import { CrudMessages } from "src/app/infrastructure/models/crud-messages"

// This function generates CRUD messages for a program
export const generateCrudMessagesForProgram = (programName) => {
  programName = programName.toLowerCase();

  const msgs: CrudMessages = {
    addUserError: {
      heading: `Failed to add ${programName} program`,
      message: 'Some data on the new program was invalid, and the program could not be added. Please try again.',
    },
    addServerFail: {
      heading: `Failed to add ${programName} program`,
      message:
        'We did something wrong and could not save your new program. Please try again later.',
    },

    editUserError: {
      heading: `Failed to edit ${programName} program`,
      message:
        'Some data on the program was invalid, and the program could not be edited. Please try again.',
    },
    editServerFail: {
      heading: `Failed to edit ${programName} program`,
      message:
        'We did something wrong and could not save your changes. Please try again later.',
    },

    removeUserError: {
      heading: `Failed to remove ${programName} program`,
      message: 'We could not remove the program. Please try again.',
    },
    removeServerFail: {
      heading: `Failed to remove ${programName} program`,
      message:
        'We did something wrong and could not remove the program. Please try again later.',
    },
  };

  return msgs;
}
