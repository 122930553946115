import { HttpClient } from '@angular/common/http';
import { inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountTypeDto } from '../models/dtos/account-type.dto';
import { DataLoadApi } from 'src/app/infrastructure/models/interfaces/data-load-api';

export const AccountTypeApiToken = new InjectionToken<AccountTypeApiService>('AccountTypeApiService');

@Injectable({
  providedIn: 'root'
})
export class AccountTypeApiService implements DataLoadApi<AccountTypeDto> {

  private httpClient = inject(HttpClient);

  loadData(): Observable<AccountTypeDto[]> {
    return this.httpClient.get<AccountTypeDto[]>('/api/v1/products/account-types');
  }
}
