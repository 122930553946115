import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { NumbersProgramDto } from '../models/numbers-program.dto';
import { HttpClient } from '@angular/common/http';
import { NumbersProgramSettings } from '../numbers-program.settings';

@Injectable({
  providedIn: 'root',
})
export class NumbersProgramApiService {
  private http = inject(HttpClient);
  private baseUrl = '/api/v1/programs/math';
  private map = new Map<number, string>([
    [NumbersProgramSettings.numericProgramType, 'numeric'],
    [NumbersProgramSettings.quantityProgramType, 'quantity'],
  ]);

  public patchHighestNumber(
    programId: string,
    programType: number,
    highestNumber: number
  ): Observable<NumbersProgramDto> {
    const urlPart = this.map.get(programType);

    return this.http
      .patch<NumbersProgramDto>(
        `${this.baseUrl}/${urlPart}/${programId}/highNumber`,
        highestNumber
      );
  }
}
