import { Inject, Injectable } from '@angular/core';
import { SpecificProgramPresentationService } from 'src/app/features/present/models/specific-program-presentation-service';
import { ProgramSetListItem } from 'src/app/infrastructure/models/program-set-list-item';
import { SpecificProgramServiceService } from 'src/app/infrastructure/services/specific-program/specific-program-service.service';
import { ProgramSettings } from 'src/app/infrastructure/models/program-settings';
import { EquationProgramSettingsToken } from '../equation-program-settings';
import { EquationSet } from '../models/equation-set';
import { EquationSetDto } from '../models/equation-set.dto';

@Injectable({
  providedIn: 'root',
})
export class PresentEquationsService
  extends SpecificProgramServiceService
  implements SpecificProgramPresentationService
{
  constructor(
    @Inject(EquationProgramSettingsToken) settings: ProgramSettings[]
  ) {
    super(settings);
  }

  public getListItems(setsForProgram: EquationSet[]): ProgramSetListItem[] {
    const listItems = setsForProgram.map((set) => {
      const listItem: ProgramSetListItem = {
        setId: set.id,
        title: null,
        content: set.equations,
      };

      return listItem;
    });

    return listItems;
  }

  public getContentToPresent(set: EquationSetDto): string[] {
    const parts = [];
    set.equations.forEach(equation => {
      const equationParts = equation.split(' ');
      equationParts.forEach(part => {
        if(part.trim() !== ''){
          parts.push(part);
        }
      });
    });

    return parts;
  }

  public shouldSuffleSetContent(): boolean {
    return false;
  }
}
