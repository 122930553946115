import { Child } from "src/app/features/children/models/child";
import { ProgramDto } from "./dtos/program-dto";
import { ProgramAreas } from "./enums/program-areas";
import { ChildUrlIdentifierService } from "src/app/features/children/services/child-url-identifier.service";
import { Program } from "./program";

export const mapProgramDtoToProgram = (
  dto: ProgramDto,
  allChildren: Child[]
) => {
  const childrenOnProgram = allChildren.filter((c) =>
    dto.childIds.includes(c.id)
  );
  const program: Program = {
    // Base Program
    id: dto.id,
    programType: dto.programType,
    children: childrenOnProgram,
    childrenUrlIdentifier: ChildUrlIdentifierService.constructGroupIdentifier(childrenOnProgram),
    nbrOfSessionsDay: dto.nbrOfSessionsADay,
    programArea: ProgramAreas.other,
    isActive: dto.isActive,

  };

  return program;
}

export const mapProgramToProgramDto = (
  program: Program
): ProgramDto => {
  const dto: ProgramDto = {
    id: program.id,
    programType: program.programType,
    childIds: program.children.map((x) => x.id),
    nbrOfSessionsADay: program.nbrOfSessionsDay,
    isActive: program.isActive,
  };

  return dto;
}
