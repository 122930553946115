import { Routes } from '@angular/router';
import { CheckoutService } from './services/checkout.service';

export const checkoutUrl = '/checkout';
export const checkoutSuccessUrl = '/checkout/success';
export const checkoutFailedUrl = '/checkout/failed';

export const CheckoutRoutes: Routes = [
  {
    path: 'checkout',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./containers/stripe-checkout/stripe-checkout.component').then(
            (m) => m.StripeCheckoutComponent
          ),
        title: 'Checkout',
      },
      {
        path: 'result',
        loadComponent: () =>
          import(
            './containers/stripe-checkout-result/stripe-checkout-result.component'
          ).then((m) => m.StripeCheckoutResultComponent),
        title: 'Processing payment',
      },
      {
        path: 'success',
        loadComponent: () =>
          import(
            './components/stripe-checkout-success/stripe-checkout-success.component'
          ).then((m) => m.StripeCheckoutSuccessComponent),
        title: 'Checkout completed',
      },
      {
        path: 'failed',
        loadComponent: () =>
          import('./containers/checkout-failed/checkout-failed.component').then(
            (m) => m.CheckoutFailedComponent
          ),
        title: 'Checkout failed',
      },
    ],
    providers: [CheckoutService],
  },
];
