import {
  AccountTypeApiService,
  AccountTypeApiToken,
} from './services/account-type-api.service';

export const ProductProviders = [
  {
    provide: AccountTypeApiToken,
    useExisting: AccountTypeApiService,
  },
];
