import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { Language } from 'src/app/features/languages/models/language';
import { DataLoadApi } from 'src/app/infrastructure/models/interfaces/data-load-api';

export const LanguageApiToken = new InjectionToken<DataLoadApi<Language>>(
  'LanguageApi'
);

@Injectable({
  providedIn: 'root'
})
export class LanguageApiService implements DataLoadApi<Language> {

  constructor(private httpClient: HttpClient) { }

  public loadData(): Observable<Language[]> {
    return this.httpClient.get<Language[]>('/api/v1/languages');
  }
}
