import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ToastController } from '@ionic/angular/standalone';
import { UserStoreToken } from '../stores/user-store.';
import { User } from '../models/user';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, take, map, Observable, of, switchMap } from 'rxjs';

export const activeSubscriptionGuard: CanActivateFn = () => {
  const userStore = inject(UserStoreToken);
  const toastController = inject(ToastController);
  const router = inject(Router);

  if (userStore.user() && (userStore.user().hasActiveSubscription || userStore.user().isAdmin)) {
    return true;
  }

  if(userStore.getUserFromApiLoaded()) {
    return showToast(toastController, router, userStore.user()).pipe(
      map(() => false)
    );
  }

  userStore.getUserFromApi();

  return toObservable(userStore.getUserFromApiLoaded).pipe(
    filter(loaded => loaded),
    take(1),
    map(() => userStore.user() && (userStore.user().hasActiveSubscription || userStore.user().isAdmin)),
    switchMap(canAccess => {
      if (canAccess) {
        return of(true);
      } else {
        return showToast(toastController, router, userStore.user()).pipe(
          map(() => false)
        );
      }
    })
  );
};

const showToast = (toastController: ToastController, router: Router, user: User): Observable<void> => {
  return new Observable(observer => {
    toastController.create({
      message: 'You must have an active subscription to access the functionality.',
      duration: 3000,
      position: 'bottom',
      color: 'danger',
      buttons: [
        {
          text: 'Fix it',
          handler: () => {
            if (user?.hasSubscriptionSetup) {
              router.navigate(['/user/subscription']);
            } else {
              router.navigate(['/checkout']);
            }
          },
        },
      ],
    }).then(toast => {
      toast.present();
      observer.next();
      observer.complete();
    });
  });
};
