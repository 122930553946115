import { Provider } from "@angular/core";
import { ProgramApiToken, ProgramApiService } from "./services/api/program-api.service";
import { ProgramInfoApiService, ProgramInfoApiToken } from "./services/api/program-info-api.service";

export const AllProgramsProviders: Provider[] = [
  {
    provide: ProgramApiToken,
    useExisting: ProgramApiService
  },
  {
    provide: ProgramInfoApiToken,
    useExisting: ProgramInfoApiService
  }
];
