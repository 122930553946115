import { Provider } from '@angular/core';
import { ReadingCategoryProgramSettings } from './reading-category-program.settings';
import { SpecificProgramSettingsService } from 'src/app/infrastructure/services/specific-program/specific-program-settings.service';
import { SpecificProgramStoreProviderService } from 'src/app/infrastructure/services/specific-program/specific-program-store-provider.service';
import {
  CoupletsProgramStoreSettings,
  SentencesProgramStoreSettings,
  SingleWordsProgramStoreSettings,
} from './reading-category-program-store.settings';
import {
  ReadingCategoryProgramAPIService,
  ReadingCategoryProgramApiServiceToken,
} from './services/reading-category-program-api.service';
import {
  ReadingCategoryApiService,
  ReadingCategoryApiServiceToken,
} from './services/reading-category-api.service';
import {
  ReadingCategoryStore,
  ReadingCategoryStoreToken,
} from './stores/reading-categories.store';
import {
  ReadingCategoryProgramStore,
  ReadingCategoryProgramStoreToken,
} from './stores/reading-category-program.store';
import { PlanSpecificSetProgramToken } from 'src/app/features/plan-set-program/models/interfaces/plan-specific-set-program';
import { PlanSentenceProgramService } from './services/plan-sentence-program.service';
import { PlanSingleWordsProgramService } from './services/plan-single-words-program.service';
import { PresentReadingCategoryService } from './services/present-reading-category.service';
import { SpecificProgramChecklistToken } from 'src/app/features/checklist-for-programs/models/interfaces/specific-program-checklist.service';
import { ReadingCategoryProgramChecklistService } from './services/reading-category-program-checklist.service';
import { ReadingCategory } from './models/reading-category';
import { ProgramStore } from 'src/app/infrastructure/models/interfaces/program-store';
import { EditSetStore } from 'src/app/features/plan-set-program/models/interfaces/edit-set-store';
import { SpecificWordStatusSetService } from 'src/app/features/word-status/services/specific-word-status-set.service';
import { ReadingProgram } from '../reading-program/models/reading-program';
import { EditReadingCategoryStore, EditReadingCategoryStoreToken } from './stores/edit-reading-category.store';
import { SpecificProgramPresentationServiceToken } from 'src/app/features/present/models/specific-program-presentation-service';

const programSettings = [
  ReadingCategoryProgramSettings.singleWords,
  ReadingCategoryProgramSettings.couplets,
  ReadingCategoryProgramSettings.sentences,
];

export const ReadingCategoryProgramProviders: Provider[] = [
  {
    provide: SpecificProgramSettingsService,
    useFactory: () => new SpecificProgramSettingsService(programSettings),
    multi: true,
  },
  {
    provide: SpecificProgramStoreProviderService,
    useFactory: () =>
      new SpecificProgramStoreProviderService([
        SingleWordsProgramStoreSettings,
        CoupletsProgramStoreSettings,
        SentencesProgramStoreSettings,
      ]),
    multi: true,
  },
  {
    provide: ReadingCategoryProgramApiServiceToken,
    useExisting: ReadingCategoryProgramAPIService,
  },
  {
    provide: PlanSpecificSetProgramToken,
    useExisting: PlanSentenceProgramService,
    multi: true,
  },
  {
    provide: PlanSpecificSetProgramToken,
    useExisting: PlanSingleWordsProgramService,
    multi: true,
  },
  {
    provide: ReadingCategoryApiServiceToken,
    useExisting: ReadingCategoryApiService,
  },
  {
    provide: ReadingCategoryProgramStoreToken,
    useExisting: ReadingCategoryProgramStore,
  },
  {
    provide: ReadingCategoryStoreToken,
    useExisting: ReadingCategoryStore,
  },
  {
    provide: SpecificProgramPresentationServiceToken,
    useExisting: PresentReadingCategoryService,
    multi: true
  },
  {
    provide: SpecificProgramChecklistToken,
    useExisting: ReadingCategoryProgramChecklistService,
    multi: true
  },
  {
    provide: EditReadingCategoryStoreToken,
    useExisting: EditReadingCategoryStore
  },
  {
    provide: SpecificWordStatusSetService,
    useFactory: (
      store: ProgramStore<ReadingProgram>,
      editStore: EditSetStore<ReadingCategory>
    ) =>
      new SpecificWordStatusSetService(store, editStore, programSettings),
    deps: [ReadingCategoryProgramStoreToken, EditReadingCategoryStoreToken],
    multi: true,
  },
];
