import { Provider } from "@angular/core";
import { GenericProgramSettings } from "./generic-program-settings";
import { SpecificProgramSettingsService } from "src/app/infrastructure/services/specific-program/specific-program-settings.service";
import { SpecificProgramChecklistToken } from "src/app/features/checklist-for-programs/models/interfaces/specific-program-checklist.service";
import { SpecificProgramStoreProviderService } from "src/app/infrastructure/services/specific-program/specific-program-store-provider.service";
import { GenericProgramApiToken, GenericProgramApiService } from "./services/generic-program-api.service";
import { GenericProgramChecklistService } from "./services/generic-program-checklist.service";
import { GenericProgramStoreToken, GenericProgramStore } from "./stores/generic-program.store";
import { GenericProgramStoreSettings } from "./generic-program.store.settings";

export const GenericProgramProviders: Provider[] = [
  {
    provide: SpecificProgramSettingsService,
    useFactory: () => new SpecificProgramSettingsService([
      GenericProgramSettings
    ]),
    multi: true,
  },
  {
    provide: GenericProgramApiToken,
    useExisting: GenericProgramApiService,
  },
  {
    provide: SpecificProgramStoreProviderService,
    useFactory: () =>
      new SpecificProgramStoreProviderService([
        GenericProgramStoreSettings,
      ]),
    multi: true,
  },
  {
    provide: GenericProgramStoreToken,
    useExisting: GenericProgramStore,
  },
  {
    provide: SpecificProgramChecklistToken,
    useExisting: GenericProgramChecklistService,
    multi: true,
  }
];
