import { Provider, EnvironmentProviders } from '@angular/core';
import {
  NumericProgramApiService,
  NumericProgramApiServiceToken,
} from './services/numeric/numeric-program-api.service';

import { NumbersProgramSettings } from './numbers-program.settings';
import { SpecificProgramChecklistToken } from 'src/app/features/checklist-for-programs/models/interfaces/specific-program-checklist.service';
import { NumbersProgramChecklistService } from './services/numbers-program-checklist.service';
import { SpecificProgramSettingsService } from 'src/app/infrastructure/services/specific-program/specific-program-settings.service';
import { SpecificProgramStoreProviderService } from 'src/app/infrastructure/services/specific-program/specific-program-store-provider.service';
import {
  NumericProgramStoreSettings,
  QuantityProgramStoreSettings,
} from './numeric-program-store.settings';
import { NumericProgramPresentationService } from './services/numeric/numeric-program-presentation.service';
import { SpecificProgramPresentationServiceToken } from 'src/app/features/present/models/specific-program-presentation-service';
import {
  QuantityProgramApiService,
  QuantityProgramApiServiceToken,
} from './services/quantity/quantity-program-api.service';

export const NumbersProgramProviders: (Provider | EnvironmentProviders)[] = [
  {
    provide: SpecificProgramSettingsService,
    useFactory: () => {
      return new SpecificProgramSettingsService([
        NumbersProgramSettings.numericProgram,
        NumbersProgramSettings.quantityProgram,
      ]);
    },
    multi: true,
  },
  {
    provide: NumericProgramApiServiceToken,
    useExisting: NumericProgramApiService,
  },
  {
    provide: QuantityProgramApiServiceToken,
    useExisting: QuantityProgramApiService,
  },
  {
    provide: SpecificProgramStoreProviderService,
    useFactory: () =>
      new SpecificProgramStoreProviderService([
        NumericProgramStoreSettings,
        QuantityProgramStoreSettings,
      ]),
    multi: true,
  },
  {
    provide: SpecificProgramPresentationServiceToken,
    useExisting: NumericProgramPresentationService,
    multi: true,
  },
  {
    provide: SpecificProgramChecklistToken,
    useExisting: NumbersProgramChecklistService,
    multi: true,
  },
];
