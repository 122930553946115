import { inject, InjectionToken } from '@angular/core';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { ProgramService } from '../models/interfaces/program-service';

export class ProgramProviderService<TService extends ProgramService> {

  protected specificServices: TService[];

  constructor(
    private logger: AppLogger,
    serviceInjector: InjectionToken<TService[]> | null = null,
    services: TService[] | null = null
  ) {
    if (serviceInjector !== null) {
      this.specificServices = inject(serviceInjector);
    } else if (services !== null) {
      this.specificServices = services;
    } else {
      this.logger.error('No services provided');
      throw new Error('No services provided');
    }
  }

  public getServiceForTypename(typeName: string): TService {
    const service = this.specificServices.find((s) =>
      s.isProgramTypeNameForService(typeName)
    );

    return this.handleFoundService(service, typeName);
  }

  public getServiceForUrl(url: string) {
    const service = this.specificServices.find((s) =>
      s.isUrlForService(url)
    );

    return this.handleFoundService(service, url);
  }


  getServiceForType(programType: number): TService {
    const service = this.specificServices.find((s) =>
      s.isProgramTypeForService(programType)
    );

    return this.handleFoundService(service, programType);
  }

  getServiceForProgramId(programId: string) {
    const service = this.specificServices.find((s) =>
      s.isProgramIdForService(programId)
    );

    return this.handleFoundService(service, programId);
  }

  getAllServices() {
    return this.specificServices;
  }


  private handleFoundService(
    service: TService | undefined,
    identifier: string | number
  ): TService {
    if (!service) {
      this.logger.debug(`No service found for type ${identifier}`);
      throw new Error(`No service found for type ${identifier}`);
    }

    return service;
  }
}
