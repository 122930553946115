import { Provider } from "@angular/core";
import { SpecificProgramChecklistToken } from "src/app/features/checklist-for-programs/models/interfaces/specific-program-checklist.service";
import { EquationProgramChecklistService } from "./services/equation-program-checklist.service";
import { SpecificProgramPresentationServiceToken } from "src/app/features/present/models/specific-program-presentation-service";
import { PresentEquationsService } from "./services/present-equations.service";

export const EquationProgramProviders: Provider[] = [
  {
    provide: SpecificProgramChecklistToken,
    useExisting: EquationProgramChecklistService,
    multi: true,
  },
  {
    provide: SpecificProgramPresentationServiceToken,
    useExisting: PresentEquationsService,
    multi: true
  },
]
