import { Program } from 'src/app/programs/all-programs/models/program';

import { SpecificProgramStore } from '../../models/interfaces/specific-program-store';
import { Type } from '@angular/core';
import { ProgramStoreSettings as ProgramStoreSettings } from '../../models/program-store-settings';
import { SpecificProgramServiceService } from './specific-program-service.service';

export class SpecificProgramStoreProviderService extends SpecificProgramServiceService {

  constructor(private programs: ProgramStoreSettings<Program>[]) {
    super(programs.map((program) => program.programSettings));
  }

  getStoreTypeForProgramType(
    programType: number
  ): Type<SpecificProgramStore<Program>> {
    const settings = this.programs.find(
      (program) => program.programSettings.programType === programType
    );

    if (settings && settings.storeType !== null) {
      return settings.storeType;
    }

    throw new Error('Store type not found');
  }
}
