import { Injectable } from '@angular/core';
import { AsyncValidator, FormGroup, ValidationErrors } from '@angular/forms';
import { Observable, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Child } from 'src/app/features/children/models/child';
import { ChildrenApiService } from '../services/children-api.service';

@Injectable({
  providedIn: 'root',
})
export class ServerChildValidator implements AsyncValidator {
  constructor(private api: ChildrenApiService) {}

  validate(control: FormGroup): Observable<ValidationErrors> {
    const child: Child = {
      id: null,
      firstName: control.get('firstName').value,
      lastName: control.get('lastName').value,

      urlIdentifier: null,
    };

    return timer(500).pipe(
      switchMap(() =>
        this.api.validateAdd(child).pipe(
          map((validationResult) => {
            if (validationResult == null) {
              return null;
            } else {
              return this.getErrors(validationResult);
            }
          })
        )
      )
    );
  }

  public getErrors(
    validationResult: { [key: string]: string } = {}
  ): ValidationErrors {
    let errors = {};

    if (validationResult.FirstName) {
      errors = { ...errors, firstName: validationResult.FirstName };
    }

    if (validationResult.LastName) {
      errors = { ...errors, lastName: validationResult.LastName };
    }

    return errors;
  }
}
