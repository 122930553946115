import { computed, inject, Injectable } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { StripeApiService } from './services/stripe-api.service';
import { AppConfig } from 'src/app/core/configuration/app-config';
import {
  dataErrorStatus,
  dataLoadedStatus,
  dataLoadingStatus,
  DataLoadingStatus,
  initialDataLoadingStatus,
} from 'src/app/infrastructure/models/data-loading-status';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, tap, map, switchMap, of, catchError, filter } from 'rxjs';
import { StripeCheckoutSession } from './models/stripe-checkout-session';

export type ProductBuy = {
  priceId: string;
  useFreeTrial: boolean;
};

export type ShoppingCartStoreState = {
  shoppingCart: ProductBuy[];
  createSessionStatusInApi: DataLoadingStatus | undefined;
  checkoutSession: StripeCheckoutSession | undefined;
};

const initialState: ShoppingCartStoreState = {
  shoppingCart: [],
  createSessionStatusInApi: initialDataLoadingStatus,
  checkoutSession: undefined,
};

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartStore extends signalStore(
  withState(initialState),
  withComputed((state) => {
    const isEmpty = computed(() => {
      const isEmpty = state.shoppingCart().length === 0;
      return isEmpty;
    });

    const createSessionStatus = computed(() => {
      const currentStatus = state.createSessionStatusInApi();

      if (currentStatus) {
        return currentStatus;
      }

      return initialDataLoadingStatus;
    });

    return { isEmpty, createSessionStatus };
  }),
  withMethods((store) => {
    const api = inject(StripeApiService);
    const appConfig = inject(AppConfig);
    const logger = inject(AppLogger).forContext('ShoppingCartStore');

    const addProduct = (priceId: string, useFreeTrial = false) => {
      const newShoppingCart = [
        ...store.shoppingCart(),
        { priceId, useFreeTrial },
      ];

      patchState(store, (state) => ({
        ...state,
        shoppingCart: newShoppingCart,
      }));
    };

    const createCheckoutSession = rxMethod<void>(
      pipe(
        tap(() => {
          patchState(store, { createSessionStatusInApi: dataLoadingStatus });
          logger.debug('Creating checkout session');
        }),
        map(() =>
          Array.from(new Set(store.shoppingCart().map((p) => p.priceId)))
        ),
        filter((checkoutForProductPrices) => checkoutForProductPrices.length > 0),
        switchMap((checkoutForProductPrices) => {
          logger.debug('Creating checkout session for product prices', checkoutForProductPrices);
          return api
            .createCheckoutSession(
              checkoutForProductPrices,
              `${appConfig.appUrl}/checkout/result`,
              store.shoppingCart().some((p) => p.useFreeTrial)
            )
            .pipe(
              tap((checkoutSession) => {
                logger.info('Checkout session created');
                patchState(store, {
                  createSessionStatusInApi: dataLoadedStatus,
                  checkoutSession: checkoutSession,
                })
              }),
              catchError((error) => {
                logger.error('Error creating checkout session', error);
                patchState(store, {
                  createSessionStatusInApi: dataErrorStatus(error.message),
                });
                return of(undefined);
              })
            );
        })
      )
    );

    return { addPrice: addProduct, createCheckoutSession };
  }),
  withHooks((_, logger = inject(AppLogger)) => ({
    onInit: () => {
      logger.forContext('CheckoutStore').info('Store initialized');
    },
  }))
) {}
