import { Routes } from '@angular/router';

export const GenericProgramSettingsRoutes: Routes = [
  {
    path: 'generic',
    loadComponent: () =>
      import(
        './containers/generic-program-settings/generic-program-settings.component'
      ).then((m) => m.GenericProgramSettingsComponent),
  },
];
