import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
} from '@ngrx/signals';
import { ProgramDto } from './models/dtos/program-dto';
import {
  addEntities,
  addEntity,
  EntityId,
  removeEntity,
  updateEntity,
  withEntities,
} from '@ngrx/signals/entities';
import { withDataLoadAll } from 'src/app/infrastructure/store-features/with-data-load-all.feature';
import { ProgramApiToken } from './services/api/program-api.service';
import { computed, inject, Injectable } from '@angular/core';
import { ChildStore } from 'src/app/features/children/children.store';
import { mapProgramDtoToProgram } from './models/program.mapper';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { ProgramInfoStore } from './program-info.store';
import { ProgramsInCategory } from './models/programs-in-category';
import { SortPorgrams } from './helpers/sortPrograms';

@Injectable({
  providedIn: 'root'
})
export class ProgramStore extends signalStore(
  { protectedState: false }, withEntities<ProgramDto>(),
  withMethods(() => ({
    mapDto: (dto: ProgramDto) => {
      return dto;
    },
  })),
  withDataLoadAll(ProgramApiToken),
  withComputed((state) => {
    const infoStore = inject(ProgramInfoStore);
    const childStore = inject(ChildStore);

    const allPrograms = computed(() =>
      state
        .entities()
        .map((x) => mapProgramDtoToProgram(x, childStore.entities()))
    );

    const allProgramsWithInfoCategories = computed(() => {
      const result: ProgramsInCategory[] = [];

      const infoCategories = infoStore.allProgramInfoInCategories();

      infoCategories.forEach((category) => {
        const categoryResult: ProgramsInCategory = {
          categoryName: category.categoryName,
          groups: [],
        };

        category.programs.forEach((programInfo) => {
          const programsOfType = allPrograms().filter(
            (x) => x.programType === programInfo.programType
          );
          if (programsOfType.length > 0) {
            categoryResult.groups.push({
              info: programInfo,
              programs: programsOfType,
            });
          }
        });

        if (categoryResult.groups.length > 0) {
          result.push(categoryResult);
        }
      });

      return result;
    });

    const allProgramsGroupedByChildren = computed(() => {
      const programs = allPrograms();
      return SortPorgrams.sortProgramsGroupedByChildren(programs);
    });

    const allActiveProgramsGroupedByChildren = computed(() => {
      const programs = allPrograms().filter((x) => x.isActive);
      return SortPorgrams.sortProgramsGroupedByChildren(programs);
    });

    return {
      allPrograms,
      allProgramsWithInfoCategories,
      allProgramsGroupedByChildren,
      allActiveProgramsGroupedByChildren,
    };
  }),
  withMethods((state) => ({
    addProgram: (program: ProgramDto) => {
      patchState(state, addEntity(program));
    },
    updateProgram: (programId: string, program: ProgramDto) => {
      patchState(
        state,
        updateEntity({
          id: programId,
          changes: { ...program },
        })
      );
    },

    deleteProgram: (programId: EntityId) => {
      patchState(state, removeEntity(programId));
    },

    programsOfTypes: (programTypes: number[]) =>
      computed(() =>
        state.entities().filter((x) => programTypes.includes(x.programType))
      ),

    programWithId: (programId: string) =>
      computed(() => state.allPrograms().find((x) => x.id === programId)),

    programDtoWithId: (programId: string) =>
      computed(() => state.entities().find((x) => x.id === programId)),

    addLoadedPrograms: (programs: ProgramDto[]) => {
      patchState(state, addEntities(programs));
    }
  })),
  withHooks((store, logger = inject(AppLogger)) => ({
    onInit: () => {
      logger.forContext('ProgramStore').debug('Store initialized');
      store.loadData(false);
    },
  }))
) {}
