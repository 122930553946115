import { InjectionToken, Signal } from "@angular/core";
import { ProgramSet } from "src/app/programs/set-programs/models/program-set";

export const EditSetStoreToken = new InjectionToken<EditSetStore<ProgramSet>>('EditSetStore');

export interface EditSetStore<TSet extends ProgramSet> {
  // Provided by withEditSetFeature
  contentName: Signal<string>;
  setName: Signal<string>;
  titleName: Signal<string>;

  set: Signal<TSet>;

  isEditMode: Signal<boolean>;

  patchSet (partialSet: Partial<TSet>): void;
  closeView(): void;

  // Needs to be implemented in the specific store
  canSave: Signal<boolean>;

  addEmptyContent(): void;
  saveSet(): void;
  setContent(content: string, index: number): void;
  removeContent(index: number): void;
}
