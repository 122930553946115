import { Injectable } from '@angular/core';
import { SpecificProgramChecklistServiceBase } from 'src/app/features/checklist-for-programs/services/specific-program-checklist.service.base';
import { HomemadeBookDto } from '../models/homemade-book-dto';
import { HomemadeBooksProgramSettings } from '../homemade-books-program.settings';

@Injectable({
  providedIn: 'root',
})
export class HomemadeBookProgramChecklistService extends SpecificProgramChecklistServiceBase<HomemadeBookDto> {
  constructor() {
    super([HomemadeBooksProgramSettings]);
  }

  protected getContentToShowForSet(set: HomemadeBookDto): string {
    return set.title;
  }
}
