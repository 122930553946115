import { computed, inject, Injectable } from '@angular/core';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { NumbersProgramStoreToken } from '../models/numbers-program.store';

@Injectable()
export class NumbersProgramService {
  private logger = inject(AppLogger);
  private store = inject(NumbersProgramStoreToken);

  public currentProgram = this.store.currentProgram;

  public isUpdatingCurrentProgram = this.store.isUpdatingCurrentProgram;
  public haveErrorUpdatingCurrentProgram =
    this.store.haveErrorUpdatingCurrentProgram;
  public highestNumber = computed(() => {
    const currentProgram = this.currentProgram();

    return currentProgram?.highestNumberCurrentOnProgram | 0;
  });

  public lowestNumber = computed(
    () => this.highestNumber() - this.currentProgram()?.nbrOfCardsInASet + 1
  );

  public numberOfDays = computed(() => {
    const nbrOfCardsLeftToShow =
      this.currentProgram()?.goalQuantity -
      this.currentProgram()?.highestNumberCurrentOnProgram;
    const nbrOfDays =
      nbrOfCardsLeftToShow / this.currentProgram()?.nbrOfNewCardsADay;

    return Math.ceil(nbrOfDays);
  });

  public completationDate = computed(() => {
    let nbrOfDaysToAdd = this.numberOfDays();

    if (!nbrOfDaysToAdd) {
      nbrOfDaysToAdd = 100 / 2;
      this.logger.info(
        'NumbersProgramService: Number of days for probram not set, will use default'
      );
    }

    const date = new Date();
    date.setDate(date.getDate() + nbrOfDaysToAdd);

    return date;
  });

  public changeHighNumber(newHigh: number) {
    this.store.changeHighNumber({
      programId: this.currentProgram().id,
      programType: this.currentProgram().programType,
      newHigh,
    });
  }
}
