import { computed, inject, Injectable } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { IdTokenClaims, RedirectRequest } from '@azure/msal-browser';
import { filter, Observable, switchMap, tap } from 'rxjs';
import { AppLogger } from '../services/logging/app-logger.service';
import { UserDto } from '../models/dtos/user.dto';
import { UserApiService } from '../services/auth/user-api.service';
import { tapResponse } from '@ngrx/operators';
import { AppConfig } from 'src/app/core/configuration/app-config';
import { User } from '../models/user';
import { AuthenticationService } from '../services/auth/authentication.service';
import { withApiRequest } from '../store-features/with-api-call';
import { UserStore } from './user-store.';

export type UserState = {
  isAuthenticated: boolean;
  userId: string | null;
  userClaims: (IdTokenClaims & { [key: string]: unknown }) | null;
};

const initialState: UserState = {
  isAuthenticated: false,
  userId: null,
  userClaims: null,
};

const updateUserSubscription = 'updateUserSubscription';
const getUserFromApi = 'getUserFromApi';

@Injectable({ providedIn: 'root' })
export class UserStoreImp extends signalStore(
  withState(() => {
    const state: UserState & { _userDto: UserDto | null } = {
      ...initialState,
      _userDto: null,
    };

    return state;
  }),
  withComputed((state) => ({
    userRole: computed(() => {
      const user = state._userDto();
      if (user?.isAdmin) {
        return 'Admin';
      }

      return 'User';
    }),

    user: computed(() => {
      const userDto = state._userDto();

      if (userDto === null) {
        return null;
      }

      const user: User = {
        id: userDto?.id.split('_')[1],
        apiId: userDto?.id,
        isAdmin: userDto?.isAdmin,
        hasActiveSubscription: userDto?.hasActiveSubscription,
        hasSubscriptionSetup: userDto?.hasSubscriptionSetup,
        accountTypeId: userDto?.accountTypeId,
      };

      return user;
    }),
  })),
  withMethods(
    (
      store,
      userApi = inject(UserApiService),
      logger = inject(AppLogger).forContext('UserStore')
    ) => {
      const getUserClaim = (claim: string) => {
        const claims = store.userClaims();
        return claims[`extension_${claim}`] as string;
      };

      const callApi = (collection: string): Observable<UserDto> => {
        switch (collection) {
          case updateUserSubscription:
            return updateUserSubscriptionCall();
          case getUserFromApi:
            return getUserFromApiCall();
          default:
            throw new Error('No call for collection: ' + collection);
        }
      };

      const updateUserSubscriptionCall = () => {
        logger.debug('Updating user subscription info');
        return userApi.updateUserPaymentInfo().pipe(
          tapResponse({
            next: (userDto) => {
              patchState(store, (state) => ({
                ...state,
                _userDto: userDto,
              }));
            },
            error: (error) =>
              logger.error('Error getting user from API', error),
          })
        );
      };

      const getUserFromApiCall = () => {
        logger.debug('Getting user from API');
        return userApi.getUserInfo().pipe(
          tapResponse({
            next: (userDto) =>
              patchState(store, (state) => ({
                ...state,
                _userDto: userDto,
              })),
            error: (error) =>
              logger.error('Error getting user from API', error),
          })
        );
      };

      return {
        getUserClaim,
        apiRequest: callApi,
      };
    }
  ),
  withApiRequest({
    collection: updateUserSubscription,
    filter: (store: UserStore) => store.isAuthenticated(),
  }),
  withApiRequest({
    collection: getUserFromApi,
    filter: (store: UserStore) => store.isAuthenticated() && !store.getUserFromApiIsLoading(),
  }),
  withMethods(
    (
      store,
      msalService = inject(MsalService),
      logger = inject(AppLogger).forContext('UserStore'),
      appConfig = inject(AppConfig),
      msalGuardConfig = inject(
        MSAL_GUARD_CONFIG
      ) as unknown as MsalGuardConfiguration
    ) => ({
      updateUserFromMsal: rxMethod<void>((source$) =>
        source$.pipe(
          tap(() => logger.debug('Updating user from MSAL')),
          tap(() => {
            const isAuthenticated =
              msalService.instance.getAllAccounts().length > 0;

            if (isAuthenticated) {
              const user = msalService.instance.getAllAccounts()[0];
              const userId = user.localAccountId;
              const userClaims = user.idTokenClaims;

              patchState(store, (state) => ({
                ...state,
                isAuthenticated: true,
                userId: userId,
                userClaims: userClaims,
              }));
            } else {
              patchState(store, (state) => ({
                ...state,
                _userDto: null,
                isAuthenticated: false,
                userId: null,
                userClaims: null,
              }));
            }
          })
        )
      ),
      login: rxMethod<void>((source$) =>
        source$.pipe(
          tap(() => logger.debug('Logging in user')),
          switchMap(() => {
            if (msalGuardConfig.authRequest) {
              return msalService.loginRedirect({
                ...msalGuardConfig.authRequest,
              } as RedirectRequest);
            } else {
              return msalService.loginRedirect();
            }
          })
        )
      ),

      logout: rxMethod<void>((source$) =>
        source$.pipe(
          tap(() => logger.debug('Logging out user')),
          switchMap(() => msalService.logoutRedirect())
        )
      ),

      register: rxMethod<void>((source$) =>
        source$.pipe(
          tap(() => logger.debug('Registering user')),
          switchMap(() => {
            const registerUserFlowRequest: RedirectRequest = {
              authority: appConfig.authentication.authorities.register,
              scopes: [],
            };

            return msalService.loginRedirect(registerUserFlowRequest);
          })
        )
      ),

      edit: rxMethod<void>((source$) =>
        source$.pipe(
          tap(() => logger.debug('Editing user')),
          switchMap(() => {
            const editUserFlowRequest: RedirectRequest = {
              authority: appConfig.authentication.authorities.editProfile,
              scopes: [],
            };

            return msalService.loginRedirect(editUserFlowRequest);
          })
        )
      ),

      loadIfNewUser: rxMethod<string>((source$) =>
        source$.pipe(
          filter((userId) => userId !== null),
          filter((userId) => userId !== store.user()?.id),
          tap(() => store.getUserFromApi())
        )
      ),
    })
  ),
  withHooks(
    (
      store,
      logger = inject(AppLogger).forContext('UserStore'),
      authService = inject(AuthenticationService)
    ) => ({
      onInit() {
        logger.debug('Initializing store');
        store.updateUserFromMsal(authService.authChanged$);
        store.loadIfNewUser(store.userId);
      },
    })
  )
) {}
