import { ChildDTO } from '../models/child-dto';
import { Child } from '../models/child';
import * as unidecode from '../../../../../node_modules/unidecode/unidecode.js';

export class ChildUrlIdentifierService {
  public static constructChildIdentifier(
    child: ChildDTO,
    allChildren: ChildDTO[]
  ): string {
    let urlIdentifier = unidecode(child.firstName)
      .toLocaleLowerCase()
      .replace('-', '_');

    const childWithSameName = allChildren.filter(
      (x) => x.firstName === child.firstName && x.apiId !== child.apiId
    );

    if (childWithSameName.length > 0) {
      const sameLastName = childWithSameName.filter(
        (x) => x.lastName === child.lastName && x.apiId !== child.apiId
      );
      urlIdentifier =
        urlIdentifier +
        '_' +
        unidecode(child.lastName).toLowerCase().replace('-', '_');
      if (sameLastName.length > 0) {
        const orderById = allChildren
          .filter(
            (x) =>
              x.firstName === child.firstName && x.lastName === child.lastName
          )
          .sort((a, b) => a.apiId.localeCompare(b.apiId));
        const index = orderById.findIndex((x) => x.apiId === child.apiId);
        urlIdentifier = urlIdentifier + '_' + (index + 1);
      }
    }

    return urlIdentifier;
  }

  public static constructChildIdentifierForChild(child: ChildDTO, allChildren: Child[] | ChildDTO[]): string {
    if(allChildren.length > 0 && (allChildren[0] as ChildDTO).apiId) {
      return this.constructChildIdentifier(child, allChildren.map(x => x as ChildDTO));
    }

    return this.constructChildIdentifierForChildWithChildren(child, allChildren.map(x => x as Child));
  }

  private static constructChildIdentifierForChildWithChildren(child: ChildDTO, allChildren: Child[]): string {
    let urlIdentifier = unidecode(child.firstName)
    .toLocaleLowerCase()
    .replace('-', '_');

  const childWithSameName = allChildren.filter(
    (x) => x.firstName === child.firstName && x.id !== child.apiId
  );

  if (childWithSameName.length > 0) {
    const sameLastName = childWithSameName.filter(
      (x) => x.lastName === child.lastName && x.id !== child.apiId
    );
    urlIdentifier =
      urlIdentifier +
      '_' +
      unidecode(child.lastName).toLowerCase().replace('-', '_');
    if (sameLastName.length > 0) {
      const orderById = allChildren
        .filter(
          (x) =>
            x.firstName === child.firstName && x.lastName === child.lastName
        )
        .sort((a, b) => a.id.localeCompare(b.id));
      const index = orderById.findIndex((x) => x.id === child.apiId);
      urlIdentifier = urlIdentifier + '_' + (index + 1);
    }
  }

  return urlIdentifier;
  }

  public static constructGroupIdentifier(children: Child[]): string {
    let identifier = '';
    children.forEach((child) => {
      identifier = identifier + child.urlIdentifier + '-';
    });
    identifier = identifier.substring(0, identifier.length - 1);

    return identifier;
  }

  public static getIndividualChildIdentifiers(
    currentUrlIdentifier: string
  ): string[] {
    const childIdentifiers = currentUrlIdentifier.split('-');
    return childIdentifiers;
  }
}
