import { Injectable } from '@angular/core';
import { ReadingCategoryProgramSettings } from '../reading-category-program.settings';
import { ReadingCategoryDTO } from '../models/reading-category-dto';
import { SpecificProgramChecklistServiceBase } from 'src/app/features/checklist-for-programs/services/specific-program-checklist.service.base';

@Injectable({
  providedIn: 'root',
})
export class ReadingCategoryProgramChecklistService extends SpecificProgramChecklistServiceBase<ReadingCategoryDTO> {
  constructor() {
    super(ReadingCategoryProgramSettings.allPrograms);
  }

  protected getContentToShowForSet(set: ReadingCategoryDTO): string {
    if (set.title === undefined || set.title === '') {
      return set.content.join(', ');
    }

    return set.title;
  }
}
