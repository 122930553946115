import { inject, Injectable } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import {
  EventMessage,
  InteractionStatus,
  EventType,
} from '@azure/msal-browser';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AppLogger } from '../logging/app-logger.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private logger = inject(AppLogger).forContext('AuthenticationService');
  private msalBroadcastService = inject(MsalBroadcastService);

  public authChanged$ = this.msalBroadcastService.msalSubject$.pipe(
    switchMap(() => this.msalBroadcastService.inProgress$),
    filter((status) => status === InteractionStatus.None),
    distinctUntilChanged(),
    map(() => void 0),
    shareReplay(1)
  );

  public userLoggedOut$ = this.msalBroadcastService.msalSubject$.pipe(
    filter(
      (event: EventMessage) =>
        event.eventType === EventType.LOGOUT_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_FAILURE
    ),
    tap(() => {
      localStorage.clear();
      this.logger.debug('User logged out');
    }),
    shareReplay(1)
  );

  public isInProcess$ = this.msalBroadcastService.inProgress$.pipe(
    map((x: InteractionStatus) => x !== InteractionStatus.None)
  );

  public authError$ = this.msalBroadcastService.msalSubject$.pipe(
    filter((result) => {
      return (
        result.eventType === EventType.LOGIN_FAILURE ||
        result.eventType === EventType.LOGOUT_FAILURE ||
        result.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
        result.eventType === EventType.SSO_SILENT_FAILURE
      );
    })
  );

  public authSuccess$ = this.msalBroadcastService.msalSubject$.pipe(
    filter(
      (result) =>
        result.eventType === EventType.LOGIN_SUCCESS ||
        result.eventType === EventType.LOGOUT_SUCCESS ||
        result.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        result.eventType === EventType.SSO_SILENT_SUCCESS
    )
  );
}
