import { Injectable, InjectionToken } from '@angular/core';
import { signalStore, withMethods } from '@ngrx/signals';
import { ReadingCategoryDTO } from '../models/reading-category-dto';
import { ReadingCategory } from '../models/reading-category';
import { withProgramSets } from 'src/app/infrastructure/store-features/with-program-sets.feature';
import { mapReadingCategoryDtoToCategory } from '../services/reading-category-program.mapper.ts';
import { ReadingCategoryApiServiceToken } from '../services/reading-category-api.service';
import { generateProgramSetCrudMessages } from '../../set-programs/services/program-set-crud-messages';
import { ProgramSetStore } from 'src/app/infrastructure/models/interfaces/program-set-store';

export const ReadingCategoryStoreToken = new InjectionToken<
  ProgramSetStore<ReadingCategory>
>('ReadingCategoryStore');

@Injectable({ providedIn: 'root' })
export class ReadingCategoryStore extends signalStore(
  withMethods(() => ({
    mapEntity: (dto: ReadingCategoryDTO) =>
      mapReadingCategoryDtoToCategory(dto),
  })),
  withProgramSets<ReadingCategory, ReadingCategoryDTO>(
    ReadingCategoryApiServiceToken,
    generateProgramSetCrudMessages('category')
  )
) {}
