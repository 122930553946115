import {
  mapProgramSetToDto,
  mapSetDtoToProgramSet,
  mapSetsProgramDtoToSetsProgram,
  mapSetsProgramToSetsProgramDto,
} from '../../set-programs/services/set-program.mapper';
import { KnowledgeProgram } from '../models/knowledge-program';
import { Child } from 'src/app/features/children/models/child';
import { KnowledgeProgramDto } from '../models/knowledge-program.dto.ts';
import { KnowledgeSetDTO } from '../models/knowledge-set-dto';
import { KnowledgeSet } from '../models/knowledge-set';
import { KnowledgeBitDTO } from '../models/knowledge-bit.dto';

export const mapKnowledgeProgramDtoToKnowledgeProgram = (
  dto: KnowledgeProgramDto,
  allChildren: Child[]
): KnowledgeProgram => {
  const program: KnowledgeProgram = {
    ...mapSetsProgramDtoToSetsProgram(dto, allChildren),
  };

  return program;
};

export const mapKnowledgeProgramToProgramDto = (
  program: KnowledgeProgram
): KnowledgeProgramDto => {
  const dto: KnowledgeProgramDto = {
    ...mapSetsProgramToSetsProgramDto(program),
  };

  return dto;
};

export const mapKnowledgeSetToDto = (set: KnowledgeSet): KnowledgeSetDTO => {
  const dto: KnowledgeSetDTO = {
    ...mapProgramSetToDto(set),
    tags: set.tags,
    categoryName: set.category,
    bits: set.content.map((bit) => {
      const bitDto: KnowledgeBitDTO = {
        id: '',
        name: bit.textOnCard,
      };

      return bitDto;
    }),
  };

  return dto;
};

export const mapKnowledgeSetDtoToKnowledgeSet = (dto: KnowledgeSetDTO): KnowledgeSet => {
  const set: KnowledgeSet = {
    ...mapSetDtoToProgramSet(dto),
    tags: dto.tags,
    category: dto.categoryName,
    content: dto.bits.map((bit) => {
      return {
        textOnCard: bit.name,
      };
    }),
  };

  return set;
}
