import { ProgramDto } from '../../models/dtos/program-dto';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable, InjectionToken } from '@angular/core';
import { DataLoadApi } from 'src/app/infrastructure/models/interfaces/data-load-api';

export const ProgramApiToken = new InjectionToken<DataLoadApi<ProgramDto>>(
  'ProgramApiService'
);

@Injectable({
  providedIn: 'root',
})
export class ProgramApiService implements DataLoadApi<ProgramDto> {
  protected readonly apiEndpoint: string = '/api/v1/programs';

  constructor(private httpClient: HttpClient) {}

  public loadData(): Observable<ProgramDto[]> {
    return this.httpClient.get<ProgramDto[]>(this.apiEndpoint);
  }

  public updateAllProgramsForToday(): Observable<void> {
    return this.httpClient.post<void>(`${this.apiEndpoint}/progress`, null);
  }
}
