import { Routes } from '@angular/router';
import { EditSetStoreToken } from 'src/app/features/plan-set-program/models/interfaces/edit-set-store';
import { EditKnowledgeSetStore } from './stores/edit-knowledge-set.store';

export const KnowledgeProgramSettingsRoutes: Routes = [
  {
    path: 'knowledge',
    loadComponent: () =>
      import(
        './containers/knowledge-bit-program-settings/knowledge-bit-program-settings.component'
      ).then((m) => m.KnowledgeBitProgramSettingsComponent),
  },
];

export const PlanKnowledgeProgramRoutes: Routes = [
  {
    path: 'knowledge/:childrenIdentifier',
    loadComponent: () =>
      import(
        'src/app/features/plan-set-program/containers/cognitive-program-planner-page/cognitive-program-planner.component'
      ).then((x) => x.CognitiveProgramPlannerComponent),
    children: [
      {
        path: 'add-set',
        loadComponent: () =>
          import('./containers/edit-bit-set/edit-bit-set.component').then(
            (c) => c.EditBitSetComponent
          ),
      },
      {
        path: 'edit-set/:setId',
        loadComponent: () =>
          import('./containers/edit-bit-set/edit-bit-set.component').then(
            (c) => c.EditBitSetComponent
          ),
      },
    ],
    providers: [
      {
        provide: EditSetStoreToken,
        useExisting: EditKnowledgeSetStore,
      },
    ],
  },
];
