import { Injectable } from '@angular/core';
import { SpecificProgramServiceService } from 'src/app/infrastructure/services/specific-program/specific-program-service.service';
import { ReadingCategoryProgramSettings } from '../reading-category-program.settings';
import { ProgramSetListItem } from 'src/app/infrastructure/models/program-set-list-item';
import { ReadingCategoryDTO } from '../models/reading-category-dto';
import { ReadingCategory } from '../models/reading-category';
import { SpecificProgramPresentationService } from 'src/app/features/present/models/specific-program-presentation-service';

@Injectable({
  providedIn: 'root',
})
export class PresentReadingCategoryService
  extends SpecificProgramServiceService
  implements SpecificProgramPresentationService
{
  constructor() {
    super([
      ReadingCategoryProgramSettings.singleWords,
      ReadingCategoryProgramSettings.couplets,
      ReadingCategoryProgramSettings.sentences,
    ]);
  }

  public getListItems(setsForProgram: ReadingCategory[]): ProgramSetListItem[] {
    return setsForProgram.map((set) => {
      const item: ProgramSetListItem = {
        title: set.title,
        setId: set.id,
        content: [],
      };

      return item;
    });
  }

  public getContentToPresent(set: ReadingCategoryDTO): string[] {
    return set.content;
  }

  public shouldSuffleSetContent(): boolean {
    return true;
  }
}
