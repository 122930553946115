import { InjectionToken } from '@angular/core';
import { ProgramSettings } from 'src/app/infrastructure/models/program-settings';
import { EquationProgram } from './models/equation-program';
import { defaultSetProgram } from '../set-programs/set-program.settings';
import { EquationProgramStep } from './models/equation-program-step';

export const EquationProgramSettingsToken = new InjectionToken<ProgramSettings>(
  'EquationProgramSettings'
);

export const defaultEquationProgram: EquationProgram = {
  ...defaultSetProgram,

  nbrOfSessionsDay: 1,
  nbrOfSetsADay: 5,
  nbrOfDaysForASet: 1,


  equationsPerSet: 2,
  maxNumber: 100,
  daysForProgram: 14,
  steps: []
};

export const defaultEquationProgramStep: EquationProgramStep = {
  maxResult: 20,
  numberOfDays: 5,
}
