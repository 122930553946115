import { computed, inject, Injectable, Signal } from '@angular/core';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { ProgramSetDTO } from '../../programs/set-programs/models/dtos/program-set-dto';
import { withEntities } from '@ngrx/signals/entities';
import { ChecklistApiService } from '../../programs/all-programs/services/api/checklist-api.service';
import { withDataLoad } from '../store-features/with-data-load.feature';
import { tap } from 'rxjs';
import { AppLogger } from '../services/logging/app-logger.service';

export type ProgramDayState = {
  forDate: Date;
  loadedDataForDate: boolean;
};

const initialState: ProgramDayState = {
  forDate: new Date(),
  loadedDataForDate: false,
};

@Injectable({
  providedIn: 'root',
})
export class ProgramDayStore extends signalStore(
  { protectedState: true },
  withEntities<ProgramSetDTO>(),
  withState(initialState),
  withMethods((state, api = inject(ChecklistApiService)) => {
    const setDate = (date: Date) => {
      const isNewDate = state.forDate() !== date;
      const haveLoadedData = state.loadedDataForDate();
      let haveLoadedDataForDate = state.loadedDataForDate();

      if (isNewDate && haveLoadedData) {
        haveLoadedDataForDate = false;
      }

      patchState(state, (store) => ({
        ...store,
        forDate: date,
        loadedDataForDate: haveLoadedDataForDate,
      }));
    };

    const dtoSetsForProgram = (programId: string) =>
      computed(() => {
        const dtos = state
          .entities()
          .filter((set) => set.programId === programId);
        return dtos;
      });

    const setWithId = (setId: string | Signal<string>) =>
      computed(() => {
        let id: string;

        const idSignal = setId as Signal<string>;
        const isSignal = idSignal.call !== undefined;

        if (isSignal) {
          id = idSignal();
        } else {
          id = setId.toString();
        }

        const set = state.entities().find((set) => set.id === id);
        return set;
      });

    const loadData = () => {
      const forDate = state.forDate() as Date;

      return api.getChecklist(forDate).pipe(
        tap(() => {
          patchState(state, (store) => ({
            ...store,
            loadedDataForDate: true,
          }));
        })
      );
    };

    const mapDto = (dto: ProgramSetDTO) => {
      return dto;
    };

    return { dtoSetsForProgram, setWithId, loadData, mapDto, setDate };
  }),
  withDataLoad(),
  withHooks((store, logger = inject(AppLogger)) => ({
    onInit() {
      logger.forContext('ProgramDayStore').debug('Store initialized');
      store.loadData(false);
    },
  }))
) {}
