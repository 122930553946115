import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesktopMenuBarComponent } from '../desktop-menu-bar/desktop-menu-bar.component';
import { IonTitle, IonToolbar } from '@ionic/angular/standalone';
import { DisplayNoneDirective } from '../../directives/display-none.directive';

@Component({
  selector: 'app-desktop-app',
  standalone: true,
  imports: [
    CommonModule,
    DesktopMenuBarComponent,
    IonToolbar,
    IonTitle,
    DisplayNoneDirective,
  ],
  templateUrl: './desktop-app.component.html',
  styleUrls: ['./desktop-app.component.scss'],
})
export class DesktopAppComponent {}
