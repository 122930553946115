import { computed, Injectable, Signal } from '@angular/core';
import { Program } from '../models/program';
import { ProgramAreas } from 'src/app/programs/all-programs/models/enums/program-areas';
import { ComponentStore } from '@ngrx/component-store';
import { Child } from '../../../features/children/models/child';
import { ChildUrlIdentifierService } from 'src/app/features/children/services/child-url-identifier.service';

export interface ProgramSettingsState {
  programSettings: Program;
  isLoadingProgram: boolean;
  haveLoadingError: boolean;
  haveLoadedProgram: boolean;
  isEditing: boolean;
  isEditable: boolean;
}

const initialAddProgramState: ProgramSettingsState = {
  programSettings: {
    id: '',
    programType: -1,
    children: [],
    nbrOfSessionsDay: 0,
    childrenUrlIdentifier: '',
    programArea: ProgramAreas.other,
    isActive: true,
  },

  isLoadingProgram: false,
  haveLoadingError: false,
  haveLoadedProgram: false,
  isEditing: false,
  isEditable: true
};

@Injectable()
export class ManageProgramSettingsService extends ComponentStore<ProgramSettingsState> {

  public readonly settings: Signal<Program> = this.selectSignal(
    (state) => state.programSettings
  );

  public readonly isEditable: Signal<boolean> = this.selectSignal(
    (state) => state.isEditable
  );

  public readonly childrenUrlIdentifier: Signal<string | undefined> = computed( // To replace with info from child store
    () => {
      if (this.settings().children.length === 0) {
        return undefined;
      }

      const children = this.settings().children;

      return ChildUrlIdentifierService.constructGroupIdentifier(children);
    }
  );

  public readonly children: Signal<Child[]> = this.selectSignal(
    (state) => state.programSettings.children
  );

  public readonly isLoadingProgram = this.selectSignal(
    (state) => state.isLoadingProgram
  );

  public readonly haveLoadingError = this.selectSignal(
    (state) => state.haveLoadingError
  );

  public readonly haveLoadedProgram = this.selectSignal(
    (state) => state.haveLoadedProgram
  );

  public readonly isEditing = this.selectSignal((state) => state.isEditing);

  public readonly setChildren = this.updater(
    (state, children: Child[]): ProgramSettingsState => ({
      ...state,
      programSettings: {
        ...state.programSettings,
        children,
      },
    })
  );

  public readonly updateOtherSettings = this.updater(
    (state, settings: Partial<Program>): ProgramSettingsState => ({
      ...state,
      programSettings: {
        ...state.programSettings,
        ...settings,
        programType: state.programSettings.programType,
        children: state.programSettings.children,
      },
    })
  );

  public readonly setProgramType = this.updater(
    (state, programType: number): ProgramSettingsState => ({
      ...state,
      programSettings: {
        ...state.programSettings,
        programType,
      },
    })
  );

  public readonly setIsLoadingProgram = this.updater(
    (state): ProgramSettingsState => ({
      ...state,
      isLoadingProgram: true,
      haveLoadedProgram: false,
      haveLoadingError: false,
    })
  );

  public readonly setHaveLoadedProgram = this.updater(
    (state): ProgramSettingsState => ({
      ...state,
      isLoadingProgram: false,
      haveLoadedProgram: true,
      haveLoadingError: false,
    })
  );

  public readonly setHaveLoadingError = this.updater(
    (state): ProgramSettingsState => ({
      ...state,
      isLoadingProgram: false,
      haveLoadedProgram: false,
      haveLoadingError: true,
    })
  );

  public readonly setIsEditable = this.updater(
    (state, editable: boolean): ProgramSettingsState => ({
      ...state,
      isEditable: editable
    })
  );

  constructor() {
    super(initialAddProgramState);
  }
}
