import { Injectable, InjectionToken } from '@angular/core';
import { mapNumberProgramToNumbersProgramDto } from '../numbers-program.mappers';
import { NumbersProgram } from '../../models/numbers-program';
import { NumbersProgramDto } from '../../models/numbers-program.dto';
import { SpecificProgramApiService } from 'src/app/infrastructure/services/specific-program/specific-program-api.service';
import { SpecificProgramApi } from 'src/app/infrastructure/models/interfaces/specific-program-api';

export const QuantityProgramApiServiceToken = new InjectionToken<
  SpecificProgramApi<NumbersProgram, NumbersProgramDto>
>('QuantityProgramApi');

@Injectable({
  providedIn: 'root',
})
export class QuantityProgramApiService extends SpecificProgramApiService<
  NumbersProgram,
  NumbersProgramDto
> {
  protected baseUrl = '/api/v1/programs/math/quantity';

  protected toDto(program: NumbersProgram): NumbersProgramDto {
    return mapNumberProgramToNumbersProgramDto(program);
  }
}
