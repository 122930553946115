import { Program } from 'src/app/programs/all-programs/models/program';

export class SortPorgrams {
  public static sortProgramsGroupedByChildren(programs: Program[]): {
    identifier: string;
    programs: Program[];
  }[] {
    const result: {
      identifier: string;
      programs: Program[];
    }[] = [];
    const childGroups: string[] = SortPorgrams.identifyChildGroups(programs);

    const processedIdentifiers = [];
    while (
      childGroups.length > 0 &&
      childGroups.length > processedIdentifiers.length
    ) {
      const maxGroupIdentifier = SortPorgrams.identifyLargestCommonGroup(
        programs,
        childGroups,
        processedIdentifiers
      );

      const programsForMostGroup = programs.filter(
        (x) => x.childrenUrlIdentifier === maxGroupIdentifier
      );
      result.push({
        identifier: maxGroupIdentifier,
        programs: programsForMostGroup,
      });
      processedIdentifiers.push(maxGroupIdentifier);
    }
    return result;
  }

  private static identifyChildGroups(programs: Program[]) {
    const childGroups: string[] = [];

    programs.forEach((program) => {
      if (
        program.childrenUrlIdentifier !== '' &&
        childGroups.indexOf(program.childrenUrlIdentifier) < 0
      ) {
        childGroups.push(program.childrenUrlIdentifier);
      }
    });
    return childGroups;
  }

  private static identifyLargestCommonGroup = (
    programs: Program[],
    childGroups: string[],
    processedIdentifiers: string[]
  ) => {
    const remainingGroups = childGroups.filter(
      (el) => !processedIdentifiers.includes(el)
    );

    let max = 0;
    let maxGroupIdentifier = '';
    remainingGroups.forEach((group) => {
      const nbrOfGroups = programs.filter(
        (x) => x.childrenUrlIdentifier === group
      );
      if (nbrOfGroups.length > max) {
        max = nbrOfGroups.length;
        maxGroupIdentifier = group;
      }
    });

    return maxGroupIdentifier;
  };
}
