import { ProgramSettings } from "src/app/infrastructure/models/program-settings";

export const additionUrlIdentifier = 'addition';

export const addtionProgramType = 7;

export const AdditionProgramSettings: ProgramSettings = {
  programType: 7,
  programName: 'Addition',
  typeName: 'addition',
  urlName: 'addition',
  idPrefix: 'programs_cognitive_math_addition_',
  urlPath: ['addition'],
  isActive: true,
  presentsInApp: true,
}
