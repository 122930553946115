import { Injectable, InjectionToken } from '@angular/core';
import { ProgramSetApiService } from '../../set-programs/services/program-set-api.service';
import { HomemadeBook } from '../models/homemade-book';
import { HomemadeBookDto } from '../models/homemade-book-dto';
import { mapHomemadeBookToDto } from './homemade-books-mapper';

export const HomemadeBookApiToken = new InjectionToken<
  ProgramSetApiService<HomemadeBook, HomemadeBookDto>
>('HomemadeBookApiService');

@Injectable({
  providedIn: 'root',
})
export class HomemadeBookApiService extends ProgramSetApiService<
  HomemadeBook,
  HomemadeBookDto
> {
  protected readonly baseUrl = '/api/v1/programs/reading/books/sets';

  protected setToDto(set: HomemadeBook): HomemadeBookDto {
    return mapHomemadeBookToDto(set);
  }
}
