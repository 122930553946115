import { ProgramSettings } from "src/app/infrastructure/models/program-settings";
import { KnowledgeProgram } from "./models/knowledge-program";
import { ProgramAreas } from "../all-programs/models/enums/program-areas";

export const KnowledgeProgramSettings: ProgramSettings = {
  programType: 6,
  programName: 'Knowledge',
  typeName: 'knowledge',
  urlName: 'knowledge',
  idPrefix: 'programs_cognitive_knowledge-programs_',
  urlPath: ['knowledge'],
  isActive: true,
  presentsInApp: false,
}

export const defaultKnowledgeProgram: KnowledgeProgram = {
  programType: KnowledgeProgramSettings.programType,
  programArea: ProgramAreas.cognitive,

  nbrOfSetsADay: 5,
  nbrOfDaysForASet: 5,
  nbrOfSessionsDay: 3,
} as KnowledgeProgram
