import { Injectable } from '@angular/core';
import { ProgramCategory } from '../models/program-category';
import { ProgramInfo } from '../models/program-info';
import { ProgramSettingsService } from 'src/app/infrastructure/services/program-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramInfoService {
  constructor(private programSettingsProvider: ProgramSettingsService) {}

  public allProgramInfoInCategories(
    programInfo: ProgramInfo[]
  ): ProgramCategory[] {
    const result: ProgramCategory[] = [];

    const categories = programInfo
      .map((info) => info.category)
      .filter((category, index, a) => {
        const compResult = a.indexOf(category) === index;
        return compResult;
      });

    categories.forEach((category) => {
      const programsInCategory = programInfo.filter(
        (info) => info.category === category
      );

      const categoryInfo: ProgramCategory = {
        categoryName: category,
        programs: programsInCategory,
      };
      result.push(categoryInfo);
    });

    return result;
  }

  public programInfoForActivePrograms(programInfo: ProgramCategory[]) {
    const result: ProgramCategory[] = [];

    programInfo.forEach((category) => {
      const updatedCategory = this.createProgramCategory(category);

      if (updatedCategory.programs.length > 0) {
        result.push(updatedCategory);
      }
    });
    return result;
  }

  private createProgramCategory(category: ProgramCategory): ProgramCategory {
    const updatedCategory: ProgramCategory = {
      ...category,
      programs: [],
    };

    category.programs.forEach((program) => {
      if (this.programIsActive(program)) {
        updatedCategory.programs.push(program);
      }
    });
    return updatedCategory;
  }

  private programIsActive(program: ProgramInfo) {
    return this.programSettingsProvider.programIsActive(program.programType);
  }
}
