import {
  EditSetInfoState,
  withEditSetFeature,
} from 'src/app/features/plan-set-program/with-edit-set.feature';
import { HomemadeBook } from '../models/homemade-book';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { HomemadeBookStoreToken } from './homemade-books.store';
import { computed, inject, InjectionToken } from '@angular/core';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { ReadingCard } from '../../reading-program/models/reading-card';
import { HomemadeBookProgramStore } from './homemade-book-program.store';
import { EditSetStore } from 'src/app/features/plan-set-program/models/interfaces/edit-set-store';
import { HomemadeBooksProgramSettings } from '../homemade-books-program.settings';

export const EditHomemadeBookStoreToken = new InjectionToken<EditSetStore<HomemadeBook>>('EditHomemadeBookStore');

const setInfoState: EditSetInfoState<HomemadeBook> = {
  contentName: 'Page',
  setName: 'Book',
  titleName: 'Title',
  defaultSet: {
    content: [
      {
        textOnCard: '',
      },
    ],
  } as HomemadeBook,
  urlNameForProgramEdit: [HomemadeBooksProgramSettings.urlPath.join('/')],
};

export const EditHomemadeBookStore = signalStore(
  {
    providedIn: 'root', protectedState: false,
  },
  withState(setInfoState),
  withEditSetFeature<HomemadeBook>(HomemadeBookStoreToken),
  withComputed((store) => {
    const hasPages = computed(() => {
      return store.set()?.content.length > 0;
    });
    const canSave = computed(() => {
      const book = store.set();

      if (book == undefined) {
        return false;
      }

      const titleIsValid = book.title !== undefined && book.title.length > 1;

      if (!hasPages()) {
        return titleIsValid;
      }

      return true;
    });

    const content = computed(() =>
      store
        .set()
        ?.content.filter(
          (c) => c.textOnCard != undefined && c.textOnCard.length > 0
        ).map(x => x.textOnCard)
    );

    return { hasPages, canSave, content };
  }),
  withMethods((store) => {
    const programStore = inject(HomemadeBookProgramStore);
    const bookStore = inject(HomemadeBookStoreToken);

    const setPages = (pages: ReadingCard[]) => {
      patchState(store, (state) => ({
        ...state,
        set: {
          ...state.set,
          content: pages,
        },
      }));
    };

    const setPagesFromStrings = (pages: string[]) => {
      setPages(pages.map((page) => ({ textOnCard: page })));
    };

    const removePage = (index: number) => {
      const pages = store.set()?.content || [];
      pages.splice(index, 1);
      setPages(pages);
    };

    const updateTextOnPage = (newText: string, index: number) => {
      const pages = store.set()?.content || [];
      pages[index].textOnCard = newText;
      setPages(pages);
    };

    const addEmptyContent = () => {
      const pages = store.set()?.content || [];
      pages.push({ textOnCard: '' });
      setPages(pages);
    };

    const saveBook = () => {
      const book: HomemadeBook = {
        ...store.set(),
        programId: programStore.currentProgram().id,
      };
      book.content = book.content.filter(
        (c) => c.textOnCard != undefined && c.textOnCard.length > 0
      );

      if (store.isEditMode()) {
        bookStore.editEntity(book);
      } else {
        bookStore.addEntity(book);
      }
    };

    return {
      setPages: setPagesFromStrings,
      removePage,
      updateTextOnPage,
      addEmptyContent,
      setContent: updateTextOnPage,
      saveSet: saveBook,
      removeCOntent: removePage
    };
  }),
  withHooks((_, logger = inject(AppLogger)) => ({
    onInit() {
      logger.debug('Edit homemade book store initialized');
    },
  }))
);
