import { Routes } from "@angular/router";
import { PlanSpecificProgramRoutes } from "src/app/core/configuration/app-route-config";

export const PlanProgramRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './containers/program-planning-page/program-planning.component'
      ).then((mod) => mod.ProgramPlanningComponent),
  },
  {
    path: 'program',
    loadComponent: () =>
      import(
        './containers/plan-program/plan-program.component'
      ).then((mod) => mod.PlanProgramComponent),
    children: [...PlanSpecificProgramRoutes, {
      path: '',
      redirectTo: '/plan',
      pathMatch: 'full'
    }],
  },
];
