import { Routes } from '@angular/router';
import { EditReadingCategoryStore } from './stores/edit-reading-category.store';
import { EditSetStoreToken } from 'src/app/features/plan-set-program/models/interfaces/edit-set-store';

const providers = [
];

const loadSettingsComponent = () =>
  import(
    './containers/reading-category-program-settings/reading-category-program-settings.component'
  ).then((m) => m.ReadingCategoryProgramSettingsComponent);

export const ReadingCategoryAddProgramRoutes: Routes = [
  {
    path: 'single-words',
    loadComponent: loadSettingsComponent,
    providers: providers,
  },
  {
    path: 'couplets',
    loadComponent: loadSettingsComponent,
    providers: providers,
  },
  {
    path: 'sentences',
    loadComponent: loadSettingsComponent,
    providers: providers,
  },
];

export const ReadingCategoryEditProgramRoutes: Routes = [
  {
    path: 'reading',
    children: [
      {
        path: 'single-words',
        loadComponent: loadSettingsComponent,
      },
      {
        path: 'couplets',
        loadComponent: loadSettingsComponent,
      },
      {
        path: 'sentences',
        loadComponent: loadSettingsComponent,
      },
    ],
    providers: [
    ],
  },
];

const editCategoryProviders = [
  {
    provide: EditSetStoreToken,
    useExisting: EditReadingCategoryStore,
  },
];

export const ReadingCategoryPlanProgramRoutes: Routes = [
  {
    path: 'reading/single-words/:childrenIdentifier',
    loadComponent: () =>
      import(
        'src/app/features/plan-set-program/containers/cognitive-program-planner-page/cognitive-program-planner.component'
      ).then((m) => m.CognitiveProgramPlannerComponent),
    children: [
      {
        path: 'add-category',
        loadComponent: () =>
          import(
            './containers/edit-single-words-category/edit-single-words-category.component'
          ).then((x) => x.EditSingleWordCategoryComponent),
      },
      {
        path: 'edit-category/:setId',
        loadComponent: () =>
          import(
            './containers/edit-single-words-category/edit-single-words-category.component'
          ).then((x) => x.EditSingleWordCategoryComponent),
      },
    ],
    providers: editCategoryProviders,
  },
  {
    path: 'reading/single-words/:childrenIdentifier/new-from-books',
    loadComponent: () =>
      import(
        './containers/create-word-categories-for-books-page/create-word-categories-for-books.component'
      ).then((x) => x.CreateWordCategoriesForBooksComponent),
  },
  {
    path: 'reading/couplets/:childrenIdentifier',
    loadComponent: () =>
      import(
        'src/app/features/plan-set-program/containers/cognitive-program-planner-page/cognitive-program-planner.component'
      ).then((m) => m.CognitiveProgramPlannerComponent),
    children: [
      {
        path: 'add-category',
        loadComponent: () =>
          import(
            './containers/edit-sentence-category/edit-sentence-category.component'
          ).then((x) => x.EditSentenceCategoryComponent),
      },
      {
        path: 'edit-category/:setId',
        loadComponent: () =>
          import(
            './containers/edit-sentence-category/edit-sentence-category.component'
          ).then((x) => x.EditSentenceCategoryComponent),
      },
    ],
    providers: editCategoryProviders,
  },
  {
    path: 'reading/sentences/:childrenIdentifier',
    loadComponent: () =>
      import(
        'src/app/features/plan-set-program/containers/cognitive-program-planner-page/cognitive-program-planner.component'
      ).then((m) => m.CognitiveProgramPlannerComponent),
    children: [
      {
        path: 'add-category',
        loadComponent: () =>
          import(
            './containers/edit-sentence-category/edit-sentence-category.component'
          ).then((x) => x.EditSentenceCategoryComponent),
      },
      {
        path: 'edit-category/:setId',
        loadComponent: () =>
          import(
            './containers/edit-sentence-category/edit-sentence-category.component'
          ).then((x) => x.EditSentenceCategoryComponent),
      },
    ],
    providers: editCategoryProviders,
  },
];
