import { Injectable, InjectionToken } from '@angular/core';
import { SpecificProgramApiService } from 'src/app/infrastructure/services/specific-program/specific-program-api.service';
import {
  AdditionProgram,
  AdditionProgramDto,
} from '../models/addition-program-types';
import { mapEquationProgramToDot } from '../../math-equations-program/helpers/equation-program.mapper';

export const AdditionProgramApiToken = new InjectionToken<
  SpecificProgramApiService<AdditionProgram, AdditionProgramDto>
>('AdditionProgramApi');

@Injectable({
  providedIn: 'root',
})
export class AdditionProgramApiService extends SpecificProgramApiService<
  AdditionProgram,
  AdditionProgramDto
> {
  protected readonly baseUrl = '/api/v1/programs/math/addition';

  protected toDto(program: AdditionProgram): AdditionProgramDto {
    return mapEquationProgramToDot(program);
  }
}
