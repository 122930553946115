<header appDisplayNone="hideMenu">
  <ion-toolbar>
    <div class="logo">
      <img
        src="assets/images/logo-img.png"
        alt="Logo - Little boy reading on the floor"
      />
      <ion-title>Early Learning App</ion-title>
    </div>

    <app-desktop-menu-bar slot="end"></app-desktop-menu-bar>
  </ion-toolbar>
</header>

<main>
  <ng-content></ng-content>
</main>
