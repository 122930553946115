import { Inject, Injectable } from '@angular/core';
import { PlanSpecificSetProgramService } from '../../set-programs/services/plan-specific-set-program.service';
import { HomemadeBook } from '../models/homemade-book';
import { HomemadeBookProgram } from '../models/homemade-book-program';
import { ProgramSetStore } from 'src/app/infrastructure/models/interfaces/program-set-store';
import { HomemadeBooksProgramSettings } from '../homemade-books-program.settings';
import { ProgramStore } from 'src/app/infrastructure/models/interfaces/program-store';
import { DisplaySetInList } from 'src/app/features/plan-set-program/models/display-set-in-list';
import { HomemadeBookStoreToken } from '../stores/homemade-books.store';
import { HomemadeBookProgramStoreToken } from '../stores/homemade-book-program.store';

@Injectable({
  providedIn: 'root',
})
export class PlanHomemadeBooksProgramService extends PlanSpecificSetProgramService<
  HomemadeBook,
  HomemadeBookProgram
> {
  public readonly setNames = 'books';
  public readonly addSetUrl = 'add-book';
  public readonly editSetUrl = 'edit-book';
  public readonly canCopy = true;

  constructor(
    @Inject(HomemadeBookStoreToken)
    store: ProgramSetStore<HomemadeBook>,
    @Inject(HomemadeBookProgramStoreToken)
    programStore: ProgramStore<HomemadeBookProgram>
  ) {
    super(store, programStore, [HomemadeBooksProgramSettings]);
  }

  public getSetAsDisplay(set: HomemadeBook): DisplaySetInList {
    const display: DisplaySetInList = {
      setId: set.id,
      title: set.title,
      content: set.content.map((x) => x.textOnCard),
    };

    return display;
  }

  public getNbrOfContentOnPrintedPage(): number {
    return this.programStore.currentProgram().nbrOfContentOnPrintPage || 1;
  }

  public getContentToPrint(setId: string): string[] {
    const set = this.getSetById(setId);
    return set.content.map((x) => x.textOnCard);
  }
}
