import {
  computed,
  effect,
  inject,
  Injectable,
  signal,
  Signal,
} from '@angular/core';
import {
  PlanSpecificSetProgram,
  PlanSpecificSetProgramToken,
} from '../models/interfaces/plan-specific-set-program';
import { ProgramProviderService } from 'src/app/infrastructure/services/program-provider.service';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { RoutingStore } from 'src/app/infrastructure/routing.store';
import { ChildStore } from '../../children/children.store';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MoveSetService } from './move-set.service';
import { RemoveSetService } from './remove-set.service';
import { ClipboardService } from 'src/app/infrastructure/services/clipboard.service';

@Injectable()
export class PlanSetProgramService {
  private programServiceProvider: ProgramProviderService<PlanSpecificSetProgram>;
  private currentUrl = inject(RoutingStore).currentUrl;
  private currentProgramService = computed(() => {
    try {
      return this.programServiceProvider.getServiceForUrl(this.currentUrl());
    } catch {
      return undefined;
    }
  });
  private childStore = inject(ChildStore);
  private router = inject(Router);
  private moveSetService = inject(MoveSetService);
  private removeSetService = inject(RemoveSetService);
  private clipboardService = inject(ClipboardService);

  public setNames = computed(() => {
    return this.currentProgramService().setNames;
  });

  public currentSets = computed(() => {
    const sets = this.currentProgramService().currentSets();
    const service = this.currentProgramService();

    return sets.map((set) => service.getSetAsDisplay(set));
  });

  public plannedSets = computed(() => {
    const sets = this.currentProgramService().plannedSets();
    const service = this.currentProgramService();

    return sets.map((set) => service.getSetAsDisplay(set));
  });

  public completedSets = computed(() => {
    const sets = this.currentProgramService().completedSets();
    const service = this.currentProgramService();

    return sets.map((set) => service.getSetAsDisplay(set));
  });

  public isLoading = computed(() => {
    return this.currentProgramService().isLoading();
  });

  public isLoadingMorePlanned = computed(() => {
    const service = this.currentProgramService();
    if (!service) return false;

    return service.isLoadingMorePlanned();
  });

  public haveLoaded = computed(() => {
    return this.currentProgramService().haveLoaded();
  });

  public haveError = computed(() => {
    return this.currentProgramService().haveError();
  });

  public addSetUrl = computed(() => {
    return this.currentProgramService().addSetUrl;
  });

  public editSetUrl = computed(() => {
    return this.currentProgramService().editSetUrl;
  });

  public getSetToDisplay = (setId: Signal<string>) =>
    computed(() => {
      const set = this.currentProgramService().getSetToDisplay(setId());

      return set;
    });

  public getSet = (setId: Signal<string>) =>
    computed(() => {
      return this.currentProgramService().getSetById(setId());
    });

  public dismissEditView = new BehaviorSubject<void>(undefined);

  public childrenOnProgram = this.childStore.selectCurrentChildren;

  public canCopy = computed(() => {
    return this.currentProgramService().canCopy;
  });
  public haveErrorForLoadingMorePlanned = computed(() => {
    const service = this.currentProgramService();
    if (!service) return false;

    return service.haveErrorForLoadingMorePlanned();
  });
  public programType = computed(() => {
    return this.currentProgramService().currentProgram().programType;
  });

  public addActions = computed(() => {
    return this.currentProgramService().additionalAddActions;
  })

  public programAllowsEdit = computed(() => {
    return this.currentProgramService().allowsEdit;
  })

  private loadedForProgram = signal<string>('');

  public hasMorePlannedToLoad = computed(() => {
    const service = this.currentProgramService();
    if (!service) return false;

    return service.haveMorePlannedToLoad();
  });

  private programId = computed(() => {
    const url = this.currentUrl();
    if (!url) return '';

    const parts = url.split('/');
    return parts[parts.length - 1];
  });

  private loadFrontSetsEffect = effect(
    () => {
      const currentUrl = this.currentUrl();

      if (
        !currentUrl.startsWith('/plan/program/') ||
        this.currentProgramService() === undefined
      ) {
        return;
      }

      const urlParts = currentUrl.split('/');

      let urlToComp = '';

      if (urlParts[urlParts.length - 1].startsWith('add')) {
        urlToComp = currentUrl.split('/add')[0];
      } else if (urlParts[urlParts.length - 2].startsWith('edit')) {
        urlToComp = currentUrl.split('/edit')[0];
      } else {
        urlToComp = currentUrl;
      }

      const setsLoadedFor = this.loadedForProgram();

      if (urlToComp != setsLoadedFor) {
        this.currentProgramService().loadFrontSets();
        this.loadedForProgram.set(currentUrl);
      }
    },
    { allowSignalWrites: true }
  );

  constructor(logger: AppLogger) {
    this.programServiceProvider =
      new ProgramProviderService<PlanSpecificSetProgram>(
        logger,
        PlanSpecificSetProgramToken
      );
  }

  public addNewSet(): void {
    const currentUrl = this.currentUrl();
    const addSetUrl = this.currentProgramService().addSetUrl;

    this.router.navigateByUrl(`${currentUrl}/${addSetUrl}`);
  }

  public editSet(setId: string): void {
    const currentUrl = this.currentUrl();
    const editSetUrl = this.currentProgramService().editSetUrl;

    this.router.navigateByUrl(`${currentUrl}/${editSetUrl}/${setId}`);
  }

  public cancleAddOrEdit(): void {
    this.dismissEditView.next();

    const currentUrl = this.currentUrl();

    const addUrl = this.currentProgramService().addSetUrl;
    if (currentUrl.includes(addUrl)) {
      const url = currentUrl.split(`/${addUrl}`)[0];
      this.router.navigateByUrl(url);
    } else {
      const editUrl = this.currentProgramService().editSetUrl;
      const url = currentUrl.split(`/${editUrl}`)[0];
      this.router.navigateByUrl(url);
    }
  }

  public loadMorePlanned() {
    this.currentProgramService().loadMorePlanned();
  }

  public moveToCurrent(setId: string): void {
    this.currentProgramService().moveToCurrent(setId);
  }

  public moveToCompleted(setId: string): void {
    this.currentProgramService().moveToCompleted(setId);
  }

  public moveToPlanned(setId: string): void {
    this.currentProgramService().moveToPlanned(setId);
  }

  public async removeSet(setId: string): Promise<void> {
    const set = this.currentProgramService().getSetById(setId);

    const shouldRemove = await this.removeSetService.confirmRemoveSet(set);

    if (shouldRemove) {
      this.currentProgramService().removeSet(setId);
    }
  }

  public copyToClipboard(setId: string): void {
    const nbrOfContentPerPage =
      this.currentProgramService().getNbrOfContentOnPrintedPage();
    const content = this.currentProgramService().getContentToPrint(setId);

    this.clipboardService.addContentDuplexToClipboard(
      content,
      nbrOfContentPerPage
    );
  }

  public async changePosition(setId: string): Promise<void> {
    const set = this.currentProgramService().getSetById(setId);

    const moveAction = await this.moveSetService.moveSet(set);

    if(moveAction === 'cancel'){
      return;
    }

    let position = 0;
    const currentPosition = this.currentProgramService()
      .plannedSets()
      .findIndex((x) => x.id === setId);

    switch (moveAction) {
      case 'up': {
        position = currentPosition - 1;
        break;
      }
      case 'down': {
        position = currentPosition + 1;
        break;
      }
      case 'end': {
        position = -1;
        break;
      }
    }

    this.currentProgramService().changePosition(setId, position);
  }
}
