import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root',
})
export class ChildModifyAlertService {
  constructor(private alertController: AlertController) {}

  public async showUserErrorFailToSaveAlert(): Promise<boolean> {
    return this.showAlert(
      'Failed to save child',
      'Saving the child to the app failed. Do you want to check it and try again?',
      true
    );
  }

  public async showFailedSaveAlert(): Promise<boolean> {
    return this.showAlert(
      'Failed to save child',
      'Something went wrong on the server and the child couldn\'t be saved. Please try again later.',
      false
    );
  }

  public async showFailedToRemoveAlert(): Promise<boolean> {
    return this.showAlert(
      'Failed to remove child',
      'Something went wrong on the server and the child couldn\'t be removed. Please try again later.',
      false
    );
  }

  private async showAlert(
    header: string,
    message: string,
    includeButtons: boolean
  ): Promise<boolean> {
    let fixError = false;
    const modalSettings = {
      header,
      message,
      buttons: [],
    };

    if (includeButtons) {
      modalSettings.buttons.push({
        text: 'No',
        role: 'cancel',
      });
      modalSettings.buttons.push({
        text: 'Yes',
        role: 'confirm',
        handler: () => {
          fixError = true;
        },
      });
    } else {
      modalSettings.buttons.push({
        text: 'Ok',
        role: 'cancel',
      });
    }

    const alert = await this.alertController.create(modalSettings);

    await alert.present();

    await alert.onDidDismiss();

    return fixError;
  }
}
