import { effect, inject, Injectable } from '@angular/core';
import {
  ManageProgramSettingsService,
  ProgramSettingsState,
} from 'src/app/programs/all-programs/services/manage-program-settings.service';
import { Program } from 'src/app/programs/all-programs/models/program';
import { Router } from '@angular/router';
import { ProgramStoreProviderService } from 'src/app/infrastructure/services/program-store-provider.service';
import { ChildStore } from 'src/app/features/children/children.store';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';

@Injectable({
  providedIn: 'root',
})
export class EditProgramService extends ManageProgramSettingsService {
  private programStore = inject(inject(ProgramStoreProviderService).getStoreForCurrentProgram());
  private childStore = inject(ChildStore);
  private router = inject(Router);
  private logger = inject(AppLogger).forContext(EditProgramService.name);

  public readonly programToEdit = this.selectSignal((state) => state.programSettings);

  public readonly setChildUrlIdentifier = this.updater(
    (state, childUrlIdentifier: string): ProgramSettingsState => ({
      ...state,
      programSettings: {
        ...state.programSettings,
        childrenUrlIdentifier: childUrlIdentifier,
      },
    })
  );

  constructor() {
    super();
    this.logger.debug('Created');
  }

  public saveChanges(): void {
    const program = this.settings();
    this.programStore.editProgram(program);

    const currentChildrenIdentifier = this.childStore.selectCurrentChildrenUrlIdentifier();

    const programChildrenIdentifier = this.childrenUrlIdentifier();

    if(currentChildrenIdentifier != programChildrenIdentifier){
      let url = this.router.url;
      url = url.replace(currentChildrenIdentifier, programChildrenIdentifier);

      this.router.navigateByUrl(url);
    }
  }

  public deleteProgram(): void {
    const programId = this.settings().id;
    this.programStore.deleteProgram(programId);

    this.router.navigate(['/settings']);
  }

  private setProgramFromCurrent = effect(() => {
    const program = this.programStore.currentProgram();

    if(program){
      this.setProgramToEdit(program);
      this.setHaveLoadedProgram();
    } else {
      this.setIsLoadingProgram();
    }

  }, { allowSignalWrites: true });

  private setCurrentChildren = effect(() => {
    const childrensIdentifier = this.childStore.selectCurrentChildrenUrlIdentifier();
    this.setChildUrlIdentifier(childrensIdentifier);
  }, { allowSignalWrites: true });

  private readonly setProgramToEdit = this.updater(
    (state, program: Program): ProgramSettingsState => ({
      ...state,
      programSettings: {
        ...state.programSettings,
        ...program,
      },
      isEditing: true,
    })
  );
}

