import { ProgramStoreSettings } from 'src/app/infrastructure/models/program-store-settings';
import { ReadingProgram } from '../reading-program/models/reading-program';
import { ReadingCategoryProgramSettings } from './reading-category-program.settings';
import { ReadingCategoryProgramStore } from './stores/reading-category-program.store';

export const SingleWordsProgramStoreSettings: ProgramStoreSettings<ReadingProgram> =
  {
    storeType: ReadingCategoryProgramStore,
    programSettings: ReadingCategoryProgramSettings.singleWords,
  };

export const CoupletsProgramStoreSettings: ProgramStoreSettings<ReadingProgram> =
  {
    storeType: ReadingCategoryProgramStore,
    programSettings: ReadingCategoryProgramSettings.couplets,
  };

export const SentencesProgramStoreSettings: ProgramStoreSettings<ReadingProgram> =
  {
    storeType: ReadingCategoryProgramStore,
    programSettings: ReadingCategoryProgramSettings.sentences,
  };
