import { signalStore, withHooks, withMethods } from '@ngrx/signals';
import { HomemadeBookDto } from '../models/homemade-book-dto';
import { mapHomemadeBookDtoToHomemadeBook } from '../services/homemade-books-mapper';
import { withProgramSets } from 'src/app/infrastructure/store-features/with-program-sets.feature';
import { HomemadeBook } from '../models/homemade-book';
import { HomemadeBookApiToken } from '../services/homemade-book-api.service';
import { inject, InjectionToken } from '@angular/core';
import { ProgramSetStore } from 'src/app/infrastructure/models/interfaces/program-set-store';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { generateProgramSetCrudMessages } from '../../set-programs/services/program-set-crud-messages';

export const HomemadeBookStoreToken = new InjectionToken<
  ProgramSetStore<HomemadeBook>
>('HomemadeBookStore');

export const HomemadeBooksStore = signalStore(
  {
    providedIn: 'root', protectedState: false,
  },
  withMethods(() => ({
    mapEntity: (dto: HomemadeBookDto) => mapHomemadeBookDtoToHomemadeBook(dto),
  })),
  withProgramSets<HomemadeBook, HomemadeBookDto>(
    HomemadeBookApiToken,
    generateProgramSetCrudMessages('homemade book')
  ),
  withHooks((store, logger = inject(AppLogger)) => ({
    onInit: () => {
      logger.forContext('HomemadeBookStore').debug('Store initialized');
    },
  }))
);
