import { inject, InjectionToken } from '@angular/core';
import { signalStore, withHooks, withMethods } from '@ngrx/signals';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { withProgramSets } from 'src/app/infrastructure/store-features/with-program-sets.feature';
import { mapEquationSetDtoToSet } from '../../math-equations-program/helpers/equation-program.mapper';
import { EquationSetDto } from '../../math-equations-program/models/equation-set.dto';
import { EquationSet } from '../../math-equations-program/models/equation-set';
import { AdditionSetApiToken } from '../services/addition-set-api.service';
import { generateProgramSetCrudMessages } from '../../set-programs/services/program-set-crud-messages';
import { ProgramSetStore } from 'src/app/infrastructure/models/interfaces/program-set-store';

export const AdditionSetStoreToken = new InjectionToken<
  ProgramSetStore<EquationSet>
>('AddtitionEquationSetStore');

export const AdditionEquationSetStore = signalStore(
  {
    providedIn: 'root',
  },
  withMethods(() => ({
    mapEntity: (dto: EquationSetDto) => mapEquationSetDtoToSet(dto),
  })),
  withProgramSets<EquationSet, EquationSetDto>(
    AdditionSetApiToken,
    generateProgramSetCrudMessages('set')
  ),
  withHooks((_, logger = inject(AppLogger)) => ({
    onInit: () => {
      logger
        .forContext('AdditionEquationSetStore')
        .debug('EquationSetStore initialized');
    },
  }))
);
