import { GenericProgramDto } from '../models/generic-program-dto';
import { GenericProgram } from '../models/generic-program';
import { ProgramAreas } from '../../all-programs/models/enums/program-areas';
import { Child } from 'src/app/features/children/models/child';
import { mapProgramDtoToProgram, mapProgramToProgramDto } from '../../all-programs/models/program.mapper';


export const mapGenericProgramDtoToProgram = (dto: GenericProgramDto, allChildren: Child[]): GenericProgram => {
  const program: GenericProgram = {
    ...mapProgramDtoToProgram(dto, allChildren),

    name: dto.name,
    area: dto.area,
    programArea: mapProgramAreaFromString(dto.area),
    completedSessionsToday: dto.completedSessionsToday ?? []
  }

  return program;
}

export const mapGenericProgramToDto = (program: GenericProgram): GenericProgramDto => {
  const dto: GenericProgramDto = {
    ...mapProgramToProgramDto(program),
    name: program.name,
    area: program.area,
    completedSessionsToday: program.completedSessionsToday ?? []
  }

  return dto;
}

const mapProgramAreaFromString = (area: string): ProgramAreas => {
    switch(area){
      case 'Cognitive':
        return ProgramAreas.cognitive;
      case 'Physical':
        return ProgramAreas.physical;
        case 'Breathing':
        return ProgramAreas.respitory;
      case 'Physiological':
        return ProgramAreas.physiological;
      case 'Social':
        return ProgramAreas.social;
      default:
        return ProgramAreas.other;
    }
  }
