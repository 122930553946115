import { Child } from "src/app/features/children/models/child";
import { mapProgramSetToDto, mapSetDtoToProgramSet, mapSetsProgramDtoToSetsProgram, mapSetsProgramToSetsProgramDto } from "../../set-programs/services/set-program.mapper";
import { EquationProgram } from "../models/equation-program";
import { EquationProgramDto } from "../models/equation-program.dto";
import { EquationProgramStepDto } from "../models/equation-program-step.dto";
import { EquationProgramStep } from "../models/equation-program-step";
import { EquationSet } from "../models/equation-set";
import { EquationSetDto } from "../models/equation-set.dto";

export const mapEquationProgramDtoToEquationProgram = (dto: EquationProgramDto, allChildren: Child[]): EquationProgram => {
  return {
    ...mapSetsProgramDtoToSetsProgram(dto, allChildren),
    equationsPerSet: dto.nbrOfEquationsInSet,
    maxNumber: dto.highestNumber,
    daysForProgram: dto.nbrOfDaysForProgram,
    steps: dto.steps?.map(mapProgramStepDtoToStep) || []
  };
};

export const mapEquationProgramToDot = (program: EquationProgram): EquationProgramDto => {
  const dto: EquationProgramDto = {
    ...mapSetsProgramToSetsProgramDto(program),
    nbrOfEquationsInSet: program.equationsPerSet,
    highestNumber: program.maxNumber,
    nbrOfDaysForProgram: program.daysForProgram,
    steps: program.steps?.map(mapStepToProgramStepDto) || []
  };

  return dto;
}

export const mapEquationSetToDto = (set: EquationSet): EquationSetDto => {
  return {
    ...mapProgramSetToDto(set),
    equations: set.equations
  };
}

export const mapEquationSetDtoToSet = (dto: EquationSetDto): EquationSet => {
  return {
    ...mapSetDtoToProgramSet(dto),
    equations: dto.equations
  };
}

const mapProgramStepDtoToStep = (dto: EquationProgramStepDto): EquationProgramStep => {
  return {
    maxResult: dto.high,
    numberOfDays: dto.nbrOfDays
  };
}

const mapStepToProgramStepDto = (step: EquationProgramStep): EquationProgramStepDto => {
  return {
    high: step.maxResult,
    low: 0,
    nbrOfDays: step.numberOfDays
  };
}
