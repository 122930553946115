import { Injectable, InjectionToken } from '@angular/core';
import { ProgramSetApiService } from '../../set-programs/services/program-set-api.service';
import { EquationSet } from '../../math-equations-program/models/equation-set';
import { EquationSetDto } from '../../math-equations-program/models/equation-set.dto';
import { mapEquationSetToDto } from '../../math-equations-program/helpers/equation-program.mapper';
import { ProgramSetApi } from '../../set-programs/models/interfaces/program-set-api';

export const AdditionSetApiToken = new InjectionToken<ProgramSetApi<EquationSet, EquationSetDto>>(
  'AdditionSetApi'
);

@Injectable({
  providedIn: 'root'
})
export class AdditionSetApiService extends ProgramSetApiService<EquationSet, EquationSetDto> {
  protected readonly baseUrl = '/api/v1/programs/math/addition/sets';

  protected setToDto(set: EquationSet): EquationSetDto {
    return mapEquationSetToDto(set);
  }
}
