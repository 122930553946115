import { inject, Injectable } from '@angular/core';
import { ProgramSetListItem } from 'src/app/infrastructure/models/program-set-list-item';
import { SpecificProgramServiceService } from 'src/app/infrastructure/services/specific-program/specific-program-service.service';
import { NumbersProgramSettings } from '../../numbers-program.settings';
import { NumbersSetDto } from '../../models/numbers-set.dto';
import { ProgramSetDTO } from 'src/app/programs/set-programs/models/dtos/program-set-dto';
import { NumbersSetToPresentation } from '../numbers-program.mappers';
import { SpecificProgramPresentationService } from 'src/app/features/present/models/specific-program-presentation-service';
import { NumericProgramStore } from '../../stores/numeric-program.store';

@Injectable({
  providedIn: 'root',
})
export class NumericProgramPresentationService
  extends SpecificProgramServiceService
  implements SpecificProgramPresentationService
{
  private programStore = inject(NumericProgramStore);

  constructor() {
    super([NumbersProgramSettings.numericProgram]);
  }

  getListItems(setsForProgram: NumbersSetDto[]): ProgramSetListItem[] {
    return setsForProgram.map((set) => ({
      title: NumbersSetToPresentation(set),
      setId: set.id,
      content: [],
    }));
  }

  getContentToPresent(set: NumbersSetDto): string[] {
    return set.numbers.map((number) => number.toString());
  }

  shouldSuffleSetContent(set: ProgramSetDTO): boolean {
    const program = this.programStore.programWithId(set.programId)();

    if (program === undefined) {
      return false;
    }

    const highestCompletedSessions = program.children.reduce(
      (highest, child) => {
        const count = set.completedSessionsToday.filter(
          (session) => session.childId === child.id
        ).length;
        return Math.max(highest, count);
      },
      0
    );

    return program.nbrOfSessionsInOrderPerDay <= highestCompletedSessions;
  }
}
