import { InjectionToken, Signal } from "@angular/core";
import { ProgramService } from "src/app/infrastructure/models/interfaces/program-service";
import { ProgramSet } from "src/app/programs/set-programs/models/program-set";
import { DisplaySetInList } from "../display-set-in-list";
import { Program } from "src/app/programs/all-programs/models/program";

export const PlanSpecificSetProgramToken = new InjectionToken<PlanSpecificSetProgram[]>('PlanSpecificSetProgramToken');

export interface PlanSpecificSetProgram extends ProgramService {

  setNames: string;
  addSetUrl: string;
  editSetUrl: string;
  canCopy: boolean;
  readonly allowsEdit: boolean;

  additionalAddActions: {
    actionName: string,
    navigateTo: string[],
    featureFlag: string | undefined
  }[] | undefined;

  currentSets: Signal<ProgramSet[]>;
  plannedSets: Signal<ProgramSet[]>;
  completedSets: Signal<ProgramSet[]>;
  currentProgram: Signal<Program>;

  isLoading: Signal<boolean>;
  haveLoaded: Signal<boolean>;
  haveError: Signal<boolean>;
  isLoadingMorePlanned: Signal<boolean>;
  haveErrorForLoadingMorePlanned: Signal<boolean>;
  haveMorePlannedToLoad: Signal<boolean>;

  loadFrontSets(): void;

  getSetById(setId: string): ProgramSet;
  getSetAsDisplay(set: ProgramSet): DisplaySetInList
  getSetToDisplay(setId: string): DisplaySetInList;

  getNbrOfContentOnPrintedPage(): number;
  getContentToPrint(setId: string): string[];

  loadMorePlanned(): void;
  moveToCurrent(setId: string): void;
  moveToCompleted(setId: string): void;
  moveToPlanned(setId: string): void;
  removeSet(setId: string): void;
  changePosition(setId: string, position: number): void;
}
