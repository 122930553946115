<ion-app>
  <app-mobile-app *appIsMobile="desktop"> </app-mobile-app>

  <ng-template #desktop>
    <app-desktop-app>
      @if(!isIframe){
      <ion-router-outlet></ion-router-outlet>
      }
    </app-desktop-app>
  </ng-template>
</ion-app>
