import { DestroyRef, Directive, inject, TemplateRef, ViewContainerRef } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { distinctUntilChanged, tap } from 'rxjs';
import { UserStoreToken } from 'src/app/infrastructure/stores/user-store.';

@Directive({
  selector: '[appRequrireAuthentication]',
  standalone: true
})
export class RequrireAuthenticationDirective {
  private userStore = inject(UserStoreToken);
  private templateRef = inject(TemplateRef);
  private viewContainerRef = inject(ViewContainerRef);
  private destroyRef = inject(DestroyRef);

  private isAuthenticated$ = toObservable(this.userStore.isAuthenticated).pipe(
    takeUntilDestroyed(this.destroyRef),
    distinctUntilChanged(),
    tap((isAuthenticated) => {
      if(isAuthenticated){
        this.createView();
      } else {
        this.removeView();
      }
    })
  ).subscribe();

  private removeView(): void {
    this.viewContainerRef.clear();
  }

  private createView(): void {
    this.viewContainerRef.clear();
    this.viewContainerRef.createEmbeddedView(this.templateRef);
  }
}
