import { inject, Injectable } from '@angular/core';
import { ShoppingCartStore } from '../shopping-cart.store';
import { StripeService } from './stripe.service';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { Router } from '@angular/router';
import { RoutingStore } from 'src/app/infrastructure/routing.store';
import { StripeApiService } from './stripe-api.service';
import { StripeCheckoutSessionResult } from '../models/stripe-checkout-session-result';
import { checkoutFailedUrl, checkoutSuccessUrl, checkoutUrl } from '../checkout.routes';
import { switchMap, filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { UserStoreToken } from 'src/app/infrastructure/stores/user-store.';

@Injectable()
export class CheckoutService {
  private shoppingCart = inject(ShoppingCartStore);
  private stripe = inject(StripeService);
  private logger = inject(AppLogger).forContext(CheckoutService.name);
  private router = inject(Router);
  private routingStore = inject(RoutingStore);
  private api = inject(StripeApiService);
  private userStore = inject(UserStoreToken);

  private checkoutSession = toObservable(this.shoppingCart.checkoutSession);

  public shoppingCartIsEmpty = this.shoppingCart.isEmpty;
  public createSessionStatus = this.shoppingCart.createSessionStatus;

  public initializeStripeCheckoutSession(): Observable<void> {
    this.logger.info('Initializing Stripe checkout session');
    this.shoppingCart.createCheckoutSession();
    return this.checkoutSession.pipe(
      filter((checkoutSession) => !!checkoutSession),
      switchMap((checkoutSession) => {
        this.logger.info('Checkout session created', { checkoutSession });
        return this.stripe.initializeStripeCheckoutSession(checkoutSession);
      }),
      tap({
        next: () => this.logger.event('Stripe checkout session initialized successfully'),
        error: (error) => this.logger.error('Failed to initialize Stripe checkout session', error)
      })
    );
  }

  public async processCheckoutResult(): Promise<void> {
    const sessionId = this.getSessionId();
    if (!sessionId) {
      return this.handleNoId();
    }

    const sessionResult = await this.getStatus(sessionId);

    if (!sessionResult) {
      this.router.navigate(['/checkout/failed'], {
        queryParams: { sessionId: sessionId },
      });
      return;
    }

    this.handleSessionStatus(sessionResult);
  }

  public async getSessionStatus(): Promise<
    StripeCheckoutSessionResult | undefined
  > {
    const sessionId = this.getSessionId();
    if (!sessionId) {
      return undefined;
    }

    return await this.getStatus(sessionId);
  }

  public handleSessionStatus(
    status: StripeCheckoutSessionResult,
    skipDefault = false
  ) {
    switch (status.status) {
      case 'open':
        this.router.navigate([checkoutUrl]);
        break;
      case 'complete':
        this.router.navigate([checkoutSuccessUrl]);
        this.userStore.updateUserSubscription();
        break;
      case 'expired':
        this.router.navigate(['/user/account-type']);
        break;
      default:
        if (!skipDefault) {
          this.router.navigate([checkoutFailedUrl], {
            queryParams: { sessionId: this.getSessionId() },
          });
        }
        break;
    }
  }

  public getSessionId() {
    return this.routingStore.queryParams()['session_id'];
  }

  private async getStatus(
    sessionId: string
  ): Promise<StripeCheckoutSessionResult | undefined> {
    try {
      const sessionStatus = await this.api.getSessionStatus(sessionId);
      return sessionStatus;
    } catch (error) {
      this.logger.error('Failed to get session status', error);
      return undefined;
    }
  }

  private handleNoId() {
    this.logger.error('No session id provided');
    this.router.navigate([checkoutUrl]);
    return;
  }
}
