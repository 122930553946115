import {
  DestroyRef,
  Directive,
  inject,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { UserStoreToken } from 'src/app/infrastructure/stores/user-store.';

@Directive({
  selector: '[appNotWhenAuthenticated]',
  standalone: true,
})
export class NotWhenAuthenticatedDirective {
  private userStore = inject(UserStoreToken);
  private templateRef = inject(TemplateRef);
  private viewContainerRef = inject(ViewContainerRef);
  private destroyRef = inject(DestroyRef);

  private isAuthenticated$ = toObservable(this.userStore.isAuthenticated)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.removeView();
      } else {
        this.createView();
      }
    });

  private removeView(): void {
    this.viewContainerRef.clear();
  }

  private createView(): void {
    this.viewContainerRef.clear();
    this.viewContainerRef.createEmbeddedView(this.templateRef);
  }
}
