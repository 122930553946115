import { inject, Injectable } from '@angular/core';
import { AlertService } from 'src/app/infrastructure/services/alert.service';
import { ProgramSet } from 'src/app/programs/set-programs/models/program-set';

export type MoveAction = 'top' | 'up' | 'down' | 'end' | 'cancel';

@Injectable({
  providedIn: 'root'
})
export class MoveSetService {
  private alertService = inject(AlertService);
  private moveTo: MoveAction;

  private buttons = [
    {
      text: 'Move to top',
      handler: () => {
        this.moveTo = 'top';
      },
    },
    {
      text: 'Move up',
      handler: () => {
        this.moveTo = 'up';
      },
    },
    {
      text: 'Move down',
      handler: () => {
        this.moveTo = 'down';
      },
    },
    {
      text: 'Move to end',
      handler: () => {
        this.moveTo = 'end';
      },
    },
    {
      text: 'Cancel',
      handler: () => {
        this.moveTo = 'cancel';
      }
    },
  ];

  public async moveSet(set: ProgramSet): Promise<MoveAction>{
    await this.alertService.showAlertWithBtns(
      'Move set',
      `Move set "${set.title}"`,
      this.buttons
    );

    return this.moveTo;
  }
}
