import { ProgramSettings } from 'src/app/infrastructure/models/program-settings';
import { ReadingProgramType } from '../reading-program/models/reading-program-type';
import { ReadingProgramSettings } from '../reading-program/reading-program-settings';

export class ReadingCategoryProgramSettings {
  public static readonly singleWords: ProgramSettings = {
    programType: ReadingProgramType.singleWords,
    programName: 'Single words',
    typeName: 'single-words',
    urlName: 'single-words',
    idPrefix: `${ReadingProgramSettings.readingProgramIdStart}_single-words_`,
    urlPath: ['reading', 'single-words'],
    isActive: true,
    presentsInApp: true,
  };

  public static readonly couplets: ProgramSettings = {
    programType: ReadingProgramType.couplets,
    programName: 'Couplets',
    typeName: 'couplets',
    urlName: 'couplets',
    idPrefix: `${ReadingProgramSettings.readingProgramIdStart}_couplets_`,
    urlPath: ['reading', 'couplets'],
    isActive: true,
    presentsInApp: true,
  };

  public static readonly sentences: ProgramSettings = {
    programType: ReadingProgramType.sentences,
    programName: 'Sentences',
    typeName: 'sentences',
    urlName: 'sentences',
    idPrefix: `${ReadingProgramSettings.readingProgramIdStart}_sentences_`,
    urlPath: ['reading', 'sentences'],
    isActive: true,
    presentsInApp: true,
  };

  public static readonly allPrograms = [
    ReadingCategoryProgramSettings.singleWords,
    ReadingCategoryProgramSettings.couplets,
    ReadingCategoryProgramSettings.sentences
  ]
}
