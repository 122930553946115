<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancle()" data-test="cancel"
        >Cancel</ion-button
      >
    </ion-buttons>
    <ion-title *ngIf="isEditMode; else newChildMode">Edit child</ion-title>
    <ng-template #newChildMode>
      <ion-title>Add new child</ion-title>
    </ng-template>
    <ion-buttons slot="end">
      <ion-button
        color="primary"
        (click)="save()"
        data-test="save"
        [disabled]="!form.valid"
        [class.save]="form.valid"
        data-test="save"
        >Save</ion-button
      >
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <p *ngIf="!isEditMode">
    Please enter your childs first and lastname to add them to your account.
  </p>
  <form [formGroup]="form" class="ion-padding">
    <ion-input
      #firstNameInput
      placeholder="Name"
      type="text"
      formControlName="firstName"
      ngDefaultControl
      data-test="firstName"
      label="First name"
      labelPlacement="floating"
      helperText="Please enter the child's first name"
    ></ion-input>
    <ion-note slot="error" *ngIf="firstName.touched"
      >The child's name is required and must be at least 2 letters
      long.</ion-note
    >
    <ion-note
      slot="helper"
      color="danger"
      *ngIf="!firstName.invalid && form.errors?.firstName"
      >{{ form.errors?.firstName }}</ion-note
    >

    <ion-input
      placeholder="Last name"
      type="text"
      formControlName="lastName"
      ngDefaultControl
      data-test="lastname"
      label="Last name"
      labelPlacement="floating"
      helperText="Please enter the child's last name"
    ></ion-input>
    <ion-note slot="error" *ngIf="lastName.touched"
      >The child's last name is required and must be at least 2 letters
      long.</ion-note
    >
    <ion-note
      slot="helper"
      color="danger"
      *ngIf="!lastName.invalid && form.errors?.lastName"
      >{{ form.errors?.lastName }}</ion-note
    >
  </form>
</ion-content>
