import { Provider } from '@angular/core';

import { KnowledgeProgramSettings } from './knowledge-program-settings';
import { SpecificProgramSettingsService } from 'src/app/infrastructure/services/specific-program/specific-program-settings.service';
import {
  KnowledgeProgramApiService,
  KnowledgeProgramApiToken,
} from './services/knowledge-program-api.service';
import { SpecificProgramChecklistToken } from 'src/app/features/checklist-for-programs/models/interfaces/specific-program-checklist.service';
import { SpecificProgramStoreProviderService } from 'src/app/infrastructure/services/specific-program/specific-program-store-provider.service';
import { KnowledgeProgramStoreSettings } from './knowledge-program.store.settings';
import {
  KnowledgeProgramStore,
  KnowledgeProgramStoreToken,
} from './stores/knowledge-bit-program.store';
import { KnowledgeBitProgramChecklistService } from './services/knowledge-bit-program-checklist.service';
import { KnowledgeSetApiService, KnowledgeSetApiToken } from './services/knowledge-set-api.service';
import { KnowledgeSetStore, KnowledgeSetStoreToken } from './stores/knowledge-set.store';
import { EditKnowledgeSetStore, EditKnowledgeSetStoreToken } from './stores/edit-knowledge-set.store';
import { PlanSpecificSetProgramToken } from 'src/app/features/plan-set-program/models/interfaces/plan-specific-set-program';
import { PlanKnowledgeProgramService } from './services/plan-knowledge-program.service';

export const KnowledgeProgramProviders: Provider[] = [
  {
    provide: SpecificProgramSettingsService,
    useFactory: () =>
      new SpecificProgramSettingsService([KnowledgeProgramSettings]),
    multi: true,
  },
  {
    provide: KnowledgeProgramApiToken,
    useExisting: KnowledgeProgramApiService,
  },
  {
    provide: SpecificProgramStoreProviderService,
    useFactory: () =>
      new SpecificProgramStoreProviderService([KnowledgeProgramStoreSettings]),
    multi: true,
  },
  {
    provide: KnowledgeProgramStoreToken,
    useExisting: KnowledgeProgramStore,
  },
  {
    provide: SpecificProgramChecklistToken,
    useExisting: KnowledgeBitProgramChecklistService,
    multi: true,
  },

  {
    provide: KnowledgeSetApiToken,
    useExisting: KnowledgeSetApiService,
  },
  {
    provide: KnowledgeSetStoreToken,
    useExisting: KnowledgeSetStore,
  },
  {
    provide: EditKnowledgeSetStoreToken,
    useExisting: EditKnowledgeSetStore,
  },
  {
    provide: PlanSpecificSetProgramToken,
    useExisting: PlanKnowledgeProgramService,
    multi: true,
  },
];
