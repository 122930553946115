import { ProgramAreas } from 'src/app/programs/all-programs/models/enums/program-areas';
import { SetProgramDto } from '../models/dtos/set-program.dto';
import { ProgramSetDTO } from '../models/dtos/program-set-dto';
import { SetProgram } from '../models/set-program';
import { ProgramSet } from '../models/program-set';
import { Child } from 'src/app/features/children/models/child';
import { mapProgramDtoToProgram, mapProgramToProgramDto } from '../../all-programs/models/program.mapper';

export const mapSetsProgramDtoToSetsProgram = (
  dto: SetProgramDto,
  allChildren: Child[]
) => {
  const program: SetProgram = {
    ...mapProgramDtoToProgram(dto, allChildren),

    // Base Program overwrites
    programArea: ProgramAreas.cognitive,

    // Set program
    nbrOfSetsADay: dto.numberOfSetsADay,
    nbrOfDaysForASet: dto.numberOfDaysOfLifeForSet,
  };

  return program;
}

export const mapSetsProgramToSetsProgramDto = (
  program: SetProgram
): SetProgramDto => {
  const dto: SetProgramDto = {
    ...mapProgramToProgramDto(program),

    numberOfSetsADay: program.nbrOfSetsADay,
    numberOfDaysOfLifeForSet: program.nbrOfDaysForASet,
  };

  return dto;
}

export const mapProgramSetToDto = (set: ProgramSet): ProgramSetDTO => {
  const dto: ProgramSetDTO = {
   id: set.id,
   statusDate: set.statusDate,
   status: set.status,
   programId: set.programId,
   plannedActivationDate: set.plannedActivationDate,
   completedSessionsToday: set.completedSessionsToday,
   title: set. title,
   position: undefined
  };

  return dto;
}

export const mapSetDtoToProgramSet = (dto: ProgramSetDTO): ProgramSet => {
  const set: ProgramSet = {
    id: dto.id,
    statusDate: dto.statusDate,
    status: dto.status,
    programId: dto.programId,
    plannedActivationDate: dto.plannedActivationDate,
    completedSessionsToday: dto.completedSessionsToday,
    title: dto.title,
    position: dto.position
  };

  return set;
}
