import { NumbersSetDto } from '../models/numbers-set.dto';
import {
  mapSetsProgramDtoToSetsProgram,
  mapSetsProgramToSetsProgramDto,
} from '../../set-programs/services/set-program.mapper';
import { NumbersProgramDto } from '../models/numbers-program.dto';
import { NumbersProgram } from '../models/numbers-program';
import { Child } from 'src/app/features/children/models/child';

export const mapNumbersProgramDtoToNumbersProgram = (
  dto: NumbersProgramDto,
  allChildren: Child[]
) => {
  const program: NumbersProgram = {
    ...mapSetsProgramDtoToSetsProgram(dto, allChildren),

    // Set program overwrites
    nbrOfSetsADay: 1,
    nbrOfDaysForASet: 1,

    /// Numbers Program
    nbrOfCardsInASet: dto.nbrOfCardsInASet,
    nbrOfNewCardsADay: dto.nbrOfNewCardsADay,
    goalQuantity: dto.goalQuantity,
    nbrOfSessionsInOrderPerDay: dto.nbrOfSetsInOrderPerDay,
    highestNumberCurrentOnProgram: dto.highestNumberCurrentOnProgram,
  };

  return program;
};

export const mapNumberProgramToNumbersProgramDto = (
  program: NumbersProgram
): NumbersProgramDto => {
  const dto: NumbersProgramDto = {
    ...mapSetsProgramToSetsProgramDto(program),
    nbrOfCardsInASet: program.nbrOfCardsInASet,
    nbrOfNewCardsADay: program.nbrOfNewCardsADay,
    goalQuantity: program.goalQuantity,
    nbrOfSetsInOrderPerDay: program.nbrOfSessionsInOrderPerDay,
    highestNumberCurrentOnProgram: program.highestNumberCurrentOnProgram,
  };

  return dto;
};

export const NumbersSetToPresentation = (set: NumbersSetDto): string => {
  return `${Math.min(...set.numbers)} - ${Math.max(...set.numbers)}`;
};
