import { ProgramSettings } from "src/app/infrastructure/models/program-settings";

export const GenericProgramSettings: ProgramSettings = {
  programType: 100,
  programName: 'Generic',
  typeName: 'generic',
  urlName: 'generic',
  idPrefix: 'programs_generic_',
  urlPath: ['generic'],
  isActive: true,
  presentsInApp: false,
}
