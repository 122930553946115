<ion-toolbar>
  <ion-buttons>
    <ion-button [routerLink]="['/']" *appNotWhenAuthenticated>Home</ion-button>
    <ion-button [routerLink]="['/', 'checklist']" *appRequrireAuthentication>Checklist</ion-button>
    <ion-button [routerLink]="[ '/', 'present']" *appRequrireAuthentication data-test="present-btn">Present</ion-button>
    <ion-button [routerLink]="['/','plan']" *appRequrireAuthentication>Plan</ion-button>
    <ion-button [routerLink]="['/','settings']" *appRequrireAuthentication>Settings</ion-button>
    <ion-button [routerLink]="['/','info']">Information</ion-button>
    <ion-button [routerLink]="['/','user', 'register']" *appNotWhenAuthenticated data-test="register">Register</ion-button>
    <ion-button [routerLink]="['/','user', 'login']" *appNotWhenAuthenticated data-test="login">Login</ion-button>
  </ion-buttons>
</ion-toolbar>
