import { InjectionToken } from '@angular/core';
import { ProgramService } from '../../../infrastructure/models/interfaces/program-service';
import { ProgramSet } from 'src/app/programs/set-programs/models/program-set';
import { ProgramSetListItem } from '../../../infrastructure/models/program-set-list-item';
import { ProgramSetDTO } from 'src/app/programs/set-programs/models/dtos/program-set-dto';

export const SpecificProgramPresentationServiceToken =
  new InjectionToken<SpecificProgramPresentationService[]>(
    'SpecificProgramPresentationService'
  );

export interface SpecificProgramPresentationService extends ProgramService {


  getListItems(setsForProgram: ProgramSet[]): ProgramSetListItem[];
  getContentToPresent(set: ProgramSetDTO): string[];
  shouldSuffleSetContent(set: ProgramSetDTO): boolean;
}
