import { HttpErrorResponse } from '@angular/common/http';
import { computed, Signal } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  SignalStoreFeature,
  signalStoreFeature,
  type,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { filter, Observable, switchMap, tap, Unsubscribable } from 'rxjs';

// Created based on https://www.angulararchitects.io/blog/ngrx-signal-store-deep-dive-flexible-and-type-safe-custom-extensions/

export type RequestState = 'init' | 'loading' | 'loaded' | { error: string };

export type NamedRequestStateSlice<Collection extends string> = {
  [K in Collection as `${K}RequestState`]: RequestState;
};

export type RequestStateSlice = {
  callState: RequestState;
};

export type NamedRequestStateSignals<Prop extends string> = {
  [K in Prop as `${K}IsLoading`]: Signal<boolean>;
} & {
  [K in Prop as `${K}Loaded`]: Signal<boolean>;
} & {
  [K in Prop as `${K}Error`]: Signal<HttpErrorResponse | null>;
};

export type RequestStateSignals = {
  isLoading: Signal<boolean>;
  loaded: Signal<boolean>;
  error: Signal<HttpErrorResponse | null>;
};

export type RequestStateMethods = {
  callApi: () => Unsubscribable;
};

export function getRequestStateKeys(config?: { collection?: string }) {
  const prop = config?.collection;
  return {
    requestStateKey: prop ? `${config.collection}RequestState` : 'requestState',
    loadingKey: prop ? `${config.collection}IsLoading` : 'isLoading',
    loadedKey: prop ? `${config.collection}Loaded` : 'loaded',
    errorKey: prop ? `${config.collection}Error` : 'error',
    makeCall: prop ? `${config.collection}` : 'callApi',
  };
}

export function withApiRequest<Collection extends string>(config: {
  collection: Collection;
  filter?: (store: unknown) => boolean;
}): SignalStoreFeature<
  // eslint-disable-next-line @typescript-eslint/ban-types
  { state: {}; signals: {}; methods: {}; computed: {} },
  {
    state: NamedRequestStateSlice<Collection>;
    signals: NamedRequestStateSignals<Collection>;
    methods: { [K in Collection]: () => Unsubscribable };
    computed: NamedRequestStateSignals<Collection>;
  }
>;
export function withApiRequest(): SignalStoreFeature<
  // eslint-disable-next-line @typescript-eslint/ban-types
  { state: {}; signals: {}; methods: {}; computed: {} },
  {
    state: RequestStateSlice;
    signals: RequestStateSignals;
    methods: RequestStateMethods;
    computed: RequestStateSignals;
  }
>;

export function withApiRequest<Collection extends string>(config?: {
  collection: Collection;
  filter?: (store: unknown) => boolean;
}): SignalStoreFeature {
  const { requestStateKey, errorKey, loadedKey, loadingKey, makeCall } =
    getRequestStateKeys(config);

  return signalStoreFeature(
    {
      methods: type<{
        apiRequest: (collectionName: string) => Observable<unknown>;
      }>(),
    },
    withState({ [requestStateKey]: 'init' }),
    withComputed((state: Record<string, Signal<unknown>>) => {
      const requestState = state[requestStateKey] as Signal<RequestState>;

      return {
        [loadingKey]: computed(() => requestState() === 'loading'),
        [loadedKey]: computed(() => requestState() === 'loaded'),
        [errorKey]: computed(() => {
          const v = requestState();
          return typeof v === 'object' ? v.error : null;
        }),
      };
    }),
    withMethods((store) => {
      const call = rxMethod<void>((source$) =>
        source$.pipe(
          filter(() => {
            if(config?.filter) {
              return config.filter(store);
            }

            return true;
          }),
          tap(() => {
            patchState(store, (state) => ({
              ...state,
              [requestStateKey]: 'loading',
            }));
          }),
          switchMap(() =>
            store.apiRequest(config.collection).pipe(
              tapResponse({
                next: () => {
                  patchState(store, (state) => ({
                    ...state,
                    [requestStateKey]: 'loaded',
                  }));
                },
                error: (error: HttpErrorResponse) => {
                  patchState(store, (state) => ({
                    ...state,
                    [requestStateKey]: error.message,
                  }));
                },
              })
            )
          )
        )
      );
      return { [makeCall]: call };
    })
  );
}
