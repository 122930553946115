/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { AppLogger } from './app-logger.service';

@Injectable({
  providedIn: 'root',
})
export class ConsoleLogger implements AppLogger {
  private context: string | undefined;

  debug(message: string): void {
    console.info(this.createMessage(message));
  }
  error(message: string, error: Error): void {
    console.error(this.createMessage(message, error));
  }
  warning(message: string): void {
    console.warn(this.createMessage(message));
  }
  info(message: string, context?: unknown): void {
    console.info(this.createMessage(message, context));
  }
  trace(message: string): void {
    console.info(this.createMessage(message));
  }
  event(message: string): void {
    console.info(this.createMessage(message));
  }

  forContext(context: unknown): AppLogger {
    const logger = new ConsoleLogger();

    let contextString = JSON.stringify(context).replace(/"/g, '');

    if(this.context !== undefined) {
      contextString = `${this.context} > ${contextString}`;
    }

    logger.context = contextString;

    return logger;
  }

  private createMessage(message: string, context?: unknown): string {
    let messageString = message;

    if(this.context !== undefined) {
      messageString = `${this.context} > ${message}`;
    }

    if(context !== undefined) {
      messageString = `${messageString} ${JSON.stringify(context)}`;
    }

    return messageString;
  }
}
