import { Injectable } from '@angular/core';
import { SpecificProgramChecklistServiceBase } from 'src/app/features/checklist-for-programs/services/specific-program-checklist.service.base';
import { KnowledgeSet } from '../models/knowledge-set';
import { KnowledgeProgramSettings } from '../knowledge-program-settings';

@Injectable({
  providedIn: 'root',
})
export class KnowledgeBitProgramChecklistService extends SpecificProgramChecklistServiceBase<KnowledgeSet> {
  constructor() {
    super([KnowledgeProgramSettings]);
  }

  protected getContentToShowForSet(set: KnowledgeSet): string {
    return set.title;
  }
}
