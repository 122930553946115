import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../configuration/app-config';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';

@Injectable()
export class DefaultApiEndpointInterceptor implements HttpInterceptor {

  constructor(private configuration: AppConfig, logger: AppLogger) {
    logger.debug('DefaultApiEndpointInterceptor created');
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (!request.url.startsWith('http'))
    {
      const apiRequest = request.clone({
        url: this.configuration.apiEndpoint + request.url
      });
      return next.handle(apiRequest);
    }

    return next.handle(request);
  }
}
