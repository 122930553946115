import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { EditChildModalComponent } from 'src/app/features/children/components/edit-child-modal/edit-child-modal.component';
import { Child } from 'src/app/features/children/models/child';

@Injectable({
  providedIn: 'root',
})
export class EditChildModalService {
  constructor(private modalController: ModalController) {}

  public async showEditChild(
    child: Child | null,
    isEdit = false
  ): Promise<Child | null> {
    const result = await this.showEditChildModal(child, null, isEdit);
    return result;
  }

  public async showEditChildWithError(
    child: Child,
    errors: Record<string, string>,
    isEdit = false
  ): Promise<Child | null> {
    const result = await this.showEditChildModal(child, errors, isEdit);
    return result;
  }

  private async showEditChildModal(
    child: Child | null = null,
    errors: Record<string, string> | null,
    isEdit: boolean
  ): Promise<Child | null> {
    const modal = await this.modalController.create({
      component: EditChildModalComponent,
      componentProps: {
        child,
        errors,
        isEdit,
      },
    });
    modal.present();
    const { data } = await modal.onDidDismiss();
    if (data !== undefined) {
      return data;
    } else {
      return null;
    }
  }
}
