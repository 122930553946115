import { signalStore } from '@ngrx/signals';
import { withEquationProgramFeature } from '../../math-equations-program/stores/with-equation-program.feature';
import { AdditionProgramApiToken } from '../services/addition-program-api.service';
import { AdditionProgramSettings } from '../addition-program.settings';
import { generateCrudMessagesForProgram } from '../../all-programs/helpers/generate-crud-message';
import { InjectionToken } from '@angular/core';
import { AdditionProgram } from '../models/addition-program-types';
import { ProgramStore } from 'src/app/infrastructure/models/interfaces/program-store';

export const AdditionProgramStoreToken = new InjectionToken<
  ProgramStore<AdditionProgram>
>('AdditionProgramStore');

export const AdditionProgramStore = signalStore(
  {
    providedIn: 'root',
  },
  withEquationProgramFeature(
    AdditionProgramApiToken,
    [AdditionProgramSettings],
    generateCrudMessagesForProgram('addition')
  )
);
