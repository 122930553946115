import { inject, Inject, Injectable, Type } from '@angular/core';
import { Program } from 'src/app/programs/all-programs/models/program';
import { SpecificProgramStore } from '../models/interfaces/specific-program-store';
import { ProgramProviderService } from './program-provider.service';
import { SpecificProgramStoreProviderService } from './specific-program/specific-program-store-provider.service';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { ProgramSettingsService } from './program-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramStoreProviderService {
  private programService: ProgramProviderService<SpecificProgramStoreProviderService>;
  private programSettingsService = inject(ProgramSettingsService);

  constructor(
    logger: AppLogger,
    @Inject(SpecificProgramStoreProviderService)
    programServices: SpecificProgramStoreProviderService[]
  ) {
    this.programService = new ProgramProviderService(
      logger,
      null,
      programServices
    );
  }

  public getStoreTypeForProgramType(
    programType: number
  ): Type<SpecificProgramStore<Program>> {
    const service = this.programService.getServiceForType(programType);

    const type = service.getStoreTypeForProgramType(programType);
    return type;
  }

  getStoreForCurrentProgram(): Type<SpecificProgramStore<Program>> {
    const currentProgramType =
      this.programSettingsService.currentProgramTypeIn();

    return this.getStoreTypeForProgramType(currentProgramType);
  }
}
