import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventType } from '@azure/msal-browser';
import { filter } from 'rxjs';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { MobileAppComponent } from './layout/mobile-app/mobile-app.component';
import { DesktopAppComponent } from './layout/desktop/desktop-app/desktop-app.component';
import { AppLogger } from './infrastructure/services/logging/app-logger.service';
import { IsMobileDirective } from './layout/directives/device/is-mobile.directive';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    IonApp,
    IonRouterOutlet,
    MobileAppComponent,
    DesktopAppComponent,
    IsMobileDirective,
  ],
})
export class AppComponent implements OnInit {
  public isIframe = false;

  private msal$ = inject(MsalBroadcastService);
  private msal = inject(MsalService);
  private router = inject(Router);
  private logger = inject(AppLogger);

  ngOnInit(): void {
    this.msal.handleRedirectObservable().subscribe();
    this.isIframe = window !== window.parent && !window.opener;

    this.handleFailedMsalCalls();
  }

  private handleFailedMsalCalls() {
    this.msal$.msalSubject$
      .pipe(filter((result) => result.eventType === EventType.LOGIN_FAILURE))
      .subscribe((event) => {
        this.logger.info('AppComponent: Login failed', event);
        this.router.navigate(['/']);
      });
  }
}
