import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProgramSetDTO } from 'src/app/programs/set-programs/models/dtos/program-set-dto';

@Injectable({
  providedIn: 'root',
})
export class ChecklistApiService {
  constructor(private httpClient: HttpClient) {}

  public getChecklist(date: Date): Observable<ProgramSetDTO[]> {
    const dateString = date.toISOString().split('T')[0];
    const params = new HttpParams().set('dateStamp', dateString);
    return this.httpClient.get<ProgramSetDTO[]>(`/api/v1/programs/checklist`, {
      params,
    });
  }
}
