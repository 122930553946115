import { Injectable, InjectionToken } from '@angular/core';
import { ProgramInfoDTO } from '../../models/dtos/program-info-dto';
import { HttpClient } from '@angular/common/http';
import { DataLoadApi } from 'src/app/infrastructure/models/interfaces/data-load-api';
import { Observable } from 'rxjs';

export const ProgramInfoApiToken = new InjectionToken<
  DataLoadApi<ProgramInfoDTO>
>('ProgramInfoApi');

@Injectable({
  providedIn: 'root',
})
export class ProgramInfoApiService
  implements DataLoadApi<ProgramInfoDTO>
{
  protected readonly apiEndpoint: string = '/api/v1/programs/info';

  constructor(private httpClient: HttpClient) {

  }

  loadData(): Observable<ProgramInfoDTO[]> {
    return this.httpClient.get<ProgramInfoDTO[]>(this.apiEndpoint);
  }
}
