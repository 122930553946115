import {
  patchState,
  signalStoreFeature,
  type,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { computed, inject } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { exhaustMap, filter, Observable, pipe, tap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { DataLoadingStatus } from '../models/data-loading-status';
import { Entity, EntityDto } from '../models/entity';
import { addEntities, EntityState } from '@ngrx/signals/entities';

export type DataLoadState = {
  isLoading: boolean;
  haveLoaded: boolean;
  loadingError: string | undefined;
};

const initialState: DataLoadState = {
  isLoading: false,
  haveLoaded: false,
  loadingError: undefined,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withDataLoad<_>() {
  return signalStoreFeature(
    {
      state: type<EntityState<Entity>>(),
      methods: type<{
        loadData: () => Observable<EntityDto[]>,
        mapDto(dto: EntityDto): Entity;
      }>(),
    },
    withState(initialState),
    withComputed((state) => ({
      haveDataLoadingError: computed(() => {
        return !state.isLoading() && state.loadingError() !== undefined;
      }),
      loadingStatus: computed(() => {
        const status: DataLoadingStatus = {
          isLoading: state.isLoading(),
          haveLoaded: state.haveLoaded(),
          errorMessage: state.loadingError(),
          haveError: state.loadingError() !== undefined
        };

        return status;
      })
    })),
    withMethods(
      (state, logger = inject(AppLogger)) => ({
        loadData: rxMethod<boolean>(
          pipe(
            filter(
              (forceLoad) =>
                (!state.isLoading() && !state.haveLoaded()) || forceLoad
            ),
            tap(() => {
              patchState(state, (store) => ({
                ...store,
                isLoading: true,
                loadingError: undefined,
              }));
            }),
            exhaustMap(() =>
              state.loadData().pipe(
                tapResponse(
                  (dtos) => {
                    patchState(state, (store) => ({
                      ...store,
                      isLoading: false,
                      haveLoaded: true,
                    }));

                    const entities = dtos.map((dto) =>
                      state.mapDto(dto)
                    );

                    patchState(state, addEntities(entities));
                  },

                  (error: HttpErrorResponse) => {
                    patchState(state, (store) => ({
                      ...store,
                      isLoading: false,
                      loadingError: error.message,
                    }));

                    logger.error('Failed to load data', error);
                  }
                )
              )
            )
          )
        ),
      })
    )
  );
}
