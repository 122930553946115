import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NotWhenAuthenticatedDirective } from '../../directives/access/not-when-authenticated.directive';
import { IonButtons, IonToolbar } from '@ionic/angular/standalone';
import { RequrireAuthenticationDirective } from '../../directives/access/requrire-authentication.directive';

@Component({
  selector: 'app-desktop-menu-bar',
  standalone: true,
  imports: [
    IonToolbar,
    RouterLink,
    RequrireAuthenticationDirective,
    NotWhenAuthenticatedDirective,
    IonButtons
  ],
  templateUrl: './desktop-menu-bar.component.html',
  styleUrls: ['./desktop-menu-bar.component.scss'],
})
export class DesktopMenuBarComponent {}
