import { computed, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Platform } from '@ionic/angular/standalone';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  tap,
} from 'rxjs';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';

@Injectable({
  providedIn: 'root',
})
export class AppPlatformService {
  private breakPointForMobile = 992;

  public resize$ = this.platform.resize.pipe(debounceTime(300), shareReplay(1));

  private resizeSignal = toSignal(
    this.platform.resize.pipe(
      tap(() => {
        this.logger.debug('AppPlatformService: Resize signal is computed');
      })
    )
  );

  private reziseIsMobile$ = this.resize$.pipe(
    startWith(null),
    map(() => {
      if (this.isBothMobileAndDesktop) {
        const width = this.platform.width();
        return width < this.breakPointForMobile;
      }

      return this.platformIsMobile;
    }),
    distinctUntilChanged((prev, curr) => {
      return prev === curr;
    }),
    tap((isMobile) =>
      this.logger.debug(`AppPlatformService: Is mobile: ${isMobile}`)
    ),
    shareReplay(1)
  );

  private resizeIsMobile = toSignal(this.reziseIsMobile$);

  constructor(private platform: Platform, private logger: AppLogger) {}

  public get isMobile$(): Observable<boolean> {
    return this.reziseIsMobile$;
  }

  public get isDesktop$(): Observable<boolean> {
    return this.reziseIsMobile$.pipe(
      map((isMobile) => {
        return !isMobile;
      })
    );
  }

  public get isMobile(): Signal<boolean> {
    return this.resizeIsMobile;
  }

  private get platformIsMobile() {
    return this.platform.is('mobile') || this.platform.is('tablet');
  }

  private get isBothMobileAndDesktop() {
    const isDesktop = this.platform.is('desktop');

    return this.platformIsMobile && isDesktop;
  }

  public isPortrait(): boolean {
    return this.platform.isPortrait();
  }

  public isPortraitSignal = toSignal(
    this.resize$.pipe(
      tap(() => {
        this.logger.debug('AppPlatformService: Is portrait signal is computed');
      }),
      map(() => {
        return this.platform.isPortrait();
      }),
      startWith(this.platform.isPortrait()),
    )
  );

  public isLandscape(): boolean {
    return this.platform.isLandscape();
  }

  public screenWidth = computed(() => {
    this.isMobile();

    return this.platform.width();
  });
}
