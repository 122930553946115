import { ProgramAreas } from "./models/enums/program-areas"
import { Program } from "./models/program"

export const defaultProgram: Program = {
  id: undefined,
    programType: undefined,
    children: [],
    childrenUrlIdentifier: '',
    programArea: ProgramAreas.other,
    isActive: true,
    nbrOfSessionsDay: 3
}
