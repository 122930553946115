import { inject, Injectable } from '@angular/core';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { AppConfig } from 'src/app/core/configuration/app-config';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { StripeCheckoutSession } from '../models/stripe-checkout-session';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private appConfig = inject(AppConfig);
  private logger = inject(AppLogger).forContext(StripeService.name);

  /**
   * Initializes a Stripe checkout session. Mounts it to the id of "checkout" in the DOM.
   *
   * @param checkoutSession - The Stripe checkout session to be initialized.
   * @returns A promise that resolves when the checkout session is successfully initialized.
   */
  public async initializeStripeCheckoutSession(
    checkoutSession: StripeCheckoutSession
  ): Promise<void> {
    this.logger.debug('Initializing stripe checkout session');

    const stripe = await this.loadStripe();

    const checkout = await stripe.initEmbeddedCheckout({
      clientSecret: checkoutSession.clientSecret,
    });

    checkout.mount('#checkout');
    this.logger.debug('Stripe checkout session initialized');
  }

  private loadStripe(): Promise<Stripe> {
    this.logger.debug('Loading stripe');
    const stripe = loadStripe(this.appConfig.stripe.publicKey);
    this.logger.debug('Stripe loaded');
    return stripe;
  }
}
