import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { StripeCheckoutSession } from '../models/stripe-checkout-session';
import { firstValueFrom, map, Observable } from 'rxjs';
import { StripeCheckoutSessionResult } from '../models/stripe-checkout-session-result';
import { AppConfig } from 'src/app/core/configuration/app-config';

@Injectable({
  providedIn: 'root',
})
export class StripeApiService {
  private http = inject(HttpClient);
  private baseUrl = '/api/v1/stripe/checkout';
  private appSettings = inject(AppConfig);

  public createCheckoutSession(
    priceIds: string[],
    returnUrl: string,
    noCreditCard = false
  ): Observable<StripeCheckoutSession> {
    const body = {
      prices: priceIds,
      returnUrl: returnUrl,
      noCreditCard,
    };

    return this.http.post<StripeCheckoutSession>(`${this.baseUrl}`, body);
  }

  public getSessionStatus(
    sessionId: string
  ): Promise<StripeCheckoutSessionResult> {
    return firstValueFrom(
      this.http.get<StripeCheckoutSessionResult>(
        `${this.baseUrl}/status?sessionId=${sessionId}`
      )
    );
  }

  public getPaymentPortalUrl(): Promise<string> {
    const params = new HttpParams().set(
      'redirectUrl',
      `${this.appSettings.appUrl}/settings`
    );

    return firstValueFrom(
      this.http
        .post<{
          url: string;
        }>(`${this.baseUrl}/portal`, {}, { params })
        .pipe(map((response) => response.url))
    );
  }
}
