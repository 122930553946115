import { ProgramStore } from 'src/app/infrastructure/models/interfaces/program-store';
import { NumbersProgram } from './numbers-program';
import { InjectionToken, Signal } from '@angular/core';
import { Unsubscribable } from 'rxjs';

export const NumbersProgramStoreToken = new InjectionToken<NumbersProgramStore>(
  'NumbersProgramStoreToken'
);

export interface NumbersProgramStore extends ProgramStore<NumbersProgram> {
  isUpdatingCurrentProgram: Signal<boolean>;
  haveErrorUpdatingCurrentProgram: Signal<boolean>;

  changeHighNumber(toChange: {
    programId: string;
    programType: number;
    newHigh: number;
  }): Unsubscribable;
}
