import { Routes } from '@angular/router';
import { NumbersProgramService } from './services/numbers-program.service';
import { NumbersProgramStoreToken } from './models/numbers-program.store';
import { QuantityProgramStore } from './stores/quantity-program.store';
import { NumericProgramStore } from './stores/numeric-program.store';

export const NumbersProgramAddProgramRoutes: Routes = [
  {
    path: 'numeric',
    loadComponent: () =>
      import(
        './containers/numbers-program-settings/numbers-program-settings.component'
      ).then((m) => m.NumbersProgramSettingsComponent),
  },
  {
    path: 'quantity',
    loadComponent: () =>
      import(
        './containers/numbers-program-settings/numbers-program-settings.component'
      ).then((m) => m.NumbersProgramSettingsComponent),
  },
];

export const NumbersProgramEditProgramRoutes: Routes = [
  {
    path: 'numeric',
    loadComponent: () =>
      import(
        './containers/numbers-program-settings/numbers-program-settings.component'
      ).then((m) => m.NumbersProgramSettingsComponent),
  },
  {
    path: 'quantity',
    loadComponent: () =>
      import(
        './containers/numbers-program-settings/numbers-program-settings.component'
      ).then((m) => m.NumbersProgramSettingsComponent),
  },
];

export const NumbersProgramPlanProgramRoutes: Routes = [
  {
    path: 'numeric/:childrenIdentifier',
    loadComponent: () =>
      import(
        './containers/plan-numbers-program/plan-numbers-program.component'
      ).then((m) => m.PlanNumbersProgramComponent),
    providers: [
      NumbersProgramService,
      {
        provide: NumbersProgramStoreToken,
        useExisting: NumericProgramStore,
      },
    ],
  },
  {
    path: 'quantity/:childrenIdentifier',
    loadComponent: () =>
      import(
        './containers/plan-numbers-program/plan-numbers-program.component'
      ).then((m) => m.PlanNumbersProgramComponent),
    providers: [
      NumbersProgramService,
      {
        provide: NumbersProgramStoreToken,
        useExisting: QuantityProgramStore,
      },
    ],
  },
];
