import { inject } from '@angular/core';
import { ProgramDto } from '../../../programs/all-programs/models/dtos/program-dto';
import { Program } from '../../../programs/all-programs/models/program';
import { SpecificProgramApi } from '../../models/interfaces/specific-program-api';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LoadFilter } from '../../models/interfaces/load-api';

export abstract class SpecificProgramApiService<TProgram extends Program, TDto extends ProgramDto>
implements SpecificProgramApi<TProgram, TDto> {

  protected http = inject(HttpClient);
  protected abstract readonly baseUrl: string;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadEntities(filter: LoadFilter): Observable<TDto[]> {
    throw new Error('Method not implemented.');
  }

  addEntity(program: TProgram): Observable<TDto> {
    const toAdd = this.toDto(program);

    return this.http.post<TDto>(
      this.baseUrl,
      toAdd
    );
  }

  editEntity(editedProgram: TProgram): Observable<TDto> {
    const toEdit = this.toDto(editedProgram);

    return this.http.put<TDto>(
      `${this.baseUrl}/${editedProgram.id}`,
      toEdit
    );
  }

  removeEntity(program: TProgram): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${program.id}`);
  }

  protected abstract toDto(program: TProgram): TDto;
}
