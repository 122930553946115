import { InjectionToken, inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { ChildStore } from 'src/app/features/children/children.store';
import { withSpecificProgram } from 'src/app/infrastructure/store-features/with-specific-program.feature';
import { KnowledgeProgram } from '../models/knowledge-program';
import { ProgramStore } from 'src/app/infrastructure/models/interfaces/program-store';
import { KnowledgeProgramDto } from '../models/knowledge-program.dto.ts';
import {
  mapKnowledgeProgramDtoToKnowledgeProgram,
  mapKnowledgeProgramToProgramDto,
} from '../services/knowledge-program.mapper';
import { KnowledgeProgramApiService, KnowledgeProgramApiToken } from '../services/knowledge-program-api.service';
import { KnowledgeProgramSettings } from '../knowledge-program-settings';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { ProcessLoad } from 'src/app/infrastructure/models/entity-process-state';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { LoadCatgoriesFilter } from '../models/load-knowledge-program.filters';
import { exhaustMap, filter, pipe, tap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { KnowledgeProgramCrudMessages } from '../models/knowledge-program-crud-messages';

export const KnowledgeProgramStoreToken = new InjectionToken<
  ProgramStore<KnowledgeProgram>
>('KnowledgeProgramProgramStore');

export type KnowledgeProgramState = {
  categories: string[];
  loadingCategories: ProcessLoad<LoadCatgoriesFilter> | undefined;
};

const initialState: KnowledgeProgramState = {
  categories: [],
  loadingCategories: undefined,
};

export const KnowledgeProgramStore = signalStore(
  {
    providedIn: 'root', protectedState: false,
  },
  withMethods((_, childStore = inject(ChildStore)) => ({
    mapEntity: (dto: KnowledgeProgramDto) =>
      mapKnowledgeProgramDtoToKnowledgeProgram(dto, childStore.entities()),
    mapEntityToDto: (program: KnowledgeProgram) =>
      mapKnowledgeProgramToProgramDto(program),
  })),
  withSpecificProgram<KnowledgeProgram, KnowledgeProgramDto>(
    KnowledgeProgramApiToken,
    [KnowledgeProgramSettings],
    KnowledgeProgramCrudMessages
  ),
  withState(initialState),
  withMethods((store) => {
    const api = inject(KnowledgeProgramApiService);
    const logger = inject(AppLogger);

    const loadCategories = rxMethod<void>(
      pipe(
        filter(() => store.loadingCategories() === undefined),
        tap(() => {
          const load: ProcessLoad<LoadCatgoriesFilter> = {
            filter: { identifier: 'load-categories', id: '' },
            status: 'processing',
            error: undefined,
          };

          patchState(store, (state) => ({
            ...state,
            loadingCategories: load,
          }));
        }),
        exhaustMap(() => {
          return api.getAllCategories().pipe(
            tapResponse((categories) => {
              const status: ProcessLoad<LoadCatgoriesFilter> = {
                ...store.loadingCategories(),
                status: 'loaded'
              };

              patchState(store, (state) => ({
                ...state,
                categories,
                loadingCategories: status,
              }));
            },
            (error: HttpErrorResponse) => {
              logger.warning('Failed to load categories', error);

              const load: ProcessLoad<LoadCatgoriesFilter> = {
                ...store.loadingCategories(),
                status: 'error',
                error: error.message
              };

              patchState(store, (state) => ({
                ...state,
                loadingCategories: load,
              }));
            }
          ));
        })
      )
    );

    return { loadCategories };
  }),
  withHooks((_, logger = inject(AppLogger)) => ({
    onInit() {
      logger.info('KnowledgeProgramStore initialized');
    },
  }))
);
