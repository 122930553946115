import { Inject, Injectable } from '@angular/core';
import { ReadingCategoryProgramSettings } from '../reading-category-program.settings';
import { ReadingCategory } from '../models/reading-category';
import { ReadingProgram } from '../../reading-program/models/reading-program';
import { ReadingCategoryStoreToken } from '../stores/reading-categories.store';
import { ProgramSetStore } from 'src/app/infrastructure/models/interfaces/program-set-store';
import { ReadingCategoryProgramStoreToken } from '../stores/reading-category-program.store';
import { PlanReadingCategoryProgramService } from './plan-reading-category-program.service';
import { ProgramStore } from 'src/app/infrastructure/models/interfaces/program-store';

@Injectable({
  providedIn: 'root',
})
export class PlanSingleWordsProgramService extends PlanReadingCategoryProgramService {
  public additionalAddActions: {
    actionName: string;
    navigateTo: string[];
    featureFlag: string | undefined;
  }[] = [
    {
      actionName: 'Add category',
      navigateTo: ['add-category'],
      featureFlag: undefined,
    },
    {
      actionName: 'Create for books',
      navigateTo: ['new-from-books'],
      featureFlag: 'plan-from-book',
    },
  ];

  constructor(
    @Inject(ReadingCategoryStoreToken)
    store: ProgramSetStore<ReadingCategory>,
    @Inject(ReadingCategoryProgramStoreToken)
    programStore: ProgramStore<ReadingProgram>
  ) {
    super(store, programStore, [ReadingCategoryProgramSettings.singleWords]);
  }
}
