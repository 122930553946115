import { ProgramStoreSettings } from 'src/app/infrastructure/models/program-store-settings';
import { KnowledgeProgram } from './models/knowledge-program';
import {
  KnowledgeProgramStore,
} from './stores/knowledge-bit-program.store';
import { KnowledgeProgramSettings } from './knowledge-program-settings';

export const KnowledgeProgramStoreSettings: ProgramStoreSettings<KnowledgeProgram> =
  {
    storeType: KnowledgeProgramStore,
    programSettings: KnowledgeProgramSettings,
  };
