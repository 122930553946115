import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ChecklistRoutes } from './features/checklist-for-programs/checklist.routes';
import { StaticPagesRoutes } from './features/home/static-pages.routing';
import { userAccountRoutes } from './features/user-account/user-routes';
import { SettingsRoutes } from './features/settings/settings-routing';
import { OnboardingRoutes } from './features/onboard-user/onboarding.routes';
import { PresentRoutes } from './features/present/present.routes';
import { PlanProgramRoutes } from './features/plan-program/plan-program.routes';
import { CheckoutRoutes } from './features/checkout/checkout.routes';
import { activeSubscriptionGuard } from './infrastructure/guards/active-subscription.guard';

export const appRoutes: Routes = [
  {
    path: 'user',
    loadComponent: () =>
      import(
        'src/app/layout/containers/section-container/app-section-container.component'
      ).then((mod) => mod.AppSectionContainerComponent),
    children: [...userAccountRoutes],
  },
  {
    path: 'checklist',
    loadComponent: () =>
      import(
        'src/app/features/checklist-for-programs/containers/checklist-for-programs-page/checklist-for-programs.component'
      ).then((mod) => mod.ChecklistForProgramsComponent),
    data: { hideHeader: true },
    children: [...ChecklistRoutes],
    canActivate: [activeSubscriptionGuard],
  },
  {
    path: 'plan',
    data: { title: 'Plan programs', hideHeader: true },
    children: [
      ...PlanProgramRoutes,
      {
        path: 'prep-for-tomorrow',
        loadComponent: () =>
          import(
            './features/prep-for-tomorrow/containers/prep-for-tomorrow-page/prep-for-tomorrow.component'
          ).then((mod) => mod.PrepForTomorrowComponent),
      },
    ],
    providers: [],
    canActivate: [activeSubscriptionGuard],
  },
  {
    path: 'settings',
    loadComponent: () =>
      import(
        'src/app/layout/containers/section-container/app-section-container.component'
      ).then((mod) => mod.AppSectionContainerComponent),
    data: { title: 'Settings', hideHeader: true },
    children: [...OnboardingRoutes, ...SettingsRoutes],
  },
  {
    path: 'get-started',
    children: [...OnboardingRoutes],
  },
  {
    path: 'home',
    loadComponent: () =>
      import(
        './layout/containers/section-container/app-section-container.component'
      ).then((mod) => mod.AppSectionContainerComponent),
    data: { title: 'Early Learning App' },
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./features/home/containers/home/home.component').then(
            (mod) => mod.HomeComponent
          ),
      },
    ],
  },
  {
    path: 'info',
    loadComponent: () =>
      import(
        './layout/containers/section-container/app-section-container.component'
      ).then((mod) => mod.AppSectionContainerComponent),
    data: {
      title: 'Information',
      hideHeader: true,
    },
    children: [...StaticPagesRoutes],
  },
  {
    path: 'present',
    children: [...PresentRoutes],
    canActivate: [activeSubscriptionGuard],
  },
  ...CheckoutRoutes,
  {
    path: '',
    loadComponent: () =>
      import(
        './layout/containers/section-container/app-section-container.component'
      ).then((mod) => mod.AppSectionContainerComponent),
    data: { title: 'Early Learning App' },
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./features/home/containers/home/home.component').then(
            (mod) => mod.HomeComponent
          ),
      },
    ],
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled', // Don't perform initial navigation in iframes
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
