import { ProgramSettings } from "src/app/infrastructure/models/program-settings";
import { ReadingProgramType } from "../reading-program/models/reading-program-type";
import { ReadingProgramSettings } from "../reading-program/reading-program-settings";

export const HomemadeBooksProgramSettings: ProgramSettings = {
  programType: ReadingProgramType.homemadeBooks,
  programName: 'Homemade books',
  typeName: 'homemade-books',
  urlName: 'homemade-books',
  idPrefix: `${ReadingProgramSettings.readingProgramIdStart}_homemade-books_`,
  urlPath: ['reading', 'homemade-books'],
  isActive: true,
  presentsInApp: false
}
