/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { ChildDTO } from '../models/child-dto';
import { Child } from 'src/app/features/children/models/child';
import { DataLoadApi } from 'src/app/infrastructure/models/interfaces/data-load-api';

export const ChildApiToken = new InjectionToken<DataLoadApi<ChildDTO>>(
  'ChildrenApiService'
);

@Injectable({
  providedIn: 'root',
})
export class ChildrenApiService implements DataLoadApi<ChildDTO> {
  protected readonly apiEndpoint = '/api/v1/user/children';

  constructor(private httpClient: HttpClient) {}

  loadData(): Observable<ChildDTO[]> {
    return this.httpClient.get<ChildDTO[]>(this.apiEndpoint);
  }

  public addChild(child: Child): Observable<ChildDTO> {
    const body = childToChildVM(child);

    return this.httpClient.post<ChildDTO>(this.apiEndpoint, body);
  }

  public validateAdd(child: Child): Observable<any> {
    const body = childToChildVM(child);

    return this.httpClient.post(`${this.apiEndpoint}/validate/add`, body);
  }

  public editChild(child: Child): Observable<ChildDTO> {
    const body = childToChildVM(child);
    return this.httpClient.put<ChildDTO>(
      `${this.apiEndpoint}/${child.id}`,
      body
    );
  }

  public validateUpdate(child: Child): Observable<object> {
    const body = childToChildVM(child);
    return this.httpClient.post(`${this.apiEndpoint}/validate/update`, body);
  }

  public removeChild(childId: string): Observable<object> {
    return this.httpClient.delete(`${this.apiEndpoint}/${childId}`);
  }
}

export const childToChildVM = (child: Child) => {
  const dto: ChildDTO = {
    apiId: child.id,
    firstName: child.firstName,
    lastName: child.lastName,
  };
  return dto;
};
