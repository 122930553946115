import { EditSetStore } from "src/app/features/plan-set-program/models/interfaces/edit-set-store";
import { KnowledgeSet } from "../models/knowledge-set";
import { computed, inject, InjectionToken } from "@angular/core";
import { EditSetInfoState, withEditSetFeature } from "src/app/features/plan-set-program/with-edit-set.feature";
import { KnowledgeProgramSettings } from "../knowledge-program-settings";
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { KnowledgeSetStoreToken } from "./knowledge-set.store";
import { KnowledgeProgramStoreToken } from "./knowledge-bit-program.store";
import { ProgramSetContent } from "../../set-programs/models/program-set-content";
import { AppLogger } from "src/app/infrastructure/services/logging/app-logger.service";

export const EditKnowledgeSetStoreToken = new InjectionToken<EditSetStore<KnowledgeSet>>('EditKnowledgeSetStore');

const setInfoState: EditSetInfoState<KnowledgeSet> = {
  contentName: 'Bits',
  setName: 'Set',
  titleName: 'Title',
  defaultSet: {
    content: [
      {
        textOnCard: '',
      },
    ],
  } as KnowledgeSet,
  urlNameForProgramEdit: [KnowledgeProgramSettings.urlPath.join('/')],
};

export const EditKnowledgeSetStore = signalStore(
  {
    providedIn: 'root', protectedState: false,
  },
  withState(setInfoState),
  withEditSetFeature<KnowledgeSet>(KnowledgeSetStoreToken),
  withComputed((store) => {
    const hasContent = computed(() => {
      return store.set()?.content.length > 0;
    });
    const canSave = computed(() => {
      const set = store.set();

      if (set == undefined) {
        return false;
      }

      const titleIsValid = set.title !== undefined && set.title.length > 1;

      if (!hasContent()) {
        return titleIsValid;
      }

      return true;
    });

    const content = computed(() =>
      store
        .set()
        ?.content.filter(
          (c) => c.textOnCard != undefined && c.textOnCard.length > 0
        ).map(x => x.textOnCard)
    );

    return { hasPages: hasContent, canSave, content };
  }),
  withMethods((store) => {
    const programStore = inject(KnowledgeProgramStoreToken);
    const setStore = inject(KnowledgeSetStoreToken);

    const setContent = (pages: ProgramSetContent[]) => {
      patchState(store, (state) => ({
        ...state,
        set: {
          ...state.set,
          content: pages,
        },
      }));
    };

    const setContentFromStrings = (content: string[]) => {
      setContent(content.map((co) => ({ textOnCard: co })));
    };

    const removeContent = (index: number) => {
      const content = store.set()?.content || [];
      content.splice(index, 1);
      setContent(content);
    };

    const updateTextOnContent = (newText: string, index: number) => {
      const content = store.set()?.content || [];
      content[index].textOnCard = newText;
      setContent(content);
    };

    const addEmptyContent = () => {
      const content = store.set()?.content || [];
      content.push({ textOnCard: '' });
      setContent(content);
    };

    const saveSet = () => {
      const set: KnowledgeSet = {
        ...store.set(),
        programId: programStore.currentProgram().id,
      };
      set.content = set.content.filter(
        (c) => c.textOnCard != undefined && c.textOnCard.length > 0
      );

      if (store.isEditMode()) {
        setStore.editEntity(set);
      } else {
        setStore.addEntity(set);
      }
    };

    return {
      setContentFromStrings,
      removeContent,
      updateTextOnContent,
      addEmptyContent,
      saveSet,
    };
  }),
  withHooks((_, logger = inject(AppLogger)) => ({
    onInit() {
      logger.debug('Edit knowledge set store initialized');
    },
  }))
);
