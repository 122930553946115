import { Injectable, InjectionToken } from '@angular/core';
import { mapReadingCategoryProgramToDto } from './reading-category-program.mapper.ts';
import { ReadingProgram } from '../../reading-program/models/reading-program';
import { ReadingProgramDto } from '../../reading-program/models/reading-program-dto';
import { SpecificProgramApi } from 'src/app/infrastructure/models/interfaces/specific-program-api';
import { SpecificProgramApiService } from 'src/app/infrastructure/services/specific-program/specific-program-api.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

export const ReadingCategoryProgramApiServiceToken = new InjectionToken<
  SpecificProgramApi<ReadingProgram, ReadingProgramDto>
>('ReadingCategoryProgramApiService');

@Injectable({
  providedIn: 'root',
})
export class ReadingCategoryProgramAPIService extends SpecificProgramApiService<
  ReadingProgram,
  ReadingProgramDto
> {
  protected baseUrl = '/api/v1/programs/reading/categories';

  public checkTitle(
    titleToCheck: string,
    programId: string,
    setId: string | undefined = undefined
  ): Observable<CheckTitleResponse> {
    let params = new HttpParams().set('title', titleToCheck);
    params = params.append('programId', programId);
    if (setId) {
      params = params.append('setId', setId);
    }

    return this.http.get<CheckTitleResponse>(
      `${this.baseUrl}/sets/check-title`,
      { params }
    );
  }

  protected toDto(program: ReadingProgram): ReadingProgramDto {
    return mapReadingCategoryProgramToDto(program);
  }
}

export type CheckTitleResponse = {
  value: string;
  hasChanged: boolean;
};
