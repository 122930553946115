import { enableProdMode, importProvidersFrom } from '@angular/core';
import { APP_CONFIG, AppConfig } from './app/core/configuration/app-config';
import { environment } from './environments/environment';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { bootstrapApplication } from '@angular/platform-browser';
import {
  RouteReuseStrategy,
  provideRouter,
  withComponentInputBinding,
  withRouterConfig,
} from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular/standalone';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { appRoutes } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { CoreModule } from './app/core/core.module';

fetch('/assets/config.json')
  .then((response) => response.json())
  .then((config) => {
    if (environment.production) {
      enableProdMode();
    }

    bootstrapApplication(AppComponent, {
      providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: APP_CONFIG, useValue: new AppConfig(config) },
        importProvidersFrom(CoreModule),
        provideIonicAngular(),
        provideRouter(
          appRoutes,
          withComponentInputBinding(),
          withRouterConfig({
            paramsInheritanceStrategy: 'always',
          })
        ),
        provideHttpClient(withInterceptorsFromDi()),
      ],
    }).catch((err) => console.error(err));
  });
