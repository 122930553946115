import { InjectionToken, Signal } from "@angular/core";
import { UserStoreImp } from "./user.store";
import { HttpErrorResponse } from "@angular/common/http";
import { Unsubscribable } from "rxjs";
import { IdTokenClaims } from "@azure/msal-browser";
import { User } from "../models/user";

export const UserStoreToken = new InjectionToken<UserStore>('UserStore');

export function provideUserStore() {
  return {
    provide: UserStoreToken,
    useExisting: UserStoreImp,
  };
}

export interface UserStore {
  isAuthenticated: Signal<boolean>;
  userId: Signal<string | null>;
  userClaims: Signal<(IdTokenClaims & { [key: string]: unknown }) | null>;

  user: Signal<User | null>;
  userRole: Signal<'User' | 'Admin'>;

  updateUserFromMsal(): void;

  getUserFromApi(): void;
  getUserFromApiIsLoading: Signal<boolean>;
  getUserFromApiLoaded: Signal<boolean>;
  getUserFromApiError: Signal<HttpErrorResponse | null>;

  updateUserSubscription():void;
  updateUserSubscriptionIsLoading: Signal<boolean>;
  updateUserSubscriptionLoaded: Signal<boolean>;
  updateUserSubscriptionError: Signal<HttpErrorResponse | null>;

  login(): Unsubscribable;
  logout(): Unsubscribable;
  register(): Unsubscribable;
  edit(): Unsubscribable;
  getUserClaim(claim: string): unknown;
}

