import { computed, Signal } from '@angular/core';
import { ProgramStore } from 'src/app/infrastructure/models/interfaces/program-store';
import { ProgramSettings } from 'src/app/infrastructure/models/program-settings';
import { SpecificProgramServiceService } from 'src/app/infrastructure/services/specific-program/specific-program-service.service';
import { Program } from 'src/app/programs/all-programs/models/program';
import { EditSetStore } from '../../plan-set-program/models/interfaces/edit-set-store';
import { ProgramSetWithContent } from 'src/app/programs/set-programs/models/program-set-with-content';
import { ProgramSetContent } from 'src/app/programs/set-programs/models/program-set-content';

export class SpecificWordStatusSetService extends SpecificProgramServiceService {
  currentProgramId: Signal<string> = computed(() => {
    return this.programStore.currentProgram().id;
  });
  currentSetId: Signal<string | undefined> = computed(() => {
    return this.editSetStore.set()?.id;
  });

  currentSetContent: Signal<string[]> = computed(() => {
    return this.editSetStore.set()?.content.map((x) => x.textOnCard) || [];
  });

  constructor(
    private programStore: ProgramStore<Program>,
    private editSetStore: EditSetStore<
      ProgramSetWithContent<ProgramSetContent>
    >,
    settings: ProgramSettings[]
  ) {
    super(settings);
  }

  public removeContentAt(index: number) {
    this.editSetStore.removeContent(index);
  }
}
