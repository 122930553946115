import { Injectable, InjectionToken } from '@angular/core';
import { ProgramSetApiService } from '../../set-programs/services/program-set-api.service';
import { KnowledgeSet } from '../models/knowledge-set';
import { KnowledgeSetDTO } from '../models/knowledge-set-dto';
import { mapKnowledgeSetToDto } from './knowledge-program.mapper';
import { ProgramSetApi } from '../../set-programs/models/interfaces/program-set-api';

export const KnowledgeSetApiToken = new InjectionToken<
  ProgramSetApi<KnowledgeSet, KnowledgeSetDTO>
>('KnowledgeSetApi');

@Injectable({
  providedIn: 'root',
})
export class KnowledgeSetApiService extends ProgramSetApiService<
  KnowledgeSet,
  KnowledgeSetDTO
> {
  protected baseUrl = '/api/v1/programs/knowledge-programs/sets';

  protected setToDto(set: KnowledgeSet): KnowledgeSetDTO {
    return mapKnowledgeSetToDto(set);
  }
}
