import { computed, inject, Injectable, Signal } from '@angular/core';
import { ChecklistItem } from 'src/app/features/checklist-for-programs/models/checklist-item';
import { SpecificProgramChecklistService } from 'src/app/features/checklist-for-programs/models/interfaces/specific-program-checklist.service';
import { GenericProgramSettings } from '../generic-program-settings';
import { SpecificProgramServiceService } from 'src/app/infrastructure/services/specific-program/specific-program-service.service';
import { GenericProgramStore } from '../stores/generic-program.store';

@Injectable({
  providedIn: 'root',
})
export class GenericProgramChecklistService
  extends SpecificProgramServiceService
  implements SpecificProgramChecklistService
{
  private programDayStore = inject(GenericProgramStore);

  constructor() {
    super([GenericProgramSettings]);
  }
  getProgramNameForProgram(programId: string): Signal<string | undefined> {
    return computed(() => {
      const program = this.programDayStore.programWithId(programId)();

      if (!program) {
        return undefined;
      }

      return program.name;
    })
  }

  getSetsForProgram(programId: string): Signal<ChecklistItem[]> {
    return computed(() => {
      const program = this.programDayStore.programWithId(programId)();

      if (!program) {
        return [];
      }

      const listItem: ChecklistItem = {
        setId: program.id,
        completedSessionsToday: program.completedSessionsToday,
        content: program.name,
      };

      return [listItem];
    });
  }
}
