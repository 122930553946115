import { Injectable, InjectionToken } from '@angular/core';
import { mapGenericProgramToDto } from './generic-program.mapper';
import { GenericProgram } from '../models/generic-program';
import { GenericProgramDto } from '../models/generic-program-dto';
import { SpecificProgramApiService } from 'src/app/infrastructure/services/specific-program/specific-program-api.service';
import { SpecificProgramApi } from 'src/app/infrastructure/models/interfaces/specific-program-api';

export const GenericProgramApiToken = new InjectionToken<
  SpecificProgramApi<GenericProgram, GenericProgramDto>
>('GenericProgramApi');

@Injectable({
  providedIn: 'root',
})
export class GenericProgramApiService extends SpecificProgramApiService<
  GenericProgram,
  GenericProgramDto
> {
  protected baseUrl = '/api/v1/programs/generic';

  protected toDto(program: GenericProgram): GenericProgramDto {
    return mapGenericProgramToDto(program);
  }
}
