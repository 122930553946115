import { CrudMessages } from "src/app/infrastructure/models/crud-messages";

export const generateProgramSetCrudMessages = (setName: string) => {
  const messages: CrudMessages = {
    addUserError: {
      heading: `Failed to add ${setName}`,
      message: `Some data on the new ${setName} was invalid, and it could not be added. Please try again.`,
    },
    addServerFail: {
      heading: `Failed to add ${setName}`,
      message: `We did something wrong and could not save your new ${setName}. Please try again later.`,
    },
    editUserError: {
      heading: `Failed to edit ${setName}`,
      message: `Some data on the ${setName} was invalid, and it could not be edited. Please try again.`,
    },
    editServerFail: {
      heading: `Failed to edit ${setName}`,
      message: `We did something wrong and could not save your changes. Please try again later.`,
    },
    removeUserError: {
      heading: `Failed to remove ${setName}`,
      message: `We could not remove the ${setName}. Please try again.`,
    },
    removeServerFail: {
      heading: `Failed to remove ${setName}`,
      message: `We did something wrong and could not remove the ${setName}. Please try again later.`,
    },
  };

  return messages;
};
