import { ProgramSettings } from '../../models/program-settings';
import { SpecificProgramServiceService } from './specific-program-service.service';

export class SpecificProgramSettingsService extends SpecificProgramServiceService {

  constructor(private programs: ProgramSettings[]) {
    super(programs);
  }

  getProgramUrlPathForProgramId(programId: string): string[] {
    const program = this.getProgramSettingsForProgramId(programId);
    if (program) {
      return program.urlPath;
    }

    throw new Error('Program not found');
  }

  getProgramUrlNameForProgramType(programType: number): string {
    const program = this.programs.find(
      (settings) => settings.programType === programType
    );
    if (program) {
      return program.urlName;
    }

    throw new Error('Program type not found');
  }

  getTypeFromProgramId(programId: string) {
    const program = this.getProgramSettingsForProgramId(programId);
    if (program) {
      return program.programType;
    }

    throw new Error('Program not found');
  }

  getProgramType(typeName: string): number {
    const program = this.programs.find(
      (settings) => settings.typeName === typeName
    );
    if (program) {
      return program.programType;
    }

    throw new Error('Program type not found');
  }

  getProgramTypeName(programType: number): string {
    const program = this.programs.find(
      (settings) => settings.programType === programType
    );
    if (program) {
      return program.typeName;
    }

    throw new Error('Program type not found');
  }

  programIsActive(programType: number): boolean {
    const settings = this.programs.find(
      (program) => program.programType === programType
    );

    if (settings) {
      return settings.isActive;
    }

    throw new Error('Program not found');
  }

  getTypeFromUrl(url: string) {
    const program = this.programs.find((settings) =>
      url.includes(settings.urlPath.join('/'))
    );
    if (program) {
      return program.programType;
    }

    throw new Error('Program type not found');
  }

  getProgramTypesThatPresentInApp(): number[] {
    const programs = this.programs.filter((program) => program.presentsInApp);

    return programs.map((program) => program.programType);
  }

  getProgramSettingsForType(programType: number): ProgramSettings {
    const settings = this.programs.find(x => x.programType === programType);

    return settings;
  }
}
