import {
  BrowserCacheLocation,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { AppConfig } from './app-config';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const MSALInstanceFactory = (appConfig: AppConfig) =>
  new PublicClientApplication({
    auth: {
      // Application (client) ID from the app registration
      clientId: appConfig.authentication.clientId,
      // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
      authority: appConfig.authentication.authorities.signUpSignIn,
      knownAuthorities: [appConfig.authentication.authorityDomain],
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback: () => {
          return
        },
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });

const scopes = (appConfig: AppConfig) => [
  ...appConfig.authentication.scopes,
  'openid',
  'offline_access',
];

export const MSALInterceptorConfigFactory = (appConfig: AppConfig) => {
  const protectedResourceMap = new Map<string, Array<string> | null>();
  protectedResourceMap.set(`${appConfig.apiEndpoint}/api/v1/sign-up/email`, null);
  protectedResourceMap.set(`${appConfig.apiEndpoint}/*`, scopes(appConfig));

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MSALGuardConfigFactory = (appConfig: AppConfig) => ({
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: scopes(appConfig),
  },
  loginFailedRoute: '/login-failed',
});
