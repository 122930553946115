import { Injectable, InjectionToken } from '@angular/core';
import { mapNumberProgramToNumbersProgramDto } from '../numbers-program.mappers';
import { NumbersProgram } from '../../models/numbers-program';
import { NumbersProgramDto } from '../../models/numbers-program.dto';
import { SpecificProgramApi } from 'src/app/infrastructure/models/interfaces/specific-program-api';
import { SpecificProgramApiService } from 'src/app/infrastructure/services/specific-program/specific-program-api.service';

export const NumericProgramApiServiceToken = new InjectionToken<
  SpecificProgramApi<NumbersProgram, NumbersProgramDto>
>('NumericProgramApiService');

@Injectable({
  providedIn: 'root',
})
export class NumericProgramApiService extends SpecificProgramApiService<
  NumbersProgram,
  NumbersProgramDto
> {

  protected baseUrl = '/api/v1/programs/math/numeric';

  protected toDto(program: NumbersProgram): NumbersProgramDto {
    return mapNumberProgramToNumbersProgramDto(program);
  }
}
