import {
  AfterViewChecked,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControlOptions,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Child } from 'src/app/features/children/models/child';
import { ServerChildValidator } from '../../validators/server-child.validator';
import { NgIf } from '@angular/common';
import { IonInput, ModalController, IonHeader, IonButtons, IonToolbar, IonTitle, IonButton, IonContent, IonNote } from '@ionic/angular/standalone';


@Component({
  selector: 'app-edit-child-modal',
  templateUrl: './edit-child-modal.component.html',
  styleUrls: ['./edit-child-modal.component.scss'],
  standalone: true,
  imports: [IonTitle, IonHeader, IonToolbar, IonButtons, IonButton, IonContent, IonNote, IonInput, NgIf, ReactiveFormsModule],
})
export class EditChildModalComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() child: Child | null = null;
  @Input() errors: { [key: string]: string } = {};
  @Input() isEdit: boolean | null = null;
  @ViewChild('firstNameInput', { static: false }) emailInput!: IonInput;

  public isEditMode = false;
  public form: FormGroup;

  focusIsSet = false;

  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private serverChildValidator: ServerChildValidator
  ) {}

  public get firstName() {
    return this.form.get('firstName');
  }

  public get lastName() {
    return this.form.get('lastName');
  }

  public ngOnInit(): void {
    if (this.isEdit === null) {
      this.isEditMode = this.child !== null;
    } else {
      this.isEditMode = this.isEdit;
    }

    this.form = this.formBuilder.group(
      {
        firstName: this.formBuilder.control(this.child?.firstName, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
        ]),
        lastName: this.formBuilder.control(this.child?.lastName, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
        ]),
      },
      {
        asyncValidators: this.serverChildValidator.validate.bind(
          this.serverChildValidator
        ),
      } as FormControlOptions
    );

    if (this.errors) {
      const validationErrors = this.serverChildValidator.getErrors(this.errors);
      this.form.setErrors(validationErrors);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.child && !changes.child.firstChange){
      this.form.patchValue({
        firstName: this.child?.firstName,
        lastName: this.child?.lastName,
      });
    }
  }

  public ngAfterViewChecked(): void {
    if (!this.focusIsSet) {
      this.emailInput.setFocus();
      // Disable focus on setTimeout()
      // Timeout needed for buggy behavior otherwise!
      setTimeout(() => {
        this.focusIsSet = true;
      }, 1000);
    }
  }

  public save(): void {
    const child: Child = {
      ...this.child,
      firstName: this.form.get('firstName').value,
      lastName: this.form.get('lastName').value,
    };

    this.modalController.dismiss(child);
  }

  public cancle(): void {
    this.modalController.dismiss();
  }

  public resetForm(): void {
    this.form.reset();
  }
}
