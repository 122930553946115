import { InjectionToken } from '@angular/core';

export class AppConfig {
  apiEndpoint: string;
  appUrl: string;
  environment: string;
  authentication: {
    clientId: string;
    scopes: string[];
    authorityDomain: string;
    policyNames: {
      signUpSignIn: string;
      register: string;
      editProfile: string;
      resetPassword: string;
    };
    authorities: {
      signUpSignIn: string;
      register: string;
      editProfile: string;
      resetPassword: string;
    };
  };

  appInsights: {
    instrumentationKey: string;
  };

  logging: {
    logLevel: string;
    console: boolean;
  }

  stripe: {
    publicKey: string;
  }


  public get isProduction(): boolean {
    return this.environment.toLowerCase() === 'production';
  }

  public get isStage(): boolean {
    return this.environment.toLowerCase() === 'stage';
  }

  constructor(loadedConfig: AppConfig | undefined) {
    if(loadedConfig != undefined){
      this.appUrl = loadedConfig.appUrl;
      this.apiEndpoint = loadedConfig.apiEndpoint;
      this.environment = loadedConfig.environment;
      this.authentication = loadedConfig.authentication;
      this.appInsights = loadedConfig.appInsights;
      this.logging = loadedConfig.logging;
      this.stripe = loadedConfig.stripe;
    }
  }
}

// eslint-disable-next-line prefer-const
export let APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');
