import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
  withState,
} from '@ngrx/signals';
import { updateEntity } from '@ngrx/signals/entities';
import { ProgramSetDTO } from 'src/app/programs/set-programs/models/dtos/program-set-dto';
import { ProgramSet } from 'src/app/programs/set-programs/models/program-set';
import { computed, inject, InjectionToken } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { SetStatus } from 'src/app/programs/set-programs/models/enums/set-status';
import {
  PlannedSetsResponse,
  ProgramSetApi,
} from 'src/app/programs/set-programs/models/interfaces/program-set-api';
import { withCrudFeature } from './with-crud-for-entity-state.feature';
import { makeApiCall } from './api-store-function';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { ProcessState } from '../models/entity-process-state';
import { Entity } from '../models/entity';
import { LoadFrontSetsForProgramFilter } from 'src/app/programs/set-programs/services/program-set-api.service';
import { pipe, switchMap, tap } from 'rxjs';
import { CrudMessages } from '../models/crud-messages';
import {
  dataErrorStatus,
  dataLoadedStatus,
  dataLoadingStatus,
  DataLoadingStatus,
} from '../models/data-loading-status';
import { tapResponse } from '@ngrx/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { addEntities } from '@ngrx/signals/entities';

type OperationType =
  | 'moveToCurrent'
  | 'moveToPlanned'
  | 'moveToCompleted'
  | 'moveSet';

export type OperationProcessState<T extends Entity> = ProcessState<T> & {
  operation: OperationType;
};

export type ProgramSetState<TSet extends ProgramSet> = {
  processes: OperationProcessState<TSet>[];
  _programPlannedStatus: {
    programId: string;
    hasMorePlanned: boolean;
    totalPlannedCount: number;
    loadMorePlannedStatus: DataLoadingStatus;
  }[];
};

export function withProgramSets<
  TSet extends ProgramSet,
  TSetDto extends ProgramSetDTO
>(
  apiToken: InjectionToken<ProgramSetApi<TSet, TSetDto>>,
  crudMessages: CrudMessages
) {
  return signalStoreFeature(
    {
      methods: type<{
        mapEntity: (dto: TSetDto) => TSet;
      }>(),
    },
    withState(() => {
      const initialState: ProgramSetState<TSet> = {
        processes: [],
        _programPlannedStatus: [],
      };

      return initialState;
    }),
    withCrudFeature<TSet, TSetDto>(apiToken, crudMessages),
    withMethods((state) => {
      const api = inject(apiToken);
      const logger = inject(AppLogger).forContext('WithProgramSetsFeature');

      const setsForProgramWithStatus = (programId: string, status: SetStatus) =>
        computed(() => {
          const sets = state.entities();
          const setWithStatus = sets.filter(
            (x) => x.programId === programId && x.status === status
          );

          return setWithStatus;
        });

      const currentSetsForProgram = (programId: string) =>
        setsForProgramWithStatus(programId, SetStatus.current);

      const plannedSetsForProgram = (programId: string) =>
        setsForProgramWithStatus(programId, SetStatus.planned);

      const completedSetsForProgram = (programId: string) =>
        setsForProgramWithStatus(programId, SetStatus.completed);

      const getSet = (setId: string) =>
        computed(() => state.entities().find((x) => x.id === setId));

      const loadFrontSets = (programId: string) => {
        const filter: LoadFrontSetsForProgramFilter = {
          id: programId,
          identifier: 'front-sets',
        };

        logger.debug(`loadFrontSets for ${programId}`);

        state.loadEntities(filter);
      };

      const createNewProcess = (
        change: { programId: string; setId: string },
        operation: OperationType
      ) => {
        const process: OperationProcessState<TSet> = {
          entity: state.entities().find((x) => x.id === change.setId),
          status: 'processing',
          error: undefined,
          operation,
        };

        return process;
      };

      const moveToCurrentApi = (change: {
        programId: string;
        setId: string;
      }) => {
        return api.changeStatus(
          change.programId,
          change.setId,
          SetStatus.current
        );
      };

      const moveToCurrentFinished = (change: {
        programId: string;
        setId: string;
      }) => {
        const inState = state.entities().find((x) => x.id === change.setId);

        patchState(
          state,
          updateEntity({
            id: change.setId,
            changes: { ...inState, status: SetStatus.current },
          })
        );
      };

      const createProcessForMoveToCurrent = (change: {
        programId: string;
        setId: string;
      }) => createNewProcess(change, 'moveToCurrent');

      const moveToCurrent = rxMethod<{
        setId: string;
        programId: string;
      }>(
        makeApiCall(
          state,
          logger,
          state.processes(),
          (updatedList) => ({ processes: updatedList }),
          moveToCurrentApi,
          () => null,
          moveToCurrentFinished,
          createProcessForMoveToCurrent
        )
      );

      const createProcessForMoveToPlanned = (change: {
        programId: string;
        setId: string;
      }) => createNewProcess(change, 'moveToPlanned');

      const moveToPlannedApi = (change: {
        programId: string;
        setId: string;
      }) => {
        return api.changeStatus(
          change.programId,
          change.setId,
          SetStatus.planned
        );
      };

      const moveToPlannedFinished = (change: {
        programId: string;
        setId: string;
      }) => {
        const inState = state.entities().find((x) => x.id === change.setId);

        patchState(
          state,
          updateEntity({
            id: change.setId,
            changes: { ...inState, status: SetStatus.planned },
          })
        );
      };

      const moveToPlanned = rxMethod<{
        setId: string;
        programId: string;
      }>(
        makeApiCall(
          state,
          logger,
          state.processes(),
          (updatedList) => ({ processes: updatedList }),
          moveToPlannedApi,
          () => null,
          moveToPlannedFinished,
          createProcessForMoveToPlanned
        )
      );

      /* Move to completed */
      const moveToCompleted = rxMethod<{
        setId: string;
        programId: string;
      }>(
        makeApiCall(
          state,
          logger,
          state.processes(),
          (updatedList) => ({ processes: updatedList }),
          (change) => {
            return api.changeStatus(
              change.programId,
              change.setId,
              SetStatus.completed
            );
          },
          () => null,
          (change) => {
            const inState = state.entities().find((x) => x.id === change.setId);

            patchState(
              state,
              updateEntity({
                id: change.setId,
                changes: { ...inState, status: SetStatus.completed },
              })
            );
          },
          (change) => createNewProcess(change, 'moveToCompleted')
        )
      );

      const changePosition = rxMethod<{
        setId: string;
        programId: string;
        position: number;
      }>(
        makeApiCall(
          state,
          logger,
          state.processes(),
          (updatedList) => ({ processes: updatedList }),
          (change) => {
            return api.moveSet(change.programId, change.setId, change.position);
          },
          () => null,
          (change) => {
            const inState = state.entities().find((x) => x.id === change.setId);
            let atPosition = state
              .entities()
              .filter(
                (x) =>
                  x.position >= change.position &&
                  x.programId === change.programId &&
                  x.status === SetStatus.planned
              );

            inState.position = change.position;
            atPosition = atPosition.map((x) => {
              x.position += 1;
              return x;
            });

            patchState(
              state,
              updateEntity({
                id: change.setId,
                changes: { ...inState },
              })
            );

            atPosition.forEach((set) => {
              patchState(
                state,
                updateEntity({
                  id: set.id,
                  changes: { ...set },
                })
              );
            });
          },
          (change) => createNewProcess(change, 'moveSet')
        )
      );

      const isLoadingMorePlannedForProgram = (programId: string) =>
        computed(() => {
          const status = state
            ._programPlannedStatus()
            .find((x) => x.programId === programId);

          if (!status) return false;

          return status.loadMorePlannedStatus.isLoading;
        });

      const loadMorePlanned = rxMethod<string>(
        pipe(
          tap((programId: string) => {
            let currentStatus = state
              ._programPlannedStatus()
              .find((x) => x.programId === programId);
            if (currentStatus) {
              currentStatus = {
                ...currentStatus,
                loadMorePlannedStatus: dataLoadingStatus,
              };
            } else {
              currentStatus = {
                programId,
                loadMorePlannedStatus: dataLoadingStatus,
                hasMorePlanned: false,
                totalPlannedCount: 0,
              };
            }

            patchState(state, (s) => ({
              ...s,
              _programPlannedStatus: [
                ...s._programPlannedStatus.filter(
                  (x) => x.programId != programId
                ),
                currentStatus,
              ],
            }));
          }),
          switchMap((programId: string) => {
            const currentLoaded = plannedSetsForProgram(programId)();

            return api.getPlannedSets(programId, 10, currentLoaded.length).pipe(
              tapResponse(
                (response: PlannedSetsResponse<TSetDto>) => {
                  patchState(
                    state,
                    addEntities(
                      response.categories.map((c) => state.mapEntity(c))
                    )
                  );

                  const currentStatus = state
                    ._programPlannedStatus()
                    .find((x) => x.programId === programId);

                  patchState(state, (s) => ({
                    ...s,
                    _programPlannedStatus: [
                      ...s._programPlannedStatus.filter(
                        (x) => x.programId != programId
                      ),
                      {
                        ...currentStatus,
                        loadMorePlannedStatus: dataLoadedStatus,
                        hasMorePlanned: response.hasMore,
                        totalPlannedCount: response.totalCount,
                      },
                    ],
                  }));
                },
                (error: HttpErrorResponse) => {
                  logger.error('Failed to load more planned sets', error);
                  const currentStatus = state
                    ._programPlannedStatus()
                    .find((x) => x.programId === programId);
                  patchState(state, (s) => ({
                    ...s,
                    _programPlannedStatus: [
                      ...s._programPlannedStatus.filter(
                        (x) => x.programId != programId
                      ),
                      {
                        ...currentStatus,
                        loadMorePlannedStatus: dataErrorStatus(error.message),
                      },
                    ],
                  }));
                }
              )
            );
          })
        )
      );

      const haveMorePlannedToLoad = (programId: string) =>
        computed(() => {
          const status = state
            ._programPlannedStatus()
            .find((x) => x.programId === programId);
          return status?.hasMorePlanned ?? true;
        });

      const haveErrorForLoadingMorePlanned = (programId: string) =>
        computed(() => {
          const status = state
            ._programPlannedStatus()
            .find((x) => x.programId === programId);

          if (!status) return false;

          return status.loadMorePlannedStatus.haveError;
        });

      const statusForLoadMorePlanned = (programId: string) =>
        computed(() => {
          const status = state
            ._programPlannedStatus()
            .find((x) => x.programId === programId);
          return status?.loadMorePlannedStatus;
        });

      const totalPlannedCount = (programId: string) =>
        computed(() => {
          const status = state
            ._programPlannedStatus()
            .find((x) => x.programId === programId);
          return status?.totalPlannedCount ?? 0;
        });

      return {
        loadFrontSets,
        loadMorePlanned,

        currentSetsForProgram,
        plannedSetsForProgram,
        completedSetsForProgram,
        getSet,
        isLoadingForProgram: state.isLoading,
        isLoadingMorePlannedForProgram,
        haveLoadedForProgram: state.haveLoaded,
        haveErrorForProgram: state.haveLoadingError,
        haveErrorForLoadingMorePlanned,
        moveToCurrent,
        moveToPlanned,
        moveToCompleted,
        changePosition,
        haveMorePlannedToLoad,
        statusForLoadMorePlanned,
        totalPlannedCount,
      };
    })
  );
}
