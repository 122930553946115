import { computed, inject, Signal } from '@angular/core';
import { ProgramSettings } from 'src/app/infrastructure/models/program-settings';
import { ProgramDayStore } from 'src/app/infrastructure/stores/program-day.store';
import { SpecificProgramServiceService } from 'src/app/infrastructure/services/specific-program/specific-program-service.service';
import { ChecklistItem } from '../models/checklist-item';
import { ProgramSetDTO } from 'src/app/programs/set-programs/models/dtos/program-set-dto';
import { SpecificProgramChecklistService } from '../models/interfaces/specific-program-checklist.service';

export abstract class SpecificProgramChecklistServiceBase<
    TSetDto extends ProgramSetDTO
  >
  extends SpecificProgramServiceService
  implements SpecificProgramChecklistService
{
  private programDayStore = inject(ProgramDayStore);

  constructor(settings: ProgramSettings[]) {
    super(settings);
  }

 public getProgramNameForProgram(): Signal<string | undefined> {
    return computed(() => {
      return undefined;
    });
  }

  public getSetsForProgram(programId: string): Signal<ChecklistItem[]> {
    return computed(() => {
      const sets = this.programDayStore.dtoSetsForProgram(
        programId
      )() as TSetDto[];

      return sets.map((set) => {
        const item: ChecklistItem = {
          setId: set.id,
          completedSessionsToday: set.completedSessionsToday,
          content: this.getContentToShowForSet(set),
        };

        return item;
      });
    });
  }

  protected abstract getContentToShowForSet(set: TSetDto): string | string[];
}
