import { Component } from '@angular/core';
import { DisplayNoneDirective } from '../directives/display-none.directive';
import { NotWhenAuthenticatedDirective } from '../directives/access/not-when-authenticated.directive';
import {
  IonIcon,
  IonLabel,
  IonTabButton,
  IonTabs,
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { home, tv, calendar, settings, personAdd, logIn, informationCircle} from 'ionicons/icons';
import { FeatureFlagDirective } from '../directives/access/feature-flag.directive';
import { RequrireAuthenticationDirective } from '../directives/access/requrire-authentication.directive';

@Component({
  selector: 'app-mobile-app',
  standalone: true,
  imports: [
    IonTabs,
    FeatureFlagDirective,
    RequrireAuthenticationDirective,
    DisplayNoneDirective,
    NotWhenAuthenticatedDirective,
    IonTabButton,
    IonIcon,
    IonLabel,
  ],
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.scss'],
})
export class MobileAppComponent {
  constructor() {
    addIcons({ home, tv, calendar, settings, personAdd, logIn, informationCircle });
  }
}
