/* eslint-disable @typescript-eslint/ban-types */
import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
} from '@ngrx/signals';
import { Entity } from '../models/entity';
import {
  addEntities,
  addEntity,
  removeEntity,
  updateEntity,
  withEntities,
} from '@ngrx/signals/entities';
import { InjectionToken } from '@angular/core';
import { CrudApi } from '../models/interfaces/api';
import { withStatelessCrud } from './with-crud-without-state.feature';
import { LoadFilter } from '../models/interfaces/load-api';
import { CrudMessages } from '../models/crud-messages';

export function withCrudFeature<TEntity extends Entity, TDto>(
  apiToken: InjectionToken<CrudApi>,
  crudMessages: CrudMessages
) {
  return signalStoreFeature(
    {
      methods: type<{
        mapEntity: (dto: TDto) => TEntity;
      }>(),
    },
    withEntities<TEntity>(),
    withMethods((state) => {
      const addEntityToState = (_input: TEntity, entity: TEntity) => {
        patchState(state, addEntity(entity));
      };

      const addLoadedEntitiesToState = (_filter: LoadFilter, entities: TEntity[]) => {
        patchState(state, addEntities(entities));
      };

      const updateEntityInState = (_input: TEntity, entity: TEntity) => {
        patchState(
          state,
          updateEntity({
            id: entity.id,
            changes: entity,
          })
        );
      };

      const removeEntityFromState = (toRemove: TEntity) => patchState(state, removeEntity(toRemove.id));

      return {addLoadedEntitiesToState, addEntityToState, updateEntityInState, removeEntityFromState };
    }),
    withStatelessCrud(apiToken, crudMessages),
  );
}
