import { Routes } from '@angular/router';
import { GenericProgramSettingsRoutes } from 'src/app/programs/generic-program/generic-program.routes';
import {
  KnowledgeProgramSettingsRoutes,
  PlanKnowledgeProgramRoutes,
} from 'src/app/programs/knowledge-bit-program/knowledge-program.routes';
import {
  AdditionProgramPlanRoutes,
  AddtionProgramSettingsRoutes,
} from 'src/app/programs/math-addition-program/addition-program.routes';
import {
  NumbersProgramAddProgramRoutes,
  NumbersProgramEditProgramRoutes,
  NumbersProgramPlanProgramRoutes,
} from 'src/app/programs/math-numbers-program/numbers-program.routes';
import {
  ReadingCategoryAddProgramRoutes,
  ReadingCategoryEditProgramRoutes,
  ReadingCategoryPlanProgramRoutes,
} from 'src/app/programs/reading-category-program/reading-category-program.routes';
import {
  HomemadeBooksAddProgramRoutes,
  HomemadeBooksEditProgramRoutes,
  HomemadeBooksPlanProgramRoutes,
} from 'src/app/programs/reading-homemade-books-program/homemade-books-program.routes';

export const addProgramRoutes: Routes = [
  ...ReadingCategoryAddProgramRoutes,
  ...HomemadeBooksAddProgramRoutes,

  ...NumbersProgramAddProgramRoutes,
  ...AddtionProgramSettingsRoutes,
  ...KnowledgeProgramSettingsRoutes,
  ...GenericProgramSettingsRoutes,
];

export const editProgramRoutes: Routes = [
  ...ReadingCategoryEditProgramRoutes,
  ...HomemadeBooksEditProgramRoutes,
  ...NumbersProgramEditProgramRoutes,
  ...AddtionProgramSettingsRoutes,
  ...KnowledgeProgramSettingsRoutes,
  ...GenericProgramSettingsRoutes,
];

export const PlanSpecificProgramRoutes: Routes = [
  ...NumbersProgramPlanProgramRoutes,
  ...ReadingCategoryPlanProgramRoutes,
  ...HomemadeBooksPlanProgramRoutes,
  ...PlanKnowledgeProgramRoutes,
  ...AdditionProgramPlanRoutes
];
