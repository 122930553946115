import { Routes } from '@angular/router';

export const StaticPagesRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/info/info.component').then(
        (mod) => mod.InfoComponent
      ),
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./pages/privcy-policy/privcy-policy.component').then(
        (mod) => mod.PrivcyPolicyComponent
      ),
  },
  {
    path: 'cookies',
    loadComponent: () =>
      import('./pages/cookie-policy/cookie-policy.component').then(
        (mod) => mod.CookiePolicyComponent
      ),
  },
  {
    path: 'about',
    loadComponent: () =>
      import('./pages/about/about.component').then((mod) => mod.AboutComponent),
  },
  {
    path: 'resources',
    loadComponent: () =>
      import('./pages/resources/resources.component').then(
        (mod) => mod.ResourcesComponent
      ),
  },
  {
    path: 'contact',
    loadComponent: () =>
      import('./pages/contact-us/contact-us.component').then(
        (mod) => mod.ContactUsComponent
      ),
  }
];
