
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { ConsoleLogger } from 'src/app/infrastructure/services/logging/console-logger';
import { InsightsService } from 'src/app/infrastructure/services/logging/insights.service';
import { MultiLogger } from 'src/app/infrastructure/services/logging/multi-logger';
import { AppConfig } from './app-config';

export const LoggerFactory = (
  appConfig: AppConfig,
  consoleLogger: ConsoleLogger,
  insightLogger: InsightsService
): AppLogger => {
  if (appConfig.logging.console && appConfig.isProduction) {
    return new MultiLogger([consoleLogger, insightLogger]);
  } else if (appConfig.environment === 'production') {
    return insightLogger;
  } else {
    return consoleLogger;
  }
};
