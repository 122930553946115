import { Provider } from '@angular/core';

import { HomemadeBooksProgramSettings } from './homemade-books-program.settings';
import { SpecificProgramSettingsService } from 'src/app/infrastructure/services/specific-program/specific-program-settings.service';
import {
  HomemadeBooksProgramApiService,
  HomemadeBooksProgramApiToken,
} from './services/homemade-books-program-api.service';
import { HomemadeBooksProgramStoreSettings } from './homemade-books-program.store.settings';
import { SpecificProgramStoreProviderService } from 'src/app/infrastructure/services/specific-program/specific-program-store-provider.service';
import {
  HomemadeBookApiService,
  HomemadeBookApiToken,
} from './services/homemade-book-api.service';
import {
  HomemadeBooksStore,
  HomemadeBookStoreToken,
} from './stores/homemade-books.store';
import { PlanHomemadeBooksProgramService } from './services/plan-homemade-books-program.service';
import { PlanSpecificSetProgramToken } from 'src/app/features/plan-set-program/models/interfaces/plan-specific-set-program';
import {
  HomemadeBookProgramStore,
  HomemadeBookProgramStoreToken,
} from './stores/homemade-book-program.store';
import { SpecificWordStatusSetService } from 'src/app/features/word-status/services/specific-word-status-set.service';
import {
  EditHomemadeBookStore,
  EditHomemadeBookStoreToken,
} from './stores/edit-homemade-book.store';
import { ProgramStore } from 'src/app/infrastructure/models/interfaces/program-store';
import { HomemadeBookProgram } from './models/homemade-book-program';
import { EditSetStore } from 'src/app/features/plan-set-program/models/interfaces/edit-set-store';
import { HomemadeBook } from './models/homemade-book';
import { HomemadeBookProgramChecklistService } from './services/homemade-book-program-checklist.service';
import { SpecificProgramChecklistToken } from 'src/app/features/checklist-for-programs/models/interfaces/specific-program-checklist.service';

export const HomemadeBooksProgramProviders: Provider[] = [
  {
    provide: SpecificProgramSettingsService,
    useFactory: () =>
      new SpecificProgramSettingsService([HomemadeBooksProgramSettings]),
    multi: true,
  },
  {
    provide: HomemadeBooksProgramApiToken,
    useExisting: HomemadeBooksProgramApiService,
  },
  {
    provide: SpecificProgramStoreProviderService,
    useFactory: () =>
      new SpecificProgramStoreProviderService([
        HomemadeBooksProgramStoreSettings,
      ]),
    multi: true,
  },
  {
    provide: HomemadeBookApiToken,
    useExisting: HomemadeBookApiService,
  },
  {
    provide: HomemadeBookProgramStoreToken,
    useExisting: HomemadeBookProgramStore,
  },
  {
    provide: HomemadeBookStoreToken,
    useExisting: HomemadeBooksStore,
  },
  {
    provide: EditHomemadeBookStoreToken,
    useExisting: EditHomemadeBookStore,
    deps: [HomemadeBookStoreToken],
  },
  {
    provide: SpecificWordStatusSetService,
    useFactory: (
      store: ProgramStore<HomemadeBookProgram>,
      editStore: EditSetStore<HomemadeBook>
    ) =>
      new SpecificWordStatusSetService(store, editStore, [
        HomemadeBooksProgramSettings,
      ]),
    deps: [HomemadeBookProgramStoreToken, EditHomemadeBookStoreToken],
    multi: true,
  },
  {
    provide: PlanSpecificSetProgramToken,
    useExisting: PlanHomemadeBooksProgramService,
    multi: true,
  },
  {
    provide: SpecificProgramChecklistToken,
    useExisting: HomemadeBookProgramChecklistService,
    multi: true,
  }
];
