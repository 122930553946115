import { inject, Inject, Injectable } from '@angular/core';
import { SpecificProgramSettingsService } from './specific-program/specific-program-settings.service';
import { ProgramProviderService } from './program-provider.service';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ProgramSettingsService {

  private programService: ProgramProviderService<SpecificProgramSettingsService>;
  private router = inject(Router);

  constructor(
    logger: AppLogger,
    @Inject(SpecificProgramSettingsService)
    programServices: SpecificProgramSettingsService[]
  ) {
    this.programService =
      new ProgramProviderService<SpecificProgramSettingsService>(
        logger,
        null,
        programServices
      );
  }

  public getProgramType(typeName: string): number {
    const service = this.programService.getServiceForTypename(typeName);

    return service.getProgramType(typeName);
  }

  public getProgramUrlPathForProgramId(
    programId: string
  ): string[] | undefined {
    const service = this.programService.getServiceForProgramId(programId);

    return service.getProgramUrlPathForProgramId(programId);
  }

  public getProgramUrlNameForProgramType(programType: number): string {
    const service = this.programService.getServiceForType(programType);

    return service.getProgramUrlNameForProgramType(programType);
  }

  public getProgramTypeName(programType: number): string {
    const service = this.programService.getServiceForType(programType);

    return service.getProgramTypeName(programType);
  }

  public getProgramUrlNameForProgramId(programId: string): string {
    const service = this.programService.getServiceForProgramId(programId);

    const programType = service.getTypeFromProgramId(programId);

    return service.getProgramUrlNameForProgramType(programType);
  }

  public programIsActive(programType: number): boolean {
    try {
      const service = this.programService.getServiceForType(programType);
      return service.programIsActive(programType);
    } catch (error) {
      return false;
    }
  }

  public getProgramTypesThatPresentInApp() {
    const services = this.programService.getAllServices();

    const programTypesThatPrseent = services.map((service) => service.getProgramTypesThatPresentInApp()).flat();

    return programTypesThatPrseent;
  }

  public currentProgramTypeIn() {
    const url = this.router.url;

    const service = this.programService.getServiceForUrl(url);

    return service.getTypeFromUrl(url);
  }

  public getProgramNameForType(programType: number): string {
    const service = this.programService.getServiceForType(programType);
    const settings = service.getProgramSettingsForType(programType);

    return settings.programName;
  }
}
