import { CrudMessages } from "src/app/infrastructure/models/crud-messages";

export const ReadingCategoryCrudMessages: CrudMessages = {
  addUserError: {
    heading: 'Failed to add reading program',
    message: 'Some data on the new program was invalid, and the program could not be added. Please try again.',
  },
  addServerFail: {
    heading: 'Failed to add reading program',
    message: 'We did something wrong and could not save your new program. Please try again later.',
  },

  editUserError: {
    heading: 'Failed to edit reading program',
    message: 'Some data on the program was invalid, and the program could not be edited. Please try again.',
  },
  editServerFail: {
    heading: 'Failed to edit reading program',
    message: 'We did something wrong and could not save your changes. Please try again later.',
  },

  removeUserError: {
    heading: 'Failed to delete reading program',
    message: 'We could not remove the program. Please try again.',
  },
  removeServerFail: {
    heading: 'Failed to delete reading program',
    message: 'We did something wrong and could not remove the program. Please try again later.',
  },
}
