import { HttpParams } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SpecificProgramApiService } from 'src/app/infrastructure/services/specific-program/specific-program-api.service';
import { KnowledgeProgram } from '../models/knowledge-program';
import { KnowledgeProgramDto } from '../models/knowledge-program.dto.ts';
import { mapKnowledgeProgramToProgramDto } from './knowledge-program.mapper';
import { SpecificProgramApi } from 'src/app/infrastructure/models/interfaces/specific-program-api';

export const KnowledgeProgramApiToken = new InjectionToken<
  SpecificProgramApi<KnowledgeProgram, KnowledgeProgramDto>
>('KnowledgeProgramApi');

@Injectable({
  providedIn: 'root',
})
export class KnowledgeProgramApiService extends SpecificProgramApiService<
  KnowledgeProgram,
  KnowledgeProgramDto
> {
  protected baseUrl = '/api/v1/programs/knowledge-programs';

  public getTagsInCommon(tags: string[]): Observable<string[]> {
    const tagsToString = tags.join(',');
    const params = new HttpParams().set('tags', tagsToString);

    return this.http.get<string[]>(`${this.baseUrl}/tags`, { params });
  }

  public getAllCategories(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/categories`);
  }

  protected toDto(program: KnowledgeProgram): KnowledgeProgramDto {
    return mapKnowledgeProgramToProgramDto(program);
  }
}
