import { ReadingCategoryDTO } from '../models/reading-category-dto';
import { ReadingProgramDto } from '../../reading-program/models/reading-program-dto';
import { ReadingProgram } from '../../reading-program/models/reading-program';
import { ReadingCategory } from '../models/reading-category';
import { mapProgramSetToDto, mapSetDtoToProgramSet, mapSetsProgramDtoToSetsProgram, mapSetsProgramToSetsProgramDto } from '../../set-programs/services/set-program.mapper';
import { Child } from 'src/app/features/children/models/child';

export const mapReadingProgramDtoToProgram = (dto: ReadingProgramDto, allChildren: Child[]) => {
  const program: ReadingProgram = {
    ...mapSetsProgramDtoToSetsProgram(dto, allChildren),

    languageCode: dto.languageCode,
    nbrOfContentOnPrintPage: dto.nbrOfContentOnPrintPage,
  };

  return program;
}

export const mapReadingCategoryProgramToDto = (program: ReadingProgram): ReadingProgramDto => {
  const dto: ReadingProgramDto = {
    ...mapSetsProgramToSetsProgramDto(program),
    languageCode: program.languageCode,
    nbrOfContentOnPrintPage: program.nbrOfContentOnPrintPage,
  };

  return dto;
}

export const mapReadingCategoryToDto = (set: ReadingCategory): ReadingCategoryDTO => {
  const dto: ReadingCategoryDTO = {
    ...mapProgramSetToDto(set),
    content: set.content?.map(x => x.textOnCard),
  };

  return dto;
}
export const mapReadingCategoryDtoToCategory = (dto: ReadingCategoryDTO): ReadingCategory => {
  const category: ReadingCategory = {
    ...mapSetDtoToProgramSet(dto),
    content: dto.content.map(x => ({textOnCard: x}))
  };

  return category;
}
