import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDto } from '../../models/dtos/user.dto';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  private readonly baseUrl = '/api/v1/users';
  private httpClient = inject(HttpClient);

  public getUserInfo(): Observable<UserDto> {
    return this.httpClient.get<UserDto>(`${this.baseUrl}/me`);
  }

  public updateUserPaymentInfo(): Observable<UserDto> {
    return this.httpClient.post<UserDto>(`${this.baseUrl}/me/update-payment-info`, {});
  }
}
