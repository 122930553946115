import { Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HomemadeBookProgram } from '../models/homemade-book-program';
import { HomemadeBooksProgramDto } from '../models/homemade-books-program-dto';
import { SpecificProgramApiService } from 'src/app/infrastructure/services/specific-program/specific-program-api.service';
import { mapHomemadeBookProgramToProgramDto } from './homemade-books-mapper';
import { SpecificProgramApi } from 'src/app/infrastructure/models/interfaces/specific-program-api';

export const HomemadeBooksProgramApiToken = new InjectionToken<
  SpecificProgramApi<HomemadeBookProgram, HomemadeBooksProgramDto>
>('HomemadeBookProgramApi');

@Injectable({
  providedIn: 'root',
})
export class HomemadeBooksProgramApiService extends SpecificProgramApiService<
  HomemadeBookProgram,
  HomemadeBooksProgramDto
> {
  protected baseUrl = '/api/v1/programs/reading/books';

  public getWordsNotPlanned(programId: string): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.baseUrl}/${programId}/words-not-planned`
    );
  }

  protected toDto(program: HomemadeBookProgram): HomemadeBooksProgramDto {
    return mapHomemadeBookProgramToProgramDto(program);
  }
}
