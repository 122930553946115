import { Routes } from '@angular/router';
import { EditSetStoreToken } from 'src/app/features/plan-set-program/models/interfaces/edit-set-store';
import { EditHomemadeBookStore } from './stores/edit-homemade-book.store';

export const HomemadeBooksAddProgramRoutes: Routes = [
  {
    path: 'homemade-books',
    loadComponent: () =>
      import(
        './containers/homemade-book-program-settings/homemade-book-program-settings.component'
      ).then((x) => x.HomemadeBookProgramSettingsComponent),
    providers: [
    ],
  },
];

export const HomemadeBooksEditProgramRoutes: Routes = [
  {
    path: 'reading/homemade-books',
    loadComponent: () =>
      import(
        './containers/homemade-book-program-settings/homemade-book-program-settings.component'
      ).then((x) => x.HomemadeBookProgramSettingsComponent),
    providers: [
    ],
  },
];

const editBookProviders = [
  {
    provide: EditSetStoreToken,
    useExisting: EditHomemadeBookStore,
  },
];

export const HomemadeBooksPlanProgramRoutes: Routes = [
  {
    path: 'reading/homemade-books/:childrenIdentifier',
    loadComponent: () =>
      import(
        'src/app/features/plan-set-program/containers/cognitive-program-planner-page/cognitive-program-planner.component'
      ).then((x) => x.CognitiveProgramPlannerComponent),
    children: [
      {
        path: 'add-book',
        loadComponent: () =>
          import('./containers/edit-book/edit-book.component').then(
            (x) => x.EditBookComponent
          ),
      },
      {
        path: 'edit-book/:setId',
        loadComponent: () =>
          import('./containers/edit-book/edit-book.component').then(
            (x) => x.EditBookComponent
          ),
      },
    ],
    providers: editBookProviders,
  },
];
