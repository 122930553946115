import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  map,
  filter,
  distinctUntilChanged,
  combineLatest,
  Subject,
} from 'rxjs';
import * as fromRouter from 'src/app/core/selectors/router.selectors';
import { AppPlatformService } from '../services/app-platform.service';

@Directive({
  selector: '[appDisplayNone]',
  standalone: true,
})
export class DisplayNoneDirective implements OnInit, OnDestroy {
  // The name of the route data to check for
  @Input() appDisplayNone: string;

  // onnly works on the platform specified if any given
  @Input() appDisplayNonePlatform?: 'mobile' | 'desktop' | undefined = undefined;

  private destry$ = new Subject<void>();

  constructor(
    private store: Store,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private platform: AppPlatformService
  ) {}

  public ngOnInit(): void {
    const display = this.store.select(fromRouter.selectRouteData).pipe(
      filter((data) => data !== undefined),
      map((data) => {
        return data[this.appDisplayNone] ?? false;
      }),
      distinctUntilChanged()
    );

    combineLatest([display, this.platform.isMobile$, this.platform.isDesktop$])
      .pipe()
      .subscribe({
        next: ([hide, isMobile, isDesktop]) => {
          if (
            hide &&
            (this.displayForMobile(isMobile) ||
              this.displayForDesktop(isDesktop) ||
              this.displayForAll())
          ) {
            this.renderer.setStyle(
              this.elementRef.nativeElement,
              'display',
              'none'
            );
          } else {
            try {
              this.renderer.removeStyle(
                this.elementRef.nativeElement,
                'display'
              );
            } catch (error) {
              // do nothing since its because it doesn't exist but dont' produce an error
            }
          }
        },
      });
  }

  private displayForAll(): boolean {
    return this.appDisplayNonePlatform === undefined;
  }

  private displayForDesktop(isDesktop: boolean): boolean {
    return this.appDisplayNonePlatform === 'desktop' && isDesktop;
  }

  private displayForMobile(isMobile: boolean) {
    return this.appDisplayNonePlatform === 'mobile' && isMobile;
  }

  public ngOnDestroy(): void {
    this.destry$.next();
    this.destry$.complete();
  }
}
