import { signalStoreFeature, type, withMethods } from '@ngrx/signals';
import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Entity, EntityDto } from '../models/entity';
import { DataLoadApiToken } from '../models/interfaces/data-load-api';
import { withDataLoad } from './with-data-load.feature';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withDataLoadAll<_>(
  apiToken: typeof DataLoadApiToken
) {
  return signalStoreFeature(
    {

      methods: type<{
        mapDto(dto: EntityDto): Entity;
      }>(),
    },
    withMethods((_, api = inject(apiToken)) => ({
      loadData(): Observable<EntityDto[]> {
        return api
          .loadData()
          .pipe(map((dtos) => dtos.map((dto) => dto)));
      },
    })),
    withDataLoad()
  );
}
