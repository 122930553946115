import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AppPlatformService } from '../../services/app-platform.service';

@Directive({
  selector: '[appIsMobile]',
  standalone: true,
})
export class IsMobileDirective implements OnInit, OnDestroy {
  @Input() public appIsMobile?: TemplateRef<unknown> | string;

  private destroy$ = new Subject<void>();

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private platform: AppPlatformService
  ) {}

  public ngOnInit(): void {
    this.platform.isMobile$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (isMobile) => {
        isMobile ? this.onIf() : this.onElse();
      },
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private onIf(): void {
    this.createView(this.templateRef);
  }

  private onElse(): void {
    if (!this.appIsMobile || typeof this.appIsMobile === 'string') {
      return;
    }

    this.createView(this.appIsMobile);
  }

  private createView(templateRef: TemplateRef<unknown>): void {
    this.viewContainer.createEmbeddedView(templateRef);
  }
}
