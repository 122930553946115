import { HttpClient, HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { LoadFilter } from 'src/app/infrastructure/models/interfaces/load-api';
import { FrontSetsDTO } from '../models/dtos/front-sets.dto';
import { ProgramSetDTO } from '../models/dtos/program-set-dto';
import { SetStatus } from '../models/enums/set-status';
import {
  ProgramSetApi,
  PlannedSetsResponse,
} from '../models/interfaces/program-set-api';
import { ProgramSet } from '../models/program-set';
import { LoadMorePlannedFilter } from '../models/program-set-load.filters';

export type LoadFrontSetsForProgramFilter = {
  id: string;
  identifier: 'front-sets';
};

export abstract class ProgramSetApiService<
  TSet extends ProgramSet,
  TSetDto extends ProgramSetDTO
> implements ProgramSetApi<TSet, TSetDto>
{
  protected abstract baseUrl: string;
  protected http = inject(HttpClient);

  loadEntities(filter: LoadFilter): Observable<TSetDto[]> {
    if (filter.identifier === 'front-sets') {
      return this.getFrontSets((filter as LoadFrontSetsForProgramFilter).id);
    } else if (filter.identifier === 'loadMorePlanned') {
      const { id, offset } = filter as LoadMorePlannedFilter;
      return this.getSets(id, SetStatus.planned, 10, offset);
    }

    throw new Error('Method not implemented.');
  }

  getFrontSets(programId: string): Observable<TSetDto[]> {
    return this.http
      .get<FrontSetsDTO<TSetDto>>(
        `${this.baseUrl}/front?programId=${programId}`
      )
      .pipe(
        map((front) => {
          const completed = this.mapAList(front.completed, SetStatus.completed);

          const planned = this.mapAList(front.planned, SetStatus.planned);

          const current = this.mapAList(front.current, SetStatus.current);

          return [...completed, ...planned, ...current];
        })
      );
  }

  getSets(
    programId: string,
    status: SetStatus,
    limit = 10,
    offset = 0
  ): Observable<TSetDto[]> {
    return this.http
      .get<TSetDto[]>(
        `${this.baseUrl}?programId=${programId}&status=${status}&limit=${limit}&offset=${offset}`
      )
      .pipe(map((sets) => this.mapAList(sets, status, offset)));
  }
  getSet(programId: string, setId: string): Observable<TSetDto> {
    return this.http.get<TSetDto>(`${this.baseUrl}/${programId}/${setId}`);
  }

  getPlannedSets(
    programId: string,
    limit = 10,
    offset = 0
  ): Observable<PlannedSetsResponse<TSetDto>> {
    return this.http
      .get<PlannedSetsResponse<TSetDto>>(
        `${this.baseUrl}/planned?programId=${programId}&limit=${limit}&offset=${offset}`
      )
      .pipe(
        map((response) => ({
          categories: this.mapAList(
            response.categories,
            SetStatus.planned,
            offset
          ),
          hasMore: response.hasMore,
          totalCount: response.totalCount,
        }))
      );
  }

  moveSet(programId: string, setId: string, toSpot: number): Observable<void> {
    return this.http.patch<void>(
      `${this.baseUrl}/move?programId=${programId}&setId=${setId}&toSpot=${toSpot}`,
      null
    );
  }
  changeStatus(
    programId: string,
    setId: string,
    newStatus: SetStatus
  ): Observable<void> {
    return this.http.patch<void>(
      `${this.baseUrl}/status?programId=${programId}&setId=${setId}&status=${newStatus}`,
      null
    );
  }
  addSets(programId: string, toAdd: TSet[]): Observable<TSetDto[]> {
    return this.http.post<TSetDto[]>(
      `${this.baseUrl}/bulk?programId=${programId}`,
      toAdd.map((set) => this.setToDto(set))
    );
  }

  addEntity(entity: TSet): Observable<TSetDto> {
    if (entity.programId === undefined) {
      throw new Error('No programId provided for add set');
    }

    return this.http
      .post<TSetDto>(
        `${this.baseUrl}?programId=${entity.programId}`,
        this.setToDto(entity)
      )
      .pipe(map((set) => ({ ...set, status: SetStatus.planned })));
  }
  editEntity(entity: TSet): Observable<TSetDto> {
    const editedSet = this.setToDto(entity);

    return this.http
      .put<TSetDto>(
        `${this.baseUrl}?programId=${entity.programId}&setId=${entity.id}`,
        editedSet
      )
      .pipe(
        map((set) => ({
          ...set,
          status: entity.status,
          position: entity.position,
        }))
      );
  }

  removeEntity(entity: TSet): Observable<void> {
    return this.http.delete<void>(
      `${this.baseUrl}?programId=${entity.programId}&setId=${entity.id}`
    );
  }

  public checkSetTitle(
    title: string,
    programId: string,
    setId = ''
  ): Observable<{
    value: string;
    hasChanged: boolean;
  }> {
    let params = new HttpParams();
    params = params.append('programId', programId);
    if (setId !== '') {
      params = params.append('setId', setId);
    }
    params = params.append('title', title);

    return this.http.get<{
      value: string;
      hasChanged: boolean;
    }>(`${this.baseUrl}/check-title`, {
      params,
    });
  }

  protected abstract setToDto(set: TSet): TSetDto;

  private mapAList(
    list: TSetDto[],
    toStatus: SetStatus,
    offset = 0
  ): TSetDto[] {
    return list.map((set, index) => {
      const dto: TSetDto = {
        ...set,
        status: toStatus,
        position: index + offset,
      };

      return dto;
    });
  }
}
