import { ProgramSettings } from 'src/app/infrastructure/models/program-settings';
import { defaultSetProgram } from '../set-programs/set-program.settings';
import { NumbersProgram } from './models/numbers-program';

export class NumbersProgramSettings {
  public static quantityProgram: ProgramSettings = {
    programType: 3,
    programName: 'Quantity',
    typeName: 'quantity',
    urlName: 'quantity',
    idPrefix: 'programs_cognitive_math_quantity_',
    urlPath: ['quantity'],
    isActive: true,
    presentsInApp: false,
  };

  public static numericProgram: ProgramSettings= {
    programType: 11,
    programName: 'Numerical',
    typeName: 'numerical',
    urlName: 'numeric',
    idPrefix: 'programs_cognitive_math_numerical_',
    urlPath: ['numeric'],
    isActive: true,
    presentsInApp: true,
  };

  public static quantityProgramType = 3;
  public static numericProgramType = 11;

  public static quantityProgramIdStart = 'programs_cognitive_math_quantity_';

  public static defaultNumbersProgram: NumbersProgram = {
    ...defaultSetProgram,

    nbrOfSetsADay: 1,
    nbrOfDaysForASet: 1,
    nbrOfSessionsDay: 3,

    goalQuantity: 100,
    nbrOfCardsInASet: 10,
    nbrOfNewCardsADay: 2,
    nbrOfSessionsInOrderPerDay: 1,
    highestNumberCurrentOnProgram: 10,
  };
}
