import { Injectable } from '@angular/core';
import { NumbersProgramSettings } from '../numbers-program.settings';
import { SpecificProgramChecklistServiceBase } from 'src/app/features/checklist-for-programs/services/specific-program-checklist.service.base';
import { NumbersSetDto } from '../models/numbers-set.dto';
import { NumbersSetToPresentation } from './numbers-program.mappers';

@Injectable({
  providedIn: 'root',
})
export class NumbersProgramChecklistService extends SpecificProgramChecklistServiceBase<NumbersSetDto> {
  constructor() {
    super([
      NumbersProgramSettings.numericProgram,
      NumbersProgramSettings.quantityProgram,
    ]);
  }

  protected getContentToShowForSet(set: NumbersSetDto): string {
    return NumbersSetToPresentation(set);
  }
}
