import { ProgramStoreSettings } from "src/app/infrastructure/models/program-store-settings";
import { NumbersProgram } from "./models/numbers-program";
import { NumbersProgramSettings } from "./numbers-program.settings";
import { NumericProgramStore } from "./stores/numeric-program.store";
import { QuantityProgramStore } from "./stores/quantity-program.store";

export const NumericProgramStoreSettings: ProgramStoreSettings<NumbersProgram> = {
  storeType: NumericProgramStore,
  programSettings: NumbersProgramSettings.numericProgram
}

export const QuantityProgramStoreSettings: ProgramStoreSettings<NumbersProgram> = {
  storeType: QuantityProgramStore,
  programSettings: NumbersProgramSettings.quantityProgram
};
