import { ProgramAreas } from '../all-programs/models/enums/program-areas';
import { defaultProgram } from '../all-programs/program.settings';
import { SetProgram } from './models/set-program';

export const defaultSetProgram: SetProgram = {
  ...defaultProgram,
  programArea: ProgramAreas.cognitive,

  nbrOfSetsADay: 5,
  nbrOfDaysForASet: 5,
}
