import { DestroyRef } from '@angular/core';
import { InsightsService } from 'src/app/infrastructure/services/logging/insights.service';
import { AuthenticationService } from 'src/app/infrastructure/services/auth/authentication.service';
import { UserStoreImp } from 'src/app/infrastructure/stores/user.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, Observable, tap } from 'rxjs';

export class ApplicationInsightsConfig {
  private userLoggedIn$ = this.userIdObservable.pipe(
    takeUntilDestroyed(this.destroyRef),
    filter(userId => userId !== undefined && userId !== null),
    tap((userId: string) => {
      this.appInsights.setAuthenticatedUserId(userId);
      this.appInsights.event('User logged in');
    })
  ).subscribe();

  private userLoggedOut$ = this.authService.userLoggedOut$.pipe(
    takeUntilDestroyed(this.destroyRef),
    tap(() => {
      this.appInsights.clearAuthenticatedUserId();
      this.appInsights.event('User logged out');
    })
  ).subscribe();

  constructor(
    private appInsights: InsightsService,
    private authService: AuthenticationService,
    private userStore: UserStoreImp,
    private destroyRef: DestroyRef,
    private userIdObservable: Observable<string | null | undefined>
  ) {}
}
