import { Injectable } from '@angular/core';
import { AlertController, AlertOptions } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private alertController: AlertController) {}

  public async showOkAlert(header: string, message: string): Promise<void> {
    await this.showAlert(header, message, this.addOkButton);
  }

  public async showYesNoAlert(
    header: string,
    message: string
  ): Promise<boolean> {
    return this.showAlert(header, message, this.addYesNoButtons);
  }

  public async showAlertWithBtns(
    header: string,
    message: string,
    buttons: AlertOptions['buttons']
  ): Promise<void> {
    const modalSettings: AlertOptions = {
      header,
      message,
      buttons,
    };

    const alert = await this.alertController.create(modalSettings);

    await alert.present();

    await alert.onDidDismiss();
  }

  private async showAlert(
    header: string,
    message: string,
    setupAlertOptions: (settings: AlertOptions) => AlertOptions
  ): Promise<boolean> {
    const modalSettings: AlertOptions = {
      header,
      message,
      buttons: [],
    };

    setupAlertOptions(modalSettings);

    const alert = await this.alertController.create(modalSettings);

    await alert.present();

    const result = await alert.onDidDismiss();

    return result.role === 'confirm';
  }

  private addOkButton(settings: AlertOptions): AlertOptions {
    settings.buttons.push({
      text: 'Ok',
      role: 'cancel',
    });

    return settings;
  }

  private addYesNoButtons(settings: AlertOptions): AlertOptions {
    settings.buttons.push({
      text: 'No',
      role: 'cancel',
    });
    settings.buttons.push({
      text: 'Yes',
      role: 'confirm',
    });

    return settings;
  }
}
