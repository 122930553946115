import { Injectable, InjectionToken } from '@angular/core';
import { ReadingCategory } from '../models/reading-category';
import { ReadingCategoryDTO } from '../models/reading-category-dto';
import { mapReadingCategoryToDto } from './reading-category-program.mapper.ts';
import { ProgramSetApi } from '../../set-programs/models/interfaces/program-set-api';
import { ProgramSetApiService } from '../../set-programs/services/program-set-api.service';

export const ReadingCategoryApiServiceToken = new InjectionToken<ProgramSetApi<ReadingCategory, ReadingCategoryDTO>>('ReadingCategoryApiService');

@Injectable({
  providedIn: 'root'
})
export class ReadingCategoryApiService extends ProgramSetApiService<ReadingCategory, ReadingCategoryDTO> {

  protected readonly baseUrl = '/api/v1/programs/reading/categories/sets';

  protected setToDto(set: ReadingCategory): ReadingCategoryDTO {
    return mapReadingCategoryToDto(set);
  }
}
