import { Routes } from '@angular/router';

export const checklistUrl = '/checklist';

export const ChecklistRoutes: Routes = [
  {
    path: 'select-account-type',
    loadComponent: () =>
      import(
        './containers/checklist-for-programs-page/checklist-for-programs.component'
      ).then((mod) => mod.ChecklistForProgramsComponent),
      data: { title: 'Checklist' },
  },
];
