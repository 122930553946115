import { Injectable } from '@angular/core';
import { inject} from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';

@Injectable()
export class ErrorIntercept implements HttpInterceptor {
  private logger = inject(AppLogger);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
    .pipe(
        catchError((error: HttpErrorResponse) => {
            if (error.error instanceof ErrorEvent) {
                // client-side error
                this.logger.warning(error.error.message);
            } else {
                // server-side error
                const errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
                this.logger.error(errorMessage, error);
            }
            return throwError(() => error);
        })
    );
  }
}
