<ion-tabs>
  <ion-tab-bar slot="bottom"  appDisplayNone="hideTabbar">
    <ion-tab-button tab="home" *appNotWhenAuthenticated>
      <ion-icon name="home"></ion-icon>
      <ion-label>Home</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="checklist" *appRequrireAuthentication>
      <ion-icon name="home"></ion-icon>
      <ion-label>Checklist</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="present" *appRequrireAuthentication data-test="present-btn">
      <ion-icon name="tv"></ion-icon>
      <ion-label>Present</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="plan" *appRequrireAuthentication>
      <ion-icon name="calendar"></ion-icon>
      <ion-label>Plan</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="settings" data-test="settings" *appRequrireAuthentication>
      <ion-icon name="settings"></ion-icon>
      <ion-label>Settings</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="user/register" data-test="register" *appNotWhenAuthenticated>
      <ion-icon name="person-add"></ion-icon>
      <ion-label>Register</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="user/login" data-test="login" *appNotWhenAuthenticated>
      <ion-icon name="log-in"></ion-icon>
      <ion-label>Login</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="info">
      <ion-icon name="information-circle"></ion-icon>
      <ion-label>Info</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
