import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
  withState,
} from '@ngrx/signals';
import { Entity, EntityDto } from '../models/entity';
import { computed, inject, InjectionToken } from '@angular/core';
import { CrudApi } from '../models/interfaces/api';
import { ProcessLoad, ProcessState } from '../models/entity-process-state';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { makeApiCall } from './api-store-function';
import { LoadFilter } from '../models/interfaces/load-api';
import { HttpErrorResponse } from '@angular/common/http';
import { tapResponse } from '@ngrx/operators';

import { pipe, filter, tap, exhaustMap } from 'rxjs';
import { AlertService } from '../services/alert.service';
import { CrudMessages } from '../models/crud-messages';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type DataCrudState<_> = {
  isProcessingAdd: ProcessState<Entity>[];
  isProcessingEdit: ProcessState<Entity>[];
  isProcessingRemove: ProcessState<Entity>[];
  isProcessingLoad: ProcessLoad<LoadFilter>[];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withStatelessCrud<_>(
  apiToken: InjectionToken<CrudApi>,
  crudMessages: CrudMessages
) {
  return signalStoreFeature(
    {
      methods: type<{
        mapEntity: (dto: EntityDto) => Entity;
        addEntityToState: (input: Entity, entity: Entity) => void;
        addLoadedEntitiesToState: (
          filter: LoadFilter,
          entities: Entity[]
        ) => void;
        updateEntityInState: (input: Entity, entity: Entity) => void;
        removeEntityFromState: (entity: Entity) => void;
      }>(),
    },
    withState(() => {
      const initialState: DataCrudState<Entity> = {
        isProcessingAdd: [],
        isProcessingEdit: [],
        isProcessingRemove: [],
        isProcessingLoad: [],
      };
      return initialState;
    }),
    withMethods((state) => {
      const logger = inject(AppLogger).forContext('WithStatelessCrudFeature');
      const api = inject(apiToken);
      const alertService = inject(AlertService);

      const haveLoadingError = (id: string) =>
        computed(() =>
          state
            .isProcessingLoad()
            .some((x) => x.filter.id === id && x.error !== undefined)
        );

      const isLoading = (id: string) =>
        computed(() =>
          state
            .isProcessingLoad()
            .some((x) => x.filter.id === id && x.status === 'processing')
        );

      const haveNoStatus = (id: string) =>
        computed(
          () => !state.isProcessingLoad().some((x) => x.filter.id === id)
        );

      const haveLoaded = (id: string) =>
        computed(() => {
          const processes = state.isProcessingLoad();
          const haveLoaded = processes.some(
            (x) => x.filter.id === id && x.status === 'loaded'
          );

          return haveLoaded;
        });

      const addEntityToApi = (entity: Entity) => {
        logger.debug('Make api call to add entity');
        return api.addEntity(entity).pipe(
          tapResponse(
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {},
            (error: HttpErrorResponse) => {
              if (error.status < 500) {
                alertService.showOkAlert(
                  crudMessages.addUserError.heading,
                  crudMessages.addUserError.message
                );
              } else {
                alertService.showOkAlert(
                  crudMessages.addServerFail.heading,
                  crudMessages.addServerFail.message
                );
              }
            }
          )
        );
      };

      const addEntity = rxMethod<Entity>(
        makeApiCall(
          state,
          logger,
          state.isProcessingAdd(),
          (updatedList) => ({
            isProcessingAdd: updatedList,
          }),
          addEntityToApi,
          state.mapEntity,
          state.addEntityToState
        )
      );

      const addEntities = rxMethod<Entity[]>(
        pipe(
          tap(() => {
            console.error('Add entities not implemented yet');
          })
        )
      );

      const ediEntityInApi = (entity: Entity) => {
        logger.debug('Make api call to edit entity');
        return api.editEntity(entity).pipe(
          tapResponse(
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {},
            (error: HttpErrorResponse) => {
              if (error.status < 500) {
                alertService.showOkAlert(
                  crudMessages.editUserError.heading,
                  crudMessages.editUserError.message
                );
              } else {
                alertService.showOkAlert(
                  crudMessages.editServerFail.heading,
                  crudMessages.editServerFail.message
                );
              }
            }
          )
        );
      };

      const editEntity = rxMethod<Entity>(
        makeApiCall(
          state,
          logger,
          state.isProcessingEdit(),
          (updatedList) => ({
            isProcessingEdit: updatedList,
          }),
          ediEntityInApi,
          state.mapEntity,
          state.updateEntityInState
        )
      );

      const removeEntityFromApi = (entity: Entity) => {
        logger.debug('Make api call to remove entity');
        return api.removeEntity(entity).pipe(
          tapResponse(
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {},
            (error: HttpErrorResponse) => {
              if (error.status < 500) {
                alertService.showOkAlert(
                  crudMessages.removeUserError.heading,
                  crudMessages.removeUserError.message
                );
              } else {
                alertService.showOkAlert(
                  crudMessages.removeServerFail.heading,
                  crudMessages.removeServerFail.message
                );
              }
            }
          )
        );
      };

      const removeEntity = rxMethod<Entity>(
        makeApiCall(
          state,
          logger,
          state.isProcessingRemove(),
          (updatedList) => ({
            isProcessingRemove: updatedList,
          }),
          removeEntityFromApi,
          () => null,
          state.removeEntityFromState
        )
      );

      const loadEntities = rxMethod<LoadFilter>(
        pipe(
          filter(
            (filter) =>
              !state
                .isProcessingLoad()
                .some(
                  (x) =>
                    x.filter.identifier === filter.identifier &&
                    x.filter.id === filter.id
                )
          ),
          tap((filter) => {
            const newProcess: ProcessLoad<LoadFilter> = {
              filter,
              status: 'processing',
              error: undefined,
            };

            patchState(state, (store) => ({
              ...store,
              isProcessingLoad: [...store.isProcessingLoad, newProcess],
            }));
          }),
          exhaustMap((filter) =>
            api.loadEntities(filter).pipe(
              tapResponse(
                (dtos: EntityDto[]) => {
                  const process = state
                    .isProcessingLoad()
                    .find(
                      (x) =>
                        x.filter.identifier === filter.identifier &&
                        x.filter.id === filter.id
                    );
                  process.status = 'loaded';

                  const processWithoutActiveOne = state
                    .isProcessingLoad()
                    .filter(
                      (x) =>
                        !(
                          x.filter.identifier === filter.identifier &&
                          x.filter.id === filter.id
                        )
                    );

                  patchState(state, (store) => ({
                    ...store,
                    isProcessingLoad: [...processWithoutActiveOne, process],
                  }));

                  const entities = dtos.map((dto) =>
                    state.mapEntity(dto)
                  );

                  state.addLoadedEntitiesToState(filter, entities);
                },

                (error: HttpErrorResponse) => {
                  const process = state
                    .isProcessingLoad()
                    .find((x) => x.filter.identifier === filter.identifier);
                  process.error = error.message;

                  const newIsProcessing = state
                    .isProcessingLoad()
                    .filter((x) => x.filter.identifier !== filter.identifier);

                  patchState(state, (store) => ({
                    ...store,
                    isProcessingLoad: [...newIsProcessing, process],
                  }));

                  logger.error('Failed to load data', error);
                }
              )
            )
          )
        )
      );

      return {
        addEntity,
        addEntities,
        editEntity,
        removeEntity,
        loadEntities,
        haveLoadingError,
        isLoading,
        haveNoStatus,
        haveLoaded,
      };
    })
  );
}
