import { SpecificProgramChecklistServiceBase } from 'src/app/features/checklist-for-programs/services/specific-program-checklist.service.base';
import { EquationSetDto } from '../models/equation-set.dto';
import { ProgramSettings } from 'src/app/infrastructure/models/program-settings';
import { Inject, Injectable } from '@angular/core';
import { EquationProgramSettingsToken } from '../equation-program-settings';

@Injectable({
  providedIn: 'root'
})
export class EquationProgramChecklistService extends SpecificProgramChecklistServiceBase<EquationSetDto>  {

  constructor(@Inject(EquationProgramSettingsToken) settings: ProgramSettings[]){
    super(settings)
  }

  protected getContentToShowForSet(set: EquationSetDto): string | string[] {
    return set.equations;
  }
}
