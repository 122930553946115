import {
  signalStore,
  withComputed,
  withHooks,
  withMethods,
} from '@ngrx/signals';
import { withEntities } from '@ngrx/signals/entities';
import { ProgramInfoDTO } from './models/dtos/program-info-dto';
import { ProgramInfoApiToken } from './services/api/program-info-api.service';
import { computed, inject } from '@angular/core';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { ProgramInfoService } from './services/program-info.service';
import { withDataLoadAll } from 'src/app/infrastructure/store-features/with-data-load-all.feature';
import { ProgramInfo } from './models/program-info';

export const ProgramInfoStore = signalStore(
  {
    providedIn: 'root', protectedState: true,
  },
  withEntities<ProgramInfo>(),
  withMethods(() => ({
    mapDto: (dto: ProgramInfoDTO): ProgramInfo => {
      const info: ProgramInfo = {
        id: dto.name,
        name: dto.name,
        description: dto.description,
        programType: dto.programType,
        category: dto.category,
      };

      return info;
    },
  })),
  withDataLoadAll(ProgramInfoApiToken),
  withComputed((state) => {
    const programInfoService = inject(ProgramInfoService);

    const allProgramInfoInCategories = computed(() => {
      return programInfoService.allProgramInfoInCategories(state.entities());
    });

    const programInfoOnActivePrograms = computed(() => {
      return programInfoService.programInfoForActivePrograms(
        allProgramInfoInCategories()
      );
    });

    return { allProgramInfoInCategories, programInfoOnActivePrograms };
  }),
  withHooks((store, logger = inject(AppLogger)) => ({
    onInit: () => {
      logger.debug('Program info store initialized');
      store.loadData(false);
    },
  }))
);
