import { ProgramService } from '../../models/interfaces/program-service';
import { ProgramSettings } from '../../models/program-settings';

export abstract class SpecificProgramServiceService implements ProgramService {

  constructor(private settings: ProgramSettings[]) { }

  isUrlForService(url: string): boolean {
    return this.settings.some(programSettings => url.includes(programSettings.urlPath.join('/')));
  }

  isProgramIdForService(programId: string): boolean {
    return this.settings.some(programSettings => programId.startsWith(programSettings.idPrefix));
  }

  isProgramTypeNameForService(typeName: string): boolean {
    return this.settings.some(programSettings => programSettings.typeName === typeName);
  }

  isProgramTypeForService(programType: number): boolean {
    return this.settings.some(programSettings => programSettings.programType == programType);
  }

  getProgramSettingsForProgramId(programId: string) {
    return this.settings.find(programSettings => programId.startsWith(programSettings.idPrefix));
  }
}
