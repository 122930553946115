import { inject, Injectable } from '@angular/core';
import { ActionSheetController } from '@ionic/angular/standalone';
import { ProgramSet } from 'src/app/programs/set-programs/models/program-set';

@Injectable({
  providedIn: 'root',
})
export class RemoveSetService {
  private actionSheetService = inject(ActionSheetController);

  private removeSheetBtns = [
    {
      text: 'Delete',
      role: 'destructive',
      data: {
        action: 'delete',
      },
    },
    {
      text: 'Cancel',
      role: 'cancel',
      data: {
        action: 'cancel',
      },
    },
  ];

  public async confirmRemoveSet(set: ProgramSet): Promise<boolean> {
    const actionSheet = await this.actionSheetService.create({
      header: `Remove the set '${set.title}'?`,
      buttons: this.removeSheetBtns,
    });

    await actionSheet.present();

    const data = await actionSheet.onDidDismiss();
    if (data.data.action === 'delete') {
      return true;
    }

    return false;
  }
}
