import { Routes } from '@angular/router';

export const programUrlName = 'addition';

export const AddtionProgramSettingsRoutes: Routes = [
  {
    path: programUrlName,
    loadComponent: () =>
      import(
        './../math-equations-program/containers/equations-program-settings/equations-program-settings.component'
      ).then((m) => m.EquationsProgramSettingsComponent),
  },
];

export const AdditionProgramPlanRoutes: Routes = [
  {
    path: `${programUrlName}/:childrenIdentifier`,
    loadComponent: () =>
      import(
        'src/app/features/plan-set-program/containers/cognitive-program-planner-page/cognitive-program-planner.component'
      ).then((m) => m.CognitiveProgramPlannerComponent),
    providers: [
    ],
  },
];
