import { ReadingProgramType } from './models/reading-program-type';
import { ReadingProgram } from './models/reading-program';
import { ProgramAreas } from 'src/app/programs/all-programs/models/enums/program-areas';

export class ReadingProgramSettings {
  public static readingProgramIdStart = 'programs_cognitive_reading';


  public static defaultReadingProgram: ReadingProgram = {
    languageCode: 'en',
    nbrOfContentOnPrintPage: 1,
    nbrOfSetsADay: 5,
    nbrOfSessionsDay: 3,
    nbrOfDaysForASet: 5,

    id: '',
    programType: ReadingProgramType.singleWords,
    children: [],
    childrenUrlIdentifier: '',
    programArea: ProgramAreas.cognitive,
    isActive: true,
  };


}
