import { Provider } from '@angular/core';

import { AdditionProgramSettings } from './addition-program.settings';
import { SpecificProgramSettingsService } from 'src/app/infrastructure/services/specific-program/specific-program-settings.service';
import { EquationProgramSettingsToken } from '../math-equations-program/equation-program-settings';
import { SpecificProgramStoreProviderService } from 'src/app/infrastructure/services/specific-program/specific-program-store-provider.service';
import {
  AdditionProgramApiToken,
  AdditionProgramApiService,
} from './services/addition-program-api.service';
import {
  AdditionProgramStore,
  AdditionProgramStoreToken,
} from './stores/addition-program.store';
import { ProgramStoreSettings } from 'src/app/infrastructure/models/program-store-settings';
import { AdditionProgram } from './models/addition-program-types';
import { AdditionSetApiToken, AdditionSetApiService } from './services/addition-set-api.service';
import { AdditionEquationSetStore, AdditionSetStoreToken } from './stores/addition-equation-set.store';
import { ProgramSetStore } from 'src/app/infrastructure/models/interfaces/program-set-store';

import { EquationProgram } from '../math-equations-program/models/equation-program';
import { EquationSet } from '../math-equations-program/models/equation-set';
import { PlanEquationProgramService } from '../math-equations-program/services/plan-equation-program.service';
import { ProgramStore } from 'src/app/infrastructure/models/interfaces/program-store';
import { PlanSpecificSetProgramToken } from 'src/app/features/plan-set-program/models/interfaces/plan-specific-set-program';

export const AddtionProgramProviders: Provider[] = [
  {
    provide: SpecificProgramSettingsService,
    useFactory: () =>
      new SpecificProgramSettingsService([AdditionProgramSettings]),
    multi: true,
  },
  {
    provide: EquationProgramSettingsToken,
    useValue: AdditionProgramSettings,
    multi: true,
  },
  {
    provide: AdditionProgramApiToken,
    useExisting: AdditionProgramApiService,
  },
  {
    provide: SpecificProgramStoreProviderService,
    useFactory: () => {
      const settings: ProgramStoreSettings<AdditionProgram> = {
        storeType: AdditionProgramStore,
        programSettings: AdditionProgramSettings,
      };

      return new SpecificProgramStoreProviderService([settings]);
    },
    multi: true,
  },
  {
    provide: AdditionProgramStoreToken,
    useExisting: AdditionProgramStore,
  },
  {
    provide: AdditionSetApiToken,
    useExisting: AdditionSetApiService,
  },
  {
    provide: AdditionSetStoreToken,
    useExisting: AdditionEquationSetStore,
  },
  {
    provide: PlanSpecificSetProgramToken,
    useFactory: (
      setStore: ProgramSetStore<EquationSet>,
      programStore: ProgramStore<EquationProgram>
    ) => {
      return new PlanEquationProgramService(setStore, programStore, [
        AdditionProgramSettings,
      ]);
    },
    deps: [AdditionEquationSetStore, AdditionProgramStore],
    multi: true,
  },
];
