import { Inject, Injectable } from '@angular/core';
import { KnowledgeSet } from '../models/knowledge-set';
import { KnowledgeProgram } from '../models/knowledge-program';
import { PlanSpecificSetProgramService } from '../../set-programs/services/plan-specific-set-program.service';
import { DisplaySetInList } from 'src/app/features/plan-set-program/models/display-set-in-list';
import { ProgramSetStore } from 'src/app/infrastructure/models/interfaces/program-set-store';

import { KnowledgeProgramSettings } from '../knowledge-program-settings';
import { ProgramStore } from 'src/app/infrastructure/models/interfaces/program-store';
import { KnowledgeProgramStoreToken } from '../stores/knowledge-bit-program.store';
import { KnowledgeSetStoreToken } from '../stores/knowledge-set.store';

@Injectable({
  providedIn: 'root',
})
export class PlanKnowledgeProgramService extends PlanSpecificSetProgramService<
  KnowledgeSet,
  KnowledgeProgram
> {
  public readonly setNames = 'Sets';
  public readonly addSetUrl = 'add-set';
  public readonly editSetUrl = 'edit-set';
  public readonly canCopy = false;

  constructor(
    @Inject(KnowledgeSetStoreToken)
    store: ProgramSetStore<KnowledgeSet>,
    @Inject(KnowledgeProgramStoreToken)
    programStore: ProgramStore<KnowledgeProgram>
  ) {
    super(store, programStore, [KnowledgeProgramSettings]);
  }

  public getSetAsDisplay(set: KnowledgeSet): DisplaySetInList {
    const display: DisplaySetInList = {
      setId: set.id,
      title: set.title,
      content: [],
    };

    return display;
  }

  public getNbrOfContentOnPrintedPage(): number {
    return 1;
  }

  public getContentToPrint(setId: string): string[] {
    const set = this.getSetById(setId);
    return set.content.map((x) => x.textOnCard);
  }
}
