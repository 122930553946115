import { HomemadeBookProgram } from '../models/homemade-book-program';
import { HomemadeBooksProgramDto } from '../models/homemade-books-program-dto';
import { Child } from 'src/app/features/children/models/child';
import { mapSetDtoToProgramSet, mapSetsProgramDtoToSetsProgram, mapSetsProgramToSetsProgramDto } from '../../set-programs/services/set-program.mapper';
import { HomemadeBookDto } from '../models/homemade-book-dto';
import { HomemadeBook } from '../models/homemade-book';

export const mapHomemadeBookProgramDtoToProgram = (
  dto: HomemadeBooksProgramDto,
  allChildren: Child[]
): HomemadeBookProgram => {
  const program: HomemadeBookProgram = {
    ...mapSetsProgramDtoToSetsProgram(dto, allChildren),

    reviewBooks: dto.reviewBooks,
    numberOfBooksToReview: dto.nbrOfBooksToReview,
    languageCode: dto.languageCode,
    nbrOfContentOnPrintPage: dto.nbrOfContentOnPrintPage,
  };

  return program;
};

export const mapHomemadeBookProgramToProgramDto = (program: HomemadeBookProgram): HomemadeBooksProgramDto => {
  const dto: HomemadeBooksProgramDto = {
    ...mapSetsProgramToSetsProgramDto(program),

    reviewBooks: program.reviewBooks,
    nbrOfBooksToReview: program.numberOfBooksToReview,
    languageCode: program.languageCode,
    nbrOfContentOnPrintPage: program.nbrOfContentOnPrintPage
  };

  return dto;
}

export const mapHomemadeBookDtoToHomemadeBook = (dto: HomemadeBookDto): HomemadeBook => {
  const homemadeBook: HomemadeBook = {
    ...mapSetDtoToProgramSet(dto),
    content: dto.content.map(x => ({textOnCard: x}))
  };

  return homemadeBook;
}

export const mapHomemadeBookToDto = (book: HomemadeBook): HomemadeBookDto => {
  const dto: HomemadeBookDto = {
    ...mapSetDtoToProgramSet(book),
    content: book.content.map(x => x.textOnCard)
  };

  return dto;
}
