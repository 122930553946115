import { InjectionToken, Signal } from '@angular/core';
import { ChecklistItem } from '../checklist-item';
import { ProgramService } from 'src/app/infrastructure/models/interfaces/program-service';

export interface SpecificProgramChecklistService extends ProgramService {
  getProgramNameForProgram(programId: string): Signal<string | undefined>;
  getSetsForProgram(programId: string): Signal<ChecklistItem[]>;
}

export const SpecificProgramChecklistToken = new InjectionToken<
  SpecificProgramChecklistService[]
>('SpecificProgramChecklistService');
