import { Routes } from '@angular/router';
import { PageHeaderSettings } from 'src/app/layout/elements/page-header/page-header-settings';
import { addProgramRoutes } from 'src/app/core/configuration/app-route-config';

export const onboardingUrl = '/settings/onboarding';
export const onboardingProgramsUrl = `${onboardingUrl}/add-program`;
export const OnboardingRoutes: Routes = [
  {
    path: 'onboarding/add-program',
    loadComponent: () =>
      import('./containers/onboard-user/onboard-user.component').then(
        (mod) => mod.OnboardUserComponent
      ),
    children: [...addProgramRoutes],
    providers: [],
  },
  {
    path: 'onboarding',
    loadComponent: () =>
      import('./containers/onboard-user/onboard-user.component').then(
        (mod) => mod.OnboardUserComponent
      ),
    data: {
      title: 'Getting started',
      showSkipButton: true,
    } as PageHeaderSettings,
    providers: [],
  },
];
