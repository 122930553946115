import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { WordStatusDTO } from '../models/word-status-dto';
import {
  LoadApi,
  LoadFilter,
} from 'src/app/infrastructure/models/interfaces/load-api';

@Injectable({
  providedIn: 'root',
})
export class WordStatusApiService implements LoadApi<WordStatusDTO> {
  private httpClient = inject(HttpClient);
  private readonly baseUrl = '/api/v1/programs/reading';

  public getTextStatus(
    programId: string,
    text: string[],
    setId: string | undefined
  ): Observable<WordStatusDTO[]> {
    let params = new HttpParams();
    params = params.append('programId', programId);
    if (setId !== '' && setId !== undefined) {
      params = params.append('setId', setId);
    }
    text.forEach((textLine) => {
      params = params.append('text', textLine);
    });

    return this.httpClient.get<WordStatusDTO[]>(
      `${this.baseUrl}/process-text`,
      {
        params,
      }
    );
  }

  loadEntities(filter: LoadTextStatus): Observable<WordStatusDTO[]> {
    return this.getTextStatus(filter.id, filter.text, filter.setId);
  }
}

export const WordStatusApiLoadToken = new InjectionToken<
  LoadApi<WordStatusDTO>
>('WordStatusApiLoadToken');

export type LoadTextStatus = LoadFilter & {
  text: string[];
  setId: string | undefined;
};
