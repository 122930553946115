import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import {
  EditSetInfoState,
  withEditSetFeature,
} from 'src/app/features/plan-set-program/with-edit-set.feature';
import { ReadingCategory } from '../models/reading-category';
import { computed, inject, InjectionToken } from '@angular/core';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { ReadingCard } from '../../reading-program/models/reading-card';
import {
  ReadingCategoryStore,
  ReadingCategoryStoreToken,
} from './reading-categories.store';
import { ReadingCategoryProgramStore } from './reading-category-program.store';
import { EditSetStore } from 'src/app/features/plan-set-program/models/interfaces/edit-set-store';
import { ReadingCategoryProgramSettings } from '../reading-category-program.settings';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { debounceTime, filter, pipe, switchMap } from 'rxjs';
import {
  CheckTitleResponse,
  ReadingCategoryProgramAPIService,
} from '../services/reading-category-program-api.service';
import { tapResponse } from '@ngrx/operators';

export const EditReadingCategoryStoreToken = new InjectionToken<
  EditSetStore<ReadingCategory>
>('EditReadingCategoryStore');

export type EditReadingCategoryState = EditSetInfoState<ReadingCategory> & {
  lastCheckedTitle: string | undefined;
  titleCheckResult: CheckTitleResponse | undefined;
};

const setInfoState: EditReadingCategoryState = {
  contentName: 'Card',
  setName: 'Category',
  titleName: 'Title',
  defaultSet: {
    content: [
      {
        textOnCard: '',
      },
    ],
  } as ReadingCategory,
  urlNameForProgramEdit: ReadingCategoryProgramSettings.allPrograms.map((x) =>
    x.urlPath.join('/')
  ),
  lastCheckedTitle: undefined,
  titleCheckResult: undefined,
};

export const EditReadingCategoryStore = signalStore(
  {
    providedIn: 'root', protectedState: false,
  },
  withState(setInfoState),
  withEditSetFeature<ReadingCategory>(
    ReadingCategoryStoreToken
  ),
  withMethods(
    (
      state,
      categoryStore = inject(ReadingCategoryStore),
      programStore = inject(ReadingCategoryProgramStore)
    ) => {
      const api = inject(ReadingCategoryProgramAPIService);
      const logger = inject(AppLogger).forContext('EditReadingCategoryStore');

      const contentToLowercase = () => {
        const content = state.set()?.content.map((content) => {
          return {
            ...content,
            textOnCard: content.textOnCard.toLowerCase(),
          };
        });

        patchState(state, (store) => ({
          ...store,
          set: {
            ...store.set,
            content: [...content],
          },
        }));
      };

      const addEmptyContent = () => {
        const content = state.set()?.content || [];
        content.push({
          textOnCard: '',
        });

        patchState(state, (store) => ({
          ...store,
          set: {
            ...store.set,
            content,
          },
        }));
      };

      const setContent = (word: string, index: number) => {
        const content = state.set()?.content || [];

        const card: ReadingCard = {
          textOnCard: word,
        };

        if (content.length < index) {
          content.push(card);
        } else {
          content[index] = card;
        }

        patchState(state, (store) => ({
          ...store,
          set: {
            ...store.set,
            content: [...content],
          },
        }));
      };

      const saveSet = () => {
        const category = {
          ...state.set(),
          programId: programStore.currentProgram().id,
        };
        category.content = category.content.filter(
          (c) => c.textOnCard != undefined && c.textOnCard.length > 0
        );

        if (state.isEditMode()) {
          categoryStore.editEntity(category);
        } else {
          categoryStore.addEntity(category);
        }
      };

      const removeContent = (index: number) => {
        const content = state.set()?.content || [];
        content.splice(index, 1);

        patchState(state, (store) => ({
          ...store,
          set: {
            ...store.set,
            content: [...content],
          },
        }));
      };

      const uniqueTitle = rxMethod<string>(
        pipe(
          filter((title) => {
            return (
              title.length >= 2 &&
              title !== state.lastCheckedTitle() &&
              title !== state.titleCheckResult()?.value
            );
          }),
          debounceTime(300),
          switchMap((title) => {
            return api
              .checkTitle(
                title,
                programStore.currentProgram().id,
                state.set()?.id
              )
              .pipe(
                tapResponse(
                  (response: CheckTitleResponse) => {
                    if (response.hasChanged) {
                      state.patchSet({
                        title: response.value,
                      });
                    }
                    patchState(state, (store) => ({
                      ...store,
                      lastCheckedTitle: title,
                      titleCheckResult: response,
                    }));
                  },
                  (error) => {
                    logger.warning(
                      `Failed to check title for program ${
                        programStore.currentProgram().id
                      }`,
                      error
                    );
                  }
                )
              );
          })
        )
      );

      return {
        contentToLowercase,
        addEmptyContent,
        setContent,
        removeContent,
        saveSet,
        uniqueTitle,
      };
    }
  ),
  withComputed((state) => ({
    canSave: computed(() => {
      const set = state.set();

      if (set === undefined) {
        return false;
      }

      const titleIsValid = set.title !== undefined && set.title.length > 1;

      const haveCards =
        set.content?.filter((c) => c.textOnCard.length > 0).length > 0 || false;

      if (!haveCards) {
        return titleIsValid; // Must have title if no cards are set
      }

      return true;
    }),

    categoryTitle: computed(() => {
      return state.set()?.title || '';
    }),
  })),
  withHooks((state, logger = inject(AppLogger)) => ({
    onInit() {
      logger.forContext('EditReadingCategoryStore').debug('Store initialized');
      state.uniqueTitle(state.categoryTitle);
    },
  }))
);
