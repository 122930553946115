import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  public writeToClipboard(text: string): void {
    navigator.clipboard.writeText(text);
  }

  public addContentDuplexToClipboard(
    content: string[],
    nbrOnPage: number
  ): void {
    const toClipboard = this.createStringForClipboard(content, nbrOnPage, true);
    this.writeToClipboard(toClipboard);
  }

  public addContentToClipboard(
    content: string[],
    leaveEmptyBetween = false,
    nbrOnPage = 1
  ): void {
    const toClipboard = this.createStringForClipboard(
      content,
      nbrOnPage,
      false,
      leaveEmptyBetween
    );
    this.writeToClipboard(toClipboard);
  }

  public createStringForClipboard(
    content: string[],
    nbrOnPage: number,
    duplexPrint,
    leaveEmptyBetween = false
  ): string {
    let allPages = '';
    let page = '';
    let wordsOnPage = 0;

    for (let i = 0; i < content.length; i++) {
      const text = content[i];

      if (duplexPrint && wordsOnPage === nbrOnPage) {
        allPages = allPages + page + page;
        page = '';
        wordsOnPage = 0;
      }

      if (leaveEmptyBetween && wordsOnPage === nbrOnPage) {
        for (let j = 0; j < nbrOnPage; j++) {
          page = page + '\n';
        }
        wordsOnPage = 0;
      }

      page = page + text + '\n';
      wordsOnPage += 1;
    }

    page = this.paddLastPage(wordsOnPage, page, nbrOnPage);
    allPages = allPages + page;

    if (duplexPrint) {
      allPages = allPages + page;
    }

    allPages = allPages.replace(/\n*$/, '');

    return allPages;
  }

  private paddLastPage(
    wordsOnPage: number,
    page: string,
    expectedNbrOnPage: number
  ) {
    if (wordsOnPage < expectedNbrOnPage) {
      const nbrOfSpacesToFill = expectedNbrOnPage - wordsOnPage;
      for (let i = 0; i < nbrOfSpacesToFill; i++) {
        page = page + '\n';
      }
    }
    return page;
  }
}
