import { Routes } from '@angular/router';

export const PresentRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./containers/present-page/present.component').then(
            (mod) => mod.PresentComponent
          ),
      },
      {
        path: 'set/:setId',
        loadComponent: () =>
          import(
            './containers/present-set-container-page/present-set-container.component'
          ).then((mod) => mod.PresentSetContainerComponent),
        data: {
          hideHeader: true,
          hideTabbar: true,
          hideMenu: true,
        },
      },
      {
        path: 'set/:setId/completed',
        loadComponent: () =>
          import(
            './containers/present-set-completed-page/present-set-completed.component'
          ).then((mod) => mod.PresentSetCompletedComponent),
        data: {
          title: 'Set completed',
        },
      },
    ],
    providers: [
    ],
  },
];
